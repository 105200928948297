import React from 'react';

function TerminalLoading(props) {
    if (props.show === true) {
        return (
            <div>
                <div class="lds-dual-ring"></div>
            </div>            
        );
    }
    else {
        return null;
    }
};

export default TerminalLoading;