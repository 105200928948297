import React from 'react';
import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import CurrencyFlag from 'react-currency-flags';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 4,
        marginBottom: 4,
    },
    chip: { margin: 4 },
});

const CurrencyChipField = () => {
    const record = useRecordContext();
    const classes = useStyles();

    return record ? (
        <span className={classes.main}>
            <Chip
                key={record}
                className={classes.chip}
                label={<span><CurrencyFlag currency={record} size="md" />&nbsp;{record}</span>}
            />
        </span>
    ) : null;
};

export default CurrencyChipField;