import russianMessages from 'ra-language-russian';

const messages = {
    ...russianMessages, 
    kuggar: {
        auth: {
            sign_in_with_kuggar: 'ВОЙТИ С KUGGAR',
            or: 'ИЛИ ЖЕ',
            sign_in_with_microsoft: 'ВОЙТИ С MICROSOFT',
            validating_microsoft_account: 'Подтверждение вашей учетной записи Microsoft, подождите...',
            validating_google_account: 'Подтверждение вашей учетной записи Google, подождите...',
            go_back_to_login: 'Щелкните здесь, чтобы вернуться на страницу входа.',
            invalid_credentials: 'Неверные учетные данные. Пожалуйста, попробуйте еще раз.'
        },
        menu: {
            logs: 'журналы ревизий',
            countries: 'Страны',
            currencies: 'Валюты',
            organisations: 'Организация',
            users: 'Пользователи',
            virtual_cards: 'Виртуальные карты',
            caches: 'Карточные тайники',
            routing: 'Маршрутизация'
        },
        dashboard: {
            title: 'Добро пожаловать на портал для клиентов Kuggar'
        },
        logs: {
            log: 'журнал ревизий',
            timestamp: 'Отметка времени (UTC)',
            type: 'Тип',
            level: 'Уровень',
            message: 'Сообщение',
            data: 'Данные',
            exception: 'Исключение',
            linked_virtual_card: 'Связано Виртуальная карта'
        },
        country: {
            country_name: 'Имя страны',
            alpha_2_code: 'Альфа-код 2',
            alpha_3_code: 'Альфа-код 3'
        },
        currency: {
            alpha_code: 'Альфа-код',
            minor_units: 'Незначительные единицы'
        },
        organisation: {
            reference_code_validation: 'Код должен быть уникальным для каждого клиента'
        },
        virtual_cards: {
            virtual_card: 'Виртуальная карта',
            provider: 'Эмитент',
            provider_id: 'Эмитент Идентификатор',
            provider_id_eNett: 'vNett трансакция Идентификатор',
            state: 'Статус',
            card_number: 'Номер карты',
            valid_from: 'Действительная дата начала',
            valid_to: 'Действительная дата окончания',
            valid_to_validation: 'Дата начала действия должна быть раньше, чем дата окончания срока действия',
            currency: 'Валюта',
            currency_code: 'Код валюты',
            card_limit: 'Лимит карты',
            card_limit_validation: 'Лимит карты должен быть больше нуля',
            card_limit_helper: 'В единицах валюты, выбранных выше',
            generation_date: 'Дата создания',
            last_modified: 'Последнее изменение (UTC)',
            failure_reason: 'Причина неудачи',
            created_card_data: 'Созданные данные карты',
            last_updated_card_data: 'Последнее обновление данных виртуальной карты',
            cancelled_card_data: 'Последнее обновление данных виртуальной карты',
            tab_summary: 'Резюме',
            tab_details: 'Подробности',
            update_card: 'Изменить Виртуальная карта',
            cancel_card: 'Отменить Виртуальная карта',
            cancel_card_confirmation: 'Вы действительно хотите аннулировать / закрыть виртуальную карту с идентификатором Kuggar %{id}? Это действие не может быть отменено.',
            title_create: 'Создать новое Виртуальная карта',
            title_update: 'Изменить Виртуальная карта',
            on_success_notify: 'Виртуальная карта создана, обновление отправлено на',
            user_defined_fields: 'Расширенные поля данных'
        },
        shared: {
            id: 'Идентификатор',
            kuggar_id: 'Идентификатор Kuggar',
            name: 'Имя',
            numeric_code: 'Числовой код',
            reference_code: 'Код ссылки',
            email_address: 'Адрес электронной почты',
            email_address_validation: 'Электронный адрес должен быть действующим',
            currencies: 'Валюты',
            update: 'Обновлять',
            value: 'Значение'
        }
    }
};

export default messages;