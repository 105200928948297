import * as React from "react";
import { TextField, useRecordContext } from 'react-admin';
import ReactJson from "react-json-view";
import XMLViewer from 'react-xml-viewer'
import get from 'lodash/get';

const getContent = (props, text) => {
    if (text.startsWith('{') && text.endsWith('}')) {
        return <ReactJson src={JSON.parse(text)} name={null} collapsed={false} enableClipboard={false} displayDataTypes={false} />;
    }
    
    if (text.startsWith('<') && text.endsWith('>'))
        return <div className="xml-view"><XMLViewer xml={text} /></div>;

    return <TextField {...props} />;
};

const ConditionalTextField = props => {
    const { source } = props;
    const record = useRecordContext();
    
    if (record) {
        const value = get(record, source);
        if (value) return getContent(props, value);
    }
    return null;
};

export default ConditionalTextField;