import {
    ArrayField, Create,
    Datagrid, DateInput, Edit,
    EditButton, Error,
    List, Loading, ReferenceField, required, SelectInput,
    Show, SimpleForm, Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    HasCreatePermission,
    HasUpdatePermission, kuggarExporter, KuggarTextInput,
    TableFilter,
    TablePagination,
    UpdateToolbar
} from "../shared";
import * as React from "react";
import PermissionsCreateUpdate from "../components/PermissionsCreateUpdate";

export const AccountList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            filters={<TableFilter/>}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/accounts')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <TextField source="physicalAccountNumber" label={translate('kuggar.accounts.physical_account_number')} />
                <TextField source="physicalBin" label={translate('kuggar.accounts.physical_bin')} />
                <ReferenceField source="binRangeId" reference="ip_bin_range"
                                label={translate('kuggar.bin_ranges.bin_range')}>
                    <TextField source="description"/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const AccountShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/accounts') ?
                    <EditButton
                        label={translate('kuggar.accounts.update_account')}
                        record={data}/>
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <TextField source="physicalAccountNumber" label={translate('kuggar.accounts.physical_account_number')} />
                    <TextField source="physicalBin" label={translate('kuggar.accounts.physical_bin')} />
                    <TextField source="physicalExpiryDate" label={translate('kuggar.accounts.physical_expiry_date')} />
                    <TextField source="currencyCode" label={translate('kuggar.accounts.currency_code')} />
                    <ReferenceField source="binRangeId" reference="ip_bin_range"
                                    label={translate('kuggar.bin_ranges.bin_range')}>
                        <TextField source="description"/>
                    </ReferenceField>
                    <ArrayField source="permissions" label={translate('kuggar.shared.permissions')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="accessProfileId" label={translate('kuggar.shared.access_profile_id')}/>
                            <TextField source="scope" label={translate('kuggar.role.scope')}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const AccountEdit = () => {
    const translate = useTranslate();

    const {data, isLoading, error} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const inputStyle = {width: '512px'};

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <KuggarTextInput source="description" validate={[required()]} label={translate('kuggar.shared.description')} fullWidth={true} />
                <KuggarTextInput source="physicalAccountNumber" validate={[required()]} label={translate('kuggar.accounts.physical_account_number')} fullWidth={true} />
                <KuggarTextInput source="physicalBin" validate={[required()]} label={translate('kuggar.accounts.physical_bin')} fullWidth={true} />
                <DateInput source="physicalExpiryDate"
                           label={translate('kuggar.accounts.physical_expiry_date')}
                           fullWidth={true}
                           validate={[required()]}/>
                <KuggarTextInput source="currencyCode" validate={[required()]} label={translate('kuggar.accounts.currency_code')} fullWidth={true} />
                <SelectInput
                    source="binRangeId"
                    label={translate('kuggar.bin_ranges.bin_range')}
                    validate={[required()]}
                    choices={data}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <PermissionsCreateUpdate/>
            </SimpleForm>
        </Edit>
    );
};

export const AccountCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`${translate('kuggar.bin_ranges.on_success_notify')}`);
        redirect(`/ip_bin_range/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    const {data, isLoading, error} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const inputStyle = {width: '512px'};

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <KuggarTextInput source="description" validate={[required()]} label={translate('kuggar.shared.description')} fullWidth={true} />
                <KuggarTextInput source="physicalAccountNumber" validate={[required()]} label={translate('kuggar.accounts.physical_account_number')} fullWidth={true} />
                <KuggarTextInput source="physicalBin" validate={[required()]} label={translate('kuggar.accounts.physical_bin')} fullWidth={true} />
                <DateInput source="physicalExpiryDate"
                           label={translate('kuggar.accounts.physical_expiry_date')}
                           fullWidth={true}
                           validate={[required()]}/>
                <KuggarTextInput source="currencyCode" validate={[required()]} label={translate('kuggar.accounts.currency_code')} fullWidth={true} />
                <SelectInput
                    source="binRangeId"
                    label={translate('kuggar.bin_ranges.bin_range')}
                    validate={[required()]}
                    choices={data}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <PermissionsCreateUpdate/>
            </SimpleForm>
        </Create>
    );
};