import * as React from "react";
import { Admin, Resource, useTranslate } from 'react-admin';
import { QueryClient } from 'react-query';

import Layout from './Layout';
import Dashboard from './Dashboard';
import LoginForm from './LoginForm'
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import i18nProvider from './i18nProvider';
import { Version, Environment } from "./configProvider";
import { HasViewPermission } from './shared';

import { LogList, LogShow } from './views/logs';
import { CountryList } from './views/countries';
import { CurrencyList } from './views/currencies';
import { RoleList } from './views/roles';
import { OrganisationList, OrganisationShow, OrganisationEdit, OrganisationCreate } from './views/organisations';
import {UserList, UserShow, UserEdit, UserCreate, ImportUsers} from './views/users';
import { VirtualCardList, VirtualCardShow, VirtualCardEdit, VirtualCardCreate } from './views/virtualCards';
import { CacheList, CacheShow, CacheEdit, CacheCreate } from './views/caches';
import {RoutingList, RoutingEdit, CurrencyRoutingEdit} from './views/routing';
import { PrepaidAccountList, PrepaidAccountShow } from './views/prepaidAccounts';
import { IssuerAccountsList, IssuerAccountShow, IssuerAccountEdit, IssuerAccountCreate } from './views/issuerAccounts';
import { UserDefinedDataList, UserDefinedDataShow, UserDefinedDataEdit, UserDefinedDataCreate } from './views/userDefinedData';
import { VcAccessProfilesList, VcAccessProfilesShow, VcAccessProfilesEdit, VcAccessProfilesCreate } from './views/vcAccessProfiles';
import { EncryptionKeyList, EncryptionKeyShow, EncryptionKeyEdit, EncryptionKeyCreate } from './views/encryptionKeys';
import { IssuerCredentialsList, IssuerCredentialsShow, IssuerCredentialsEdit, IssuerCredentialsCreate } from './views/issuerCredentials';
import { IssuerEnvironmentsList, IssuerEnvironmentCreate, IssuerEnvironmentEdit, IssuerEnvironmentShow } from './views/issuerEnvironments';
import { IssuerFtpsList, IssuerFtpCreate, IssuerFtpShow, IssuerFtpEdit } from "./views/issuerFtps";
import { NotificationList, NotificationCreate, NotificationShow, NotificationEdit } from "./views/notifications";
import {
    CardActivityEdit,
    CardActivityList,
    CardActivityShow, FundingAccountActivityEdit,
    FundingAccountActivityList,
    FundingAccountActivityShow, NonZeroCardBalanceEdit,
    NonZeroCardBalanceList,
    NonZeroCardBalanceShow,
    VirtualCardExpiryList
} from './views/reports';
import {
    IpAccessProfilesCreate,
    IpAccessProfilesEdit,
    IpAccessProfilesList,
    IpAccessProfilesShow
} from "./views/ipAccessProfiles";
import { ExchangeRatesList } from "./views/exchangeRates";
import { OnboardingCreate, OnboardingList } from "./views/onboarding";
import { RuleCollectionList, RuleCollectionCreate } from './views/ruleCollections';


import LogIcon from '@mui/icons-material/Subject';
import CountryIcon from '@mui/icons-material/Public';
import CurrencyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RoleIcon from '@mui/icons-material/AdminPanelSettings';
import OrganisationIcon from '@mui/icons-material/Business';
import UserIcon from '@mui/icons-material/People';
import OnboardingIcon from '@mui/icons-material/AddBusiness';
import VirtualCardIcon from '@mui/icons-material/CreditCard';
import CacheIcon from '@mui/icons-material/AccountBalanceWallet';
import RoutingIcon from '@mui/icons-material/CallSplit';
import PrepaidAccountIcon from '@mui/icons-material/PriceChange';
import IssuerAccountIcon from '@mui/icons-material/Build';
import UserDefinedDataIcon from '@mui/icons-material/SyncAlt';
import IpAccessProfileIcon from '@mui/icons-material/AccountCircle';
import IssuerManagementIcon from '@mui/icons-material/ChromeReaderMode';
import SchemeIcon from '@mui/icons-material/AccountBalance';
import CardIcon from '@mui/icons-material/CreditScore';
import AuthInquiryIcon from '@mui/icons-material/Contactless';
import ProcessorPolicyIcon from '@mui/icons-material/Policy';
import IssuerTemplateIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ProcessorUserIcon from '@mui/icons-material/RecentActors';
import BinRangeIcon from '@mui/icons-material/Numbers';
import AccountIcon from '@mui/icons-material/Contacts';
import ExceptionIcon from '@mui/icons-material/Error';
import VcAccessProfileIcon from '@mui/icons-material/AccountBox';
import EncryptionKeyIcon from '@mui/icons-material/Key';
import IssuerCredentialsIcon from '@mui/icons-material/Password';
import IssuerEnvironmentIcon from '@mui/icons-material/Api';
import IssuerFtpIcon from '@mui/icons-material/CloudDownload';
import CardActivityIcon from '@mui/icons-material/Payments';
import FundingAccountActivityIcon from '@mui/icons-material/CurrencyExchange';
import CurrencyConversionsIcon from '@mui/icons-material/CurrencyExchange';
import NonZeroCardBalanceIcon from '@mui/icons-material/Money';
import NotificationIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UatpServiceLogsIcon from '@mui/icons-material/FormatLineSpacing';
import {CustomRoutes} from 'react-admin';
import { Route } from "react-router-dom";
import EditIssuerAccountDefinedData from "./components/EditIssuerAccountDefinedData";
import PgpPrivateKeyIcon from '@mui/icons-material/LockOpen';
import PgpPublicKeyIcon from '@mui/icons-material/Lock';
import RuleCollectionIcon from '@mui/icons-material/Rule';
import RuleIcon from '@mui/icons-material/Gavel';
import DataTransformQueryIcon from '@mui/icons-material/DataObject';
import FilterQueryIcon from '@mui/icons-material/FilterAlt';

import {
    IssuerManagementCreate, IssuerManagementEdit,
    IssuerManagementList,
    IssuerManagementShow
} from "./views/issuerMangement";
import {SchemeCreate, SchemeEdit, SchemeList, SchemeShow} from "./views/schemes";
import {
    CardProcessorPolicyCreate,
    CardProcessorPolicyEdit,
    CardProcessorPolicyList,
    CardProcessorPolicyShow
} from "./views/cardProcessorPolicy";
import {ProcessorUserCreate, ProcessorUserEdit, ProcessorUserList, ProcessorUserShow} from "./views/processorUser";
import {BinRangeCreate, BinRangeEdit, BinRangeList, BinRangeShow} from "./views/binRanges";
import {AccountCreate, AccountEdit, AccountList, AccountShow} from "./views/accounts";
import {
    CardIssuerTemplateCreate,
    CardIssuerTemplateEdit,
    CardIssuerTemplateList,
    CardIssuerTemplateShow
} from "./views/cardIssuerTemplate";
import {
    IssuerProcessorCardCreate,
    IssuerProcessorCardEdit,
    IssuerProcessorCardList,
    IssuerProcessorCardShow
} from "./views/ipCards";
import {
    IssuerOnboardingCreate,
    IssuerOnboardingList
} from "./views/issuer-processor/ipOnboarding";
import IssuerOnboardingIcon from '@mui/icons-material/AddCard';
import { Terminal } from "./views/terminal.jsx"
import {AuthorizationInquiryList, AuthorizationInquiryShow} from "./views/authorizationInquiries";
import {ExceptionList, ExceptionShow} from "./views/ipExceptions";
import {CurrencyConversionList, CurrencyConversionShow} from "./views/currencyConversions";
import {UatpServiceLogList, UatpServiceLogShow} from "./views/uatpServiceLogs";
import {
    PgpPrivateKeyList,
    PgpPrivateKeyShow,
    PgpPrivateKeyCreate,
    PgpPrivateKeyEdit
} from "./views/pgpPrivateKeys";
import {
    PgpPublicKeyList,
    PgpPublicKeyShow,
    PgpPublicKeyCreate,
    PgpPublicKeyEdit
} from "./views/pgpPublicKeys";
import {RuleCreate, RuleEdit, RuleList, RuleShow} from "./views/rules";
import {
    DataTransformQueryCreate,
    DataTransformQueryEdit,
    DataTransformQueryList,
    DataTransformQueryShow
} from "./views/dataTransformQueries";
import {FilterQueryCreate, FilterQueryEdit, FilterQueryList, FilterQueryShow} from "./views/filterQueries";

const isProd = Environment.Name.toLowerCase().startsWith('prod');
const isKuggar = Environment.HostName === 'Kuggar';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const GetResources = (translate) => [
    <Resource
        name="credential"
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader']}} />,
    <Resource
        name="logs"
        list={LogList}
        show={LogShow}
        icon={LogIcon}
        options={{ api: 'Platform', roles: ['35d2804e-84c9-4374-a830-8443efaaf926','ea700a03-2eec-494f-8e33-f073642cebb0','7f1d1a38-f898-4c2c-8765-0badb557f387','66bf5b46-7fcf-49d7-8d2f-fb06fa240bed'], category: 'Admin', label: translate('kuggar.menu.logs') }}
    />,
    <Resource
        name="codes/country"
        list={CountryList}
        icon={CountryIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.countries') }}
    />,
    <Resource
        name="codes/currency"
        list={CurrencyList}
        icon={CurrencyIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.currencies') }}
    />,
    <Resource
        name="currency-exchange"
        list={ExchangeRatesList}
        icon={CurrencyExchangeIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Codes', label: translate('kuggar.menu.exchange_rates') }}
    />,
    <Resource
        name="role"
        list={RoleList}
        icon={RoleIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Codes', label: translate('kuggar.menu.roles') }}
    />,
    <Resource
        name="onboarding"
        list={OnboardingList}
        create={OnboardingCreate}
        icon={OnboardingIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.onboarding') }}
    />,
    <Resource
        name="organisation"
        list={OrganisationList}
        show={OrganisationShow}
        edit={OrganisationEdit}
        create={OrganisationCreate}
        icon={OrganisationIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.organisations') }}
    />,
    <Resource
        name="user"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        create={UserCreate}
        icon={UserIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.users') }}
    />,
    <Resource
        name="encryption_key"
        list={EncryptionKeyList}
        show={EncryptionKeyShow}
        edit={EncryptionKeyEdit}
        create={EncryptionKeyCreate}
        icon={EncryptionKeyIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.encryption_keys') }}
    />,
    <Resource
        name="notification"
        list={NotificationList}
        show={NotificationShow}
        edit={NotificationEdit}
        create={NotificationCreate}
        icon={NotificationIcon}
        options={{ api: 'Platform', roles: ['Administrator','Contributor','Reader'], category: 'Customer Management', label: translate('kuggar.menu.notifications') }}
    />,
    <Resource
        name="virtual_card"
        list={VirtualCardList}
        show={VirtualCardShow}
        edit={VirtualCardEdit}
        create={VirtualCardCreate}
        icon={VirtualCardIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Payments', label: translate('kuggar.menu.virtual_cards') }}
    />,
    <Resource
        name="cache"
        list={CacheList}
        show={CacheShow}
        icon={CacheIcon}
        edit={CacheEdit}
        create={CacheCreate}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], category: 'Payments', label: translate('kuggar.menu.caches') }}
    />,
    <Resource
        name="routing"
        list={RoutingList}
        edit={RoutingEdit}
        icon={RoutingIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'cache_route', category: 'Payments', label: translate('kuggar.menu.routing') }}
    >
        <Route path="/:id/currency" element={<CurrencyRoutingEdit />} />
    </Resource>,
    /*<Resource
        name="rule_collections"
        list={RuleCollectionList}
        create={RuleCollectionCreate}
        icon={RuleCollectionIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cache_route', category: 'Rules Engine', label: translate('kuggar.menu.rule_collections') }}
    >
    </Resource>,*/
    <Resource
        name="rules"
        list={RuleList}
        show={RuleShow}
        edit={RuleEdit}
        create={RuleCreate}
        icon={RuleIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cache_route', category: 'Rules Engine', label: translate('kuggar.menu.rules') }}
    />,
    <Resource
        name="filter_queries"
        list={FilterQueryList}
        show={FilterQueryShow}
        edit={FilterQueryEdit}
        create={FilterQueryCreate}
        icon={FilterQueryIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cache_route', category: 'Rules Engine', label: translate('kuggar.menu.filter_queries') }}
    />,
    <Resource
        name="data_transform_queries"
        list={DataTransformQueryList}
        show={DataTransformQueryShow}
        edit={DataTransformQueryEdit}
        create={DataTransformQueryCreate}
        icon={DataTransformQueryIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cache_route', category: 'Rules Engine', label: translate('kuggar.menu.data_transform_queries') }}
    />,
    <Resource
        name="balance_instrument"
        list={PrepaidAccountList}
        show={PrepaidAccountShow}
        icon={PrepaidAccountIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'prepaid_account', category: 'Payments', label: translate('kuggar.menu.prepaid_accounts') }}
    />,
    <Resource
        name="card_activity"
        list={CardActivityList}
        show={CardActivityShow}
        edit={CardActivityEdit}
        icon={CardActivityIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'VirtualCardUser', 'Reader'], route: 'report_data/card_activity', category: 'Reports', label: translate('kuggar.menu.card_activity') }}
    />,
    <Resource
        name="cards_near_expiry"
        list={VirtualCardExpiryList}
        icon={AccessTimeIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','VirtualCardUser','Reader'], route: 'virtual_card', category: 'Reports', label: translate('kuggar.menu.virtual_cards_expiry') }}
    />,
    <Resource
        name="funding_account_activity"
        list={FundingAccountActivityList}
        show={FundingAccountActivityShow}
        edit={FundingAccountActivityEdit}
        icon={FundingAccountActivityIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'Reader'], route: 'report_data/funding_account_activity', category: 'Reports', label: translate('kuggar.menu.funding_account_activity') }}
    />,
    <Resource
        name="non_zero_card_balance"
        list={NonZeroCardBalanceList}
        show={NonZeroCardBalanceShow}
        edit={NonZeroCardBalanceEdit}
        icon={NonZeroCardBalanceIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager', 'Reader'], route: 'report_data/non_zero_card_balance', category: 'Reports', label: translate('kuggar.menu.non_zero_card_balance') }}
    />,
    <Resource
        name="vc_access_profile"
        list={VcAccessProfilesList}
        show={VcAccessProfilesShow}
        edit={VcAccessProfilesEdit}
        create={VcAccessProfilesCreate}
        icon={VcAccessProfileIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'access_profile', category: 'Payments Setup', label: translate('kuggar.menu.vc_access_profiles') }}
    />,
    <Resource
        name="user_defined_data"
        list={UserDefinedDataList}
        show={UserDefinedDataShow}
        edit={UserDefinedDataEdit}
        create={UserDefinedDataCreate}
        icon={UserDefinedDataIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'user_defined_datamap', category: 'Payments Setup', label: translate('kuggar.menu.user_defined_data') }}
    />,
    <Resource
        name="issuer_account"
        list={IssuerAccountsList}
        show={IssuerAccountShow}
        icon={IssuerAccountIcon}
        edit={IssuerAccountEdit}
        create={IssuerAccountCreate}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_accounts') }}
    />,
    <Resource
        name="issuer_credentials"
        list={IssuerCredentialsList}
        show={IssuerCredentialsShow}
        edit={IssuerCredentialsEdit}
        create={IssuerCredentialsCreate}
        icon={IssuerCredentialsIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'issuer_credential', category: 'Payments Setup', label: translate('kuggar.menu.issuer_credentials') }}
    />,
    <Resource
        name="issuer_environment"
        list={IssuerEnvironmentsList}
        show={IssuerEnvironmentShow}
        edit={IssuerEnvironmentEdit}
        create={IssuerEnvironmentCreate}
        icon={IssuerEnvironmentIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_environments') }}
    />,
    <Resource
        name="issuer_ftp"
        list={IssuerFtpsList}
        show={IssuerFtpShow}
        edit={IssuerFtpEdit}
        create={IssuerFtpCreate}
        icon={IssuerFtpIcon}
        options={{ api: 'VirtualCards', roles: ['Administrator','Contributor','RulesManager','Reader'], category: 'Payments Setup', label: translate('kuggar.menu.issuer_ftps') }}
    />,
    <Resource
        name="ip_onboarding"
        list={IssuerOnboardingList}
        create={IssuerOnboardingCreate}
        icon={IssuerOnboardingIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','Reader'], route: 'card_issuer_templates', category: 'Issuer Setup', label: translate('kuggar.menu.ip_onboarding') }}
    />,
    <Resource
        name="ip_access_profile"
        list={IpAccessProfilesList}
        show={IpAccessProfilesShow}
        edit={IpAccessProfilesEdit}
        create={IpAccessProfilesCreate}
        icon={IpAccessProfileIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'access_profiles', category: 'Issuer Setup', label: translate('kuggar.menu.ip_access_profiles') }}
    />,
    <Resource
        name="ip_issuer_management"
        list={IssuerManagementList}
        show={IssuerManagementShow}
        edit={IssuerManagementEdit}
        create={IssuerManagementCreate}
        icon={IssuerManagementIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'issuers', category: 'Issuer Setup', label: translate('kuggar.menu.issuer_management') }}
    />,
    <Resource
        name="ip_scheme"
        list={SchemeList}
        show={SchemeShow}
        edit={SchemeEdit}
        create={SchemeCreate}
        icon={SchemeIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'schemes', category: 'Issuer Setup', label: translate('kuggar.menu.schemes') }}
    />,
    <Resource
        name="ip_card"
        list={IssuerProcessorCardList}
        show={IssuerProcessorCardShow}
        edit={IssuerProcessorCardEdit}
        create={IssuerProcessorCardCreate}
        icon={CardIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'cards', category: 'Issuer Setup', label: translate('kuggar.menu.cards') }}
    />,
    <Resource
        name="ip_authorization_inquiries"
        list={AuthorizationInquiryList}
        show={AuthorizationInquiryShow}
        icon={AuthInquiryIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'authorization_inquiries', category: 'Issuer Setup', label: 'kuggar.menu.authorization_inquiries' }}
    />,
    <Resource
        name="ip_card_processor_policy"
        list={CardProcessorPolicyList}
        show={CardProcessorPolicyShow}
        edit={CardProcessorPolicyEdit}
        create={CardProcessorPolicyCreate}
        icon={ProcessorPolicyIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'card_processor_policies', category: 'Issuer Setup', label: translate('kuggar.menu.card_processor_policy') }}
    />,
    <Resource
        name="ip_card_issuer_template"
        list={CardIssuerTemplateList}
        show={CardIssuerTemplateShow}
        edit={CardIssuerTemplateEdit}
        create={CardIssuerTemplateCreate}
        icon={IssuerTemplateIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'card_issuer_templates', category: 'Issuer Setup', label: translate('kuggar.menu.card_issuer_template') }}
    />,
    <Resource
        name="ip_processor_user"
        list={ProcessorUserList}
        show={ProcessorUserShow}
        edit={ProcessorUserEdit}
        create={ProcessorUserCreate}
        icon={ProcessorUserIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'processor_users', category: 'Issuer Setup', label: translate('kuggar.menu.processor_users') }}
    />,
    <Resource
        name="ip_bin_range"
        list={BinRangeList}
        show={BinRangeShow}
        edit={BinRangeEdit}
        create={BinRangeCreate}
        icon={BinRangeIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'bin_ranges', category: 'Issuer Setup', label: translate('kuggar.menu.bin_ranges') }}
    />,
    <Resource
        name="ip_account"
        list={AccountList}
        show={AccountShow}
        edit={AccountEdit}
        create={AccountCreate}
        icon={AccountIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'accounts', category: 'Issuer Setup', label: translate('kuggar.menu.accounts') }}
    />,
    <Resource
        name="ip_exceptions"
        list={ExceptionList}
        show={ExceptionShow}
        icon={ExceptionIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'exceptions', category: 'Issuer Setup', label: 'kuggar.menu.exceptions' }}
    />,
    <Resource
        name="ip_currency_conversions"
        list={CurrencyConversionList}
        show={CurrencyConversionShow}
        icon={CurrencyConversionsIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'currency_conversions', category: 'Issuer Setup', label: 'kuggar.menu.currency_conversions' }}
    />,
    <Resource
        name="uatp_service_logs"
        list={UatpServiceLogList}
        show={UatpServiceLogShow}
        icon={UatpServiceLogsIcon}
        options={{ api: 'IssuerProcessor', roles: ['Administrator','Contributor','RulesManager','Reader'], route: 'uatp_service_logs', category: 'Issuer Setup', label: 'kuggar.menu.uatp_service_logs' }}
    />,
    <Resource
        name="pgp_private_key"
        list={PgpPrivateKeyList}
        show={PgpPrivateKeyShow}
        icon={PgpPrivateKeyIcon}
        create={PgpPrivateKeyCreate}
        edit={PgpPrivateKeyEdit}
        options={{ api: 'IssuerProcessor', roles: ['Administrator'], route: 'pgp_private_keys', category: 'Issuer Setup', label: 'kuggar.menu.pgp_private_keys' }}
    />,
    <Resource
        name="pgp_public_key"
        list={PgpPublicKeyList}
        show={PgpPublicKeyShow}
        icon={PgpPublicKeyIcon}
        create={PgpPublicKeyCreate}
        edit={PgpPublicKeyEdit}
        options={{ api: 'IssuerProcessor', roles: ['Administrator'], route: 'pgp_public_keys', category: 'Issuer Setup', label: 'kuggar.menu.pgp_public_keys' }}
    />
];

const Footer = () => {
    const [headers, setHeaders] = React.useState(null);
    React.useEffect(() => {
        async function getHeaders() {
            const headers = await fetch(`${Environment.API.Kuggar}/ping`).then(rsp => rsp.headers);
            setHeaders(headers);
        }
        getHeaders();
    }, []);

    let cloudProvider = 'Local';
    let cloudRegion = 'Local';
    if (headers) {
        if (headers.has('x-cloud-provider')) {
            cloudProvider = headers.get('x-cloud-provider');
            localStorage.setItem('cloud-provider', cloudProvider);
        }
        if (headers.has('x-cloud-region')) {
            cloudRegion = headers.get('x-cloud-region');
            localStorage.setItem('cloud-region', cloudRegion);
        }
    }

    return (
        <>
            <div className="spacer">&nbsp;</div>
            <div className="footer">v{Version} | {cloudProvider} ({cloudRegion}) | <a href={`mailto:${Environment.SupportEmail}`}>{Environment.SupportEmail}</a></div>
        </>
    );
};

const App = () => {
    const translate = useTranslate();
    const knownResources = GetResources(translate);
    
    const fetchResources = () => {
        // TODO remove the hard-coded reference to rule filtering once ready
        const secureResources = knownResources.filter(x => (x.props.name === 'rules' && isKuggar) || (x.props.name !== 'rules' && HasViewPermission(x.props.options.api, `/${x.props.options.route ? x.props.options.route : x.props.name}/`)));
        return Promise.resolve(secureResources);
    };

    React.useEffect(() => {
        document.title = isProd
            ? `${Environment.HostName} Customer Portal`
            : `[${Environment.Name.replace(`-${Environment.HostName}`, '')}] ${Environment.HostName} Customer Portal`;

        if (!isKuggar) {
            const theme = Environment.HostName.toLowerCase();
            document.documentElement.classList.add(`${theme}-theme`);
            document.getElementById("favIcon").href = document.getElementById("favIcon").href.replace("favicon.ico", `favicon-${theme}.ico`);
            document.getElementById("appleTouchIcon").href = document.getElementById("appleTouchIcon").href.replace("logo192.jpg", `logo192-${theme}.jpg`);
        }
    });

    return (
        <>
            <Admin
                layout={Layout}
                loginPage={LoginForm}
                dashboard={Dashboard}
                queryClient={queryClient}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                authProvider={authProvider}
                requireAuth
            >
                <CustomRoutes >
                    <Route exact path="/issuer_account/:id/defined_data" element={<EditIssuerAccountDefinedData/>} />
                    <Route path='/user/import' element={<ImportUsers />} />
                    <Route path='/terminal' element={<Terminal />} />
                </CustomRoutes>
                {fetchResources}
            </Admin>
            <Footer />
        </>
    );
};

export default App;