import * as React from "react";
import { TextField, Labeled, useRecordContext, linkToRecord, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

const LinkedEntityField = props => {
    const { nameField, idField } = props;
    const record = useRecordContext();
    const linkedEntityName = get(record, nameField);
    const linkedEntityId = get(record, idField);
    const translate = useTranslate();

    if (linkedEntityName && linkedEntityId) {
        let label = linkedEntityName;
        let content = <TextField source={idField} />;
    
        if (linkedEntityName === 'VirtualCard') {
            const linkTo = linkToRecord('/virtual_card', linkedEntityId, 'show');
            label = translate('kuggar.logs.linked_virtual_card');
            content = <Link to={linkTo}>{linkedEntityId}</Link>;
        }
    
        return <Labeled label={label}>{content}</Labeled>;  
    }
    return null;
};

export default LinkedEntityField;