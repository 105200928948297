import {
	TextInput, NumberInput, DateInput,
	//required
} from 'react-admin';

const UserDefinedField = props => {
	const { source, label, style, udfType, key } = props;

	if (udfType === "Text") {
		return (
			<TextInput
				key={key}
				source={source}
				label={label}
				fullWidth={true}
				multiline
				style={style}
				//validate={[required()]}
			/>
		);
	}
	else if (udfType === "Number") {
		return (
			<NumberInput
				key={key}
				source={source}
				label={label}
				style={style}
				//validate={[required()]}
			/>
		);
	}
	else if (udfType === "Date") {
		return (
			<DateInput
				key={key}
				source={source}
				label={label}
				style={style}
				//validate={[required()]}
			/>
		);
	}
	else {
		return null;
	}
};

export default UserDefinedField;