import {
	Loading, Error,
	SelectInput,
	required, useGetList, useRecordContext
} from 'react-admin';
import CurrencyFlag from 'react-currency-flags';

const CurrencySelect = props => {
	const { source, label, style, value, isFilter, defaultValue } = props;

	const { data, isLoading, error } = useGetList(
		'currency',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {
				field: 'alphaCode',
				order: 'ASC'
			}
		}
	);
	if (isLoading) return <Loading />;
	if (error) return <Error />;
	if (!data) return null;

	// Create the currency data with duplicated top currencies
	let currencyData = [];
	let index = 1;

	// First insert the top currencies
	const topCurrencies = ['AUD','EUR','GBP','NZD','SGD','USD'];
	const topCurrencyData = data.filter(c => topCurrencies.includes(c.alphaCode));
	for (let i = 0; i < topCurrencyData.length; i++) {
		currencyData.push({
			index: index,
			id: topCurrencyData[i].id,
			alphaCode: topCurrencyData[i].alphaCode,
			name: topCurrencyData[i].name
		});
		index++;
	}

	// Now insert all currencies
	for (let i = 0; i < data.length; i++) {
		currencyData.push({
			index: index,
			id: data[i].id,
			alphaCode: data[i].alphaCode,
			name: data[i].name
		});
		index++;
	}

	// Default select list value to 'id'.
	let optionValue = value;
	if (value == null) {
		optionValue = "id";
    }

	const OptionRenderer = () => {
		const record = useRecordContext(); 
		return <span><CurrencyFlag currency={record.alphaCode} size="md" />&nbsp;{record.alphaCode} - {record.name}{record.index === topCurrencies.length ? <hr /> : null}</span>;
	}
	
	return (<SelectInput
		source={source}
		label={label}
		validate={isFilter === true ? null : [required()]}
		choices={currencyData}
		optionText={<OptionRenderer />}
		optionValue={optionValue}
		defaultValue={defaultValue}
		style={style} />);
};

export default CurrencySelect;