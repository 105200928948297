import * as React from "react";
import {useEffect, useState} from "react";
import {
    ArrayField, BooleanField, BooleanInput, Button, Create, Datagrid, DeleteButton, Edit, 
    EditButton, Error, FormTab, FunctionField, List, Loading, NumberInput, ReferenceField,
    required, SelectInput, Show, SingleFieldList, Tab, TabbedForm, TabbedShowLayout, TextField,
    TopToolbar, useDataProvider, useGetList, useNotify, useRecordContext, useRedirect, useTranslate, WrapperField
} from 'react-admin';
import ImageField from '../components/ImageField';
import CurrencyChipField from '../components/CurrencyChipField';
import CancelIcon from '@mui/icons-material/Close';
import FillIcon from '@mui/icons-material/AutoMode';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    CreateToolbar,
    HasCreatePermission,
    HasDeletePermission,
    HasUpdatePermission,
    IsVirtualCardUser,
    kuggarExporter,
    KuggarTextInput,
    TableFilter,
    TablePagination,
    TextFieldWithCopyButton,
    UpdateToolbar
} from '../shared';
import {useParams} from 'react-router-dom';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {Environment} from "../configProvider";
import Alert from 'react-bootstrap/Alert';

import CardCreateRequestView from '../components/CardCreateRequestView';
import CardCreateRequestForm from '../components/CardCreateRequestForm';

const ActiveIcon = () => <CheckIcon style={{color: '#14cb14'}}/>;
const InactiveIcon = () => <ClearIcon style={{color: '#fd3c39'}}/>;

export const CacheList = () => {
    const translate = useTranslate();

    const StateField = ({label}) => {
        const record = useRecordContext();
        const {count, state, limits: {upperLimit, lowerLimit}} = record;

        const percentageFilled = ((count - lowerLimit) / (upperLimit - lowerLimit)) * 100;
        let color = 'green';

        if (percentageFilled < 50) {
            color = 'orange'

            if (count === 0) {
                color = 'red'
            }
        }

        return <WrapperField label={label}><span style={{color}}>{state}</span></WrapperField>
    }

    return (
        <List exporter={kuggarExporter} filters={<TableFilter/>} pagination={<TablePagination/>} perPage={10}
              sort={{field: 'description', order: 'ASC'}}
              hasCreate={HasCreatePermission('VirtualCards', '/cache') && !IsVirtualCardUser}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')}
                                reference="issuer_account" link="show" sortBy='issuerAccount.Description'>
                    <TextField source="description"/>
                </ReferenceField>
                <ReferenceField source="issuerCredentialId" label={translate('kuggar.shared.issuer_credential')}
                                reference="issuer_credentials" link="show" sortBy='issuerCredential.Description'>
                    <TextField source="description"/>
                </ReferenceField>
                <ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')}/>
                <ArrayField source="currencyCodes" label={translate('kuggar.shared.currencies')} sortable={false}>
                    <SingleFieldList linkType={false}>
                        <CurrencyChipField/>
                    </SingleFieldList>
                </ArrayField>

                <StateField label={translate('kuggar.shared.state')}/>
                <TextField source="count" label={translate('kuggar.caches.count')}/>
                <TextField source="limits.upperLimit" label={translate('kuggar.caches.upper_limit')} sortBy='limits.UpperLimit'/>
                <TextField source="limits.lowerLimit" label={translate('kuggar.caches.lower_limit')} sortBy='limits.LowerLimit'/>
                <BooleanField source="active" TrueIcon={ActiveIcon} FalseIcon={InactiveIcon}
                              label={translate('kuggar.shared.active')}/>
                <BooleanField source="default" TrueIcon={ActiveIcon} FalseIcon={InactiveIcon}
                              label={translate('kuggar.shared.default')}/>
            </Datagrid>
        </List>
    );
};

export const CacheShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const [connection, setConnection] = useState(null);
    const [cacheState, setCacheState] = useState(null);
    const [showFillAlert, setShowFillAlert] = useState(false);
    const [showPartialFillAlert, setShowPartialFillAlert] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        if (!connection) {
            const token = localStorage.getItem('auth');
            const cloudRegion = localStorage.getItem('cloud-region');
            const cloudProvider = localStorage.getItem('cloud-provider')
                ? localStorage.getItem('cloud-provider')
                : (Environment.MultiRegion === true ? Object.keys(Environment.API.Platform)[0] : "Azure");

            const hubsUrl = Environment.MultiRegion === true
                ? (Environment.Hubs.CacheUpdates[cloudProvider][cloudRegion]
                    ? Environment.Hubs.CacheUpdates[cloudProvider][cloudRegion]
                    : Environment.Hubs.CacheUpdates[Object.keys(Environment.Hubs.CacheUpdates)[0]][Object.keys(Environment.Hubs.CacheUpdates[Object.keys(Environment.Hubs.CacheUpdates)[0]])[0]])
                : Environment.Hubs.CacheUpdates;

            const newConnection = new HubConnectionBuilder()
                .withUrl(hubsUrl, {accessTokenFactory: () => token})
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
        if (connection && connection.connectionState !== "Connected" && connection.connectionState !== "Connecting") {
            connection.start()
                .then(result => {
                    console.log('Connected to Signal R Cache hub. ');

                    connection.on('ReceiveStateSetMessageAsync', message => {
                        if (id === message.cacheId) {
                            let newCacheState = {
                                newState: message.state,
                            };
                            setCacheState(newCacheState);
                        }
                    });

                    connection.on('ReceiveFillFailedMessageAsync', message => {
                        if (id === message.cacheId) {
                            let newCacheState = {
                                newState: message.state,
                                newFailReason: message.failureReason
                            };
                            setCacheState(newCacheState);
                        }
                    });

                    connection.on('ReceiveMessageAsync', message => {
                        if (id === message.cacheId) {
                            console.log(JSON.stringify(message, null, 2))
                            let newCacheState = {
                                newState: message.state,
                                newCount: message.count,
                            };
                            setCacheState(newCacheState);

                            if (message.count === message.upperLimit) {
                                setShowFillAlert(true);
                                setTimeout(function () {
                                    setShowFillAlert(false);
                                }, 2000);
                            } else if (message.count < message.upperLimit) {
                                setShowPartialFillAlert(true);
                                setTimeout(function () {
                                    setShowPartialFillAlert(false);
                                }, 2000);
                            }
                        }
                    });

                })
                .catch(e => console.log('Connection failed: ', e));
        }

        return function () {
            if (connection && connection.connectionState === "Connected") {
                connection.stop();
            }
        };
    }, [connection, id]);

    const handleRefreshCacheClick = (data) => {
        const cacheId = data.id;
        if (data) {
            let params = {
                Id: cacheId,
            };
            dataProvider.refreshCache(params);
        }
    };
    
    const handleFillCacheClick = (data) => {
        const cacheId = data.id;
        if (data) {
            const fillAmount = data.limits.upperLimit - data.count;
            let params = {
                Id: cacheId,
                Quantity: fillAmount
            };
            dataProvider.fillCache(params);
        }
    };

    const handleEmptyCacheClick = (data) => {
        const cacheId = data.id;
        if (data) {
            let params = {
                Id: cacheId,
            };
            dataProvider.emptyCache(params);
        }
    };

    const Title = () => {
        const record = useRecordContext();
        return <span>Cache - ID {record ? record.id : ''}</span>;
    };

    const onSuccess = () => {
		notify(`${translate('kuggar.caches.on_delete_success_notify')}`);
		redirect(`/cache`);
    };
    
    const onError = (data) => {
        notify(`${translate('kuggar.caches.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
    };

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('VirtualCards', '/cache/refresh') ?
                    <Button
                        label={translate('kuggar.caches.refresh_cache')}
                        startIcon={<RotateLeftIcon/>}
                        onClick={() => handleRefreshCacheClick(data)}/>
                    : null}
                {HasUpdatePermission('VirtualCards', '/cache/fill') ?
                    <Button
                        label={translate('kuggar.caches.fill_cache')}
                        startIcon={<FillIcon/>}
                        onClick={() => handleFillCacheClick(data)}/>
                    : null}
                {HasUpdatePermission('VirtualCards', '/cache/empty') ?
                    <Button
                        label={translate('kuggar.caches.empty_cache')}
                        startIcon={<DeleteIcon />}
                        onClick={() => handleEmptyCacheClick(data)}/>
                    : null}
                {!IsVirtualCardUser && HasUpdatePermission('VirtualCards', '/cache/') ?
                    <EditButton
                        label={translate('kuggar.caches.update_cache')}
                        record={data}/>
                    : null}
                {HasDeletePermission('VirtualCards', '/cache/') ?
                    <DeleteButton
                        label={translate('kuggar.caches.delete_cache')}
                        record={data}
                        icon={<CancelIcon/>}
                        mutationMode="optimistic"
                        mutationOptions={{ onSuccess, onError }}
                        confirmTitle="Delete Cache"
                        confirmContent={`Are you sure you want to delete Cache with Kuggar ID ${data ? data.id : ''}? This action cannot be undone.`}/>
                    : null}
            </TopToolbar>
        );
    };

    const CountField = (props) => {
        if (props.cacheState && props.cacheState.newCount) {
            return <FunctionField label={props.label} render={record => `${cacheState.newCount}`}/>;
        } else {
            return <TextField label={props.label} source="count"/>;
        }
    };

    const StateField = (props) => {
        if (props.cacheState && props.cacheState.newState) {
            return <FunctionField label={props.label} render={record => `${cacheState.newState}`}/>;
        } else {
            return <TextField label={props.label} source="state"/>;
        }
    };

    const FailureReasonField = (props) => {
        const record = useRecordContext();

        if (props.cacheState && props.cacheState.newFailReason) {
            return <FunctionField label={props.label} render={record => `${cacheState.newFailReason}`}/>;
        } else if (record.fillFailureReason) {
            return <TextField label={props.label} source="fillFailureReason"/>;
        } else {
            return (null);
        }
    };

    const FundingCurrencyCodeField = (props) => {
        const record = useRecordContext();

        if (record.fundingCurrencyCode) {
            return <TextField label={props.label} source="fundingCurrencyCode"/>;
        } else {
            return (null);
        }
    };

    return (
        <Show title={<Title/>} actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.virtual_cards.tab_summary')}>
                    <Alert show={showFillAlert}
                           onClose={() => setShowFillAlert(false)}
                           dismissible>
                        Cache filled
                    </Alert>
                    <Alert show={showPartialFillAlert}
                           onClose={() => setShowPartialFillAlert(false)}
                           dismissible>
                        Cache partially filled
                    </Alert>
                    <TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')}
                                    reference="issuer_account" link="show">
                        <TextField source="description"/>
                    </ReferenceField>
                    <ReferenceField source="issuerCredentialId" label={translate('kuggar.shared.issuer_credential')}
                                    reference="issuer_credentials" link="show">
                        <TextField source="description"/>
                    </ReferenceField>
                    <ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')}/>
                    <ArrayField source="currencyCodes" label={translate('kuggar.shared.currencies')}>
                        <SingleFieldList linkType={false}>
                            <CurrencyChipField/>
                        </SingleFieldList>
                    </ArrayField>
                    <FundingCurrencyCodeField label={translate('kuggar.caches.funding_currency_code')}/>
                    <StateField cacheState={cacheState} label={translate('kuggar.shared.state')}/>
                    <CountField cacheState={cacheState} label={translate('kuggar.caches.count')}/>
                    <TextField source="limits.upperLimit" label={translate('kuggar.caches.upper_limit')}/>
                    <TextField source="limits.lowerLimit" label={translate('kuggar.caches.lower_limit')}/>
                    <BooleanField source="active" TrueIcon={ActiveIcon} FalseIcon={InactiveIcon}
                                  label={translate('kuggar.shared.active')}/>
                    <BooleanField source="default" TrueIcon={ActiveIcon} FalseIcon={InactiveIcon}
                                  label={translate('kuggar.shared.default')}/>
                    <FailureReasonField cacheState={cacheState} label={translate('kuggar.caches.failure_reason')}/>
                </Tab>
                <Tab label={translate('kuggar.caches.tab_create_request')}>
                    <BooleanField label={translate('kuggar.caches.valid_from_today')}
                                  source="dynamicCreateRequest.validFromToday" TrueIcon={ActiveIcon}
                                  FalseIcon={InactiveIcon}/>
                    <TextField source="dynamicCreateRequest.daysValidFromCurrentDate"
                               label={translate('kuggar.caches.days_valid_from_current_date')}/>
                    <BooleanField label={translate('kuggar.caches.force_unique_reference')}
                                  source="dynamicCreateRequest.forceUniqueReference" TrueIcon={ActiveIcon}
                                  FalseIcon={InactiveIcon}/>
                    <CardCreateRequestView source="dynamicCreateRequest.createRequestData"/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const CacheEdit = () => {
    const translate = useTranslate();
    const Title = () => {
        const record = useRecordContext();
        return <span>{translate('kuggar.caches.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
    };
    const inputStyle = {width: '512px'};

    // Edit cache form validation
    const validateCacheEdit = (values) => {
        const errors = {};

        if (values.limits.lowerLimit >= values.limits.upperLimit) {
            errors.lowerLimit = translate('kuggar.caches.lower_limit_validation');
        }

        return errors;
    };

    return (
        <Edit title={<Title/>} mutationMode="optimistic">
            <TabbedForm toolbar={<UpdateToolbar/>} validate={validateCacheEdit}>
                <FormTab label={translate('kuggar.shared.tab_summary')}>
                    <KuggarTextInput
                        source="description"
                        label={translate('kuggar.shared.description')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <NumberInput
                        source="limits.upperLimit"
                        label={translate('kuggar.caches.upper_limit')}
                        min="1"
                        style={inputStyle}
                        validate={[required()]}/>
                    <NumberInput
                        source="limits.lowerLimit"
                        label={translate('kuggar.caches.lower_limit')}
                        min="1"
                        style={inputStyle}
                        validate={[required()]}/>
                    <BooleanInput
                        source="active"
                        label={translate('kuggar.shared.active')}/>
                    <BooleanInput
                        source="default"
                        label={translate('kuggar.shared.default')}/>
                </FormTab>
                <FormTab label={translate('kuggar.caches.tab_create_request')}>
                    <BooleanInput
                        source="dynamicCreateRequest.validFromToday"
                        label={translate('kuggar.caches.valid_from_today')}/>
                    <NumberInput
                        source="dynamicCreateRequest.daysValidFromCurrentDate"
                        label={translate('kuggar.caches.days_valid_from_current_date')}
                        min="0"
                        style={inputStyle}/>
                    <BooleanInput
                        source="dynamicCreateRequest.forceUniqueReference"
                        label={translate('kuggar.caches.force_unique_reference')}/>
                    <CardCreateRequestForm source="dynamicCreateRequest.createRequestData"/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export const CacheCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [provider, setProvider] = useState(null);

    // Setup the issuer credentials dropdown list
    const {data: issuerCredData, isLoading: issuerCredLoading, error: issuerCredError} = useGetList(
        'issuer_credentials',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {}
        }
    );

    // Setup the issuer account dropdown list
    const {data: issuerAccData, isLoading: issuerAccLoading, error: issuerAccError} = useGetList(
        'issuer_account',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {}
        }
    );

    if (issuerCredLoading) return <Loading/>;
    if (issuerCredError) return <Error/>;
    if (!issuerCredData) return null;

    if (issuerAccLoading) return <Loading/>;
    if (issuerAccError) return <Error/>;
    if (!issuerAccData) return null;

    const validateCacheCreate = (values) => {
        const errors = {};

        if (values.limits) {
            if (values.limits.lowerLimit >= values.limits.upperLimit) {
                errors.lowerLimit = translate('kuggar.caches.lower_limit_validation');
            }
        }

        return errors;
    };

    const inputStyle = {width: '512px'};

    const onSuccess = (data) => {
        notify(`${translate('kuggar.caches.on_success_notify')}`);
        redirect(`/cache/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    const onIssuerCredentialChange = (value) => {
        const provider = getProviderFromIssuerCred(value.target.value);
        setProvider(provider);
    };

    const onIssuerAccountChange = (value) => {
        const provider = getProviderFromIssuerAcc(value.target.value);
        setProvider(provider);
    };

    const getProviderFromIssuerCred = (issuerCredentialId) => {
        const issuerCred = issuerCredData.find(element => element.id === issuerCredentialId);
        return issuerCred.provider;
    };

    const getProviderFromIssuerAcc = (issuerAccountId) => {
        const issuerAcc = issuerAccData.find(element => element.id === issuerAccountId);
        return issuerAcc.provider;
    };

    return (
        <Create title={translate('kuggar.caches.title_create')} mutationOptions={{ onSuccess, onError }}>
            <TabbedForm toolbar={<CreateToolbar/>} validate={validateCacheCreate}>
                <FormTab label={translate('kuggar.shared.tab_summary')}>
                    <SelectInput
                        source="issuerCredentialId"
                        label={translate('kuggar.shared.issuer_credentials')}
                        validate={[required()]}
                        choices={issuerCredData}
                        onChange={onIssuerCredentialChange}
                        optionText="description"
                        optionValue="id"
                        style={inputStyle}/>
                    <SelectInput
                        source="issuerAccountId"
                        label={translate('kuggar.shared.issuer_account')}
                        validate={[required()]}
                        choices={issuerAccData}
                        onChange={onIssuerAccountChange}
                        optionText="description"
                        optionValue="id"
                        style={inputStyle}/>
                    <NumberInput
                        source="limits.upperLimit"
                        label={translate('kuggar.caches.upper_limit')}
                        min="1"
                        style={inputStyle}/>
                    <NumberInput
                        source="limits.lowerLimit"
                        label={translate('kuggar.caches.lower_limit')}
                        min="1"
                        style={inputStyle}/>
                    <KuggarTextInput
                        source="description"
                        label={translate('kuggar.shared.description')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <BooleanInput
                        source="active"
                        label={translate('kuggar.shared.active')}/>
                    <BooleanInput
                        source="default"
                        label={translate('kuggar.shared.default')}/>
                </FormTab>
                <FormTab label={translate('kuggar.caches.tab_create_request')}>
                    <BooleanInput
                        source="dynamicCreateRequest.validFromToday"
                        label={translate('kuggar.caches.valid_from_today')}/>
                    <NumberInput
                        source="dynamicCreateRequest.daysValidFromCurrentDate"
                        label={translate('kuggar.caches.days_valid_from_current_date')}
                        min="0"
                        style={inputStyle}/>
                    <BooleanInput
                        source="dynamicCreateRequest.forceUniqueReference"
                        label={translate('kuggar.caches.force_unique_reference')}/>
                    <CardCreateRequestForm provider={provider} source="dynamicCreateRequest.createRequestData"/>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};