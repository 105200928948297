import * as React from "react";
import { Card, CardHeader } from '@mui/material';
import { useTranslate } from 'react-admin';

const Dashboard = () => {
    const translate = useTranslate();

    const identity = localStorage.getItem('identity');
    let data = { username: '' };
    if (identity !== undefined && identity !== null && identity !== '')
        data = JSON.parse(identity);

    return (
        <Card>
            <CardHeader title={translate('kuggar.dashboard.title', data)} />
        </Card>
    );
};

export default Dashboard;