import * as React from "react";
import { FormatCardNumber } from '../shared';

const CreditCardMint = props => (
  <div>
    <svg width="350" data-name="Layer 1" version="1.1" viewBox="0 0 1e3 630" xmlns="http://www.w3.org/2000/svg" xmlnscc="http://creativecommons.org/ns#" xmlnsdc="http://purl.org/dc/elements/1.1/" xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsxlink="http://www.w3.org/1999/xlink">
      <title>Nium Credit Card</title>
      <defs>
        <style>{`.cls-1,.cls-19,.cls-23,.cls-27,.cls-30{fill:#fff;}.cls-1,.cls-10,.cls-12,.cls-14,.cls-16,.cls-18,.cls-6,.cls-8{fill-rule:evenodd;}.cls-2{fill:none;}.cls-3{fill:url(#linear-gradient);}.cls-4{clip-path:url(#clip-path);}.cls-5{mask:url(#mask);}.cls-6{fill:url(#linear-gradient-2);}.cls-7{mask:url(#mask-2);}.cls-8{fill:url(#linear-gradient-3);}.cls-9{mask:url(#mask-3);}.cls-10{fill:url(#linear-gradient-4);}.cls-11{mask:url(#mask-4);}.cls-12{fill:url(#linear-gradient-5);}.cls-13{mask:url(#mask-5-2);}.cls-14{fill:url(#linear-gradient-6);}.cls-15{mask:url(#mask-6);}.cls-16{fill:url(#linear-gradient-7);}.cls-17{mask:url(#mask-7);}.cls-18{fill:url(#linear-gradient-8);}.cls-19{font-size:51px;}.cls-19,.cls-23,.cls-27{font-family:Sora-Regular, Sora;}.cls-20{letter-spacing:-0.01em;}.cls-21{letter-spacing:0.01em;}.cls-22{letter-spacing:-0.01em;}.cls-23{font-size:32px;}.cls-24{letter-spacing:0em;}.cls-25{letter-spacing:-0.04em;}.cls-26{letter-spacing:0em;}.cls-27{font-size:42px;}.cls-28{letter-spacing:0em;}.cls-29{letter-spacing:0em;}`}</style>
        <linearGradient id="linear-gradient" x2="1536" y1="484.5" y2="484.5" gradientTransform="rotate(180,768,484.5)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#333" offset="0"/>
        <stop stop-color="#494a49" offset="1"/>
        </linearGradient>
        <clipPath id="clip-path" transform="translate(660.11 99.02)">
        <rect class="cls-2" transform="rotate(180,768,484.5)" width="1536" height="969"/>
        </clipPath>
        <mask id="mask" x="279.97" y="0" width="2153.89" height="1421.26" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m166-79.72a418.42 418.42 0 0 1 212.46 151.2c25.38 34.33 36.58 59.12 85 74.27a130.49 130.49 0 0 0 135.54-36.69c11.79-13.76 17.31-19.3 32.27-31.73a269 269 0 0 1 252.53-49.91c30 9.38 51 25.55 78.42 34.12a130.21 130.21 0 0 0 121.31-23.36l2.21-1.83c6.48-5.51 9.85-8 16.47-12.87 104.33-77.5 243-105.71 376.57-63.93 221.61 69.34 345 305.15 275.65 526.71-42.87 136.93-149.35 236.34-276.74 276l-1.27 0.44a124 124 0 0 0-80.8 81.22 123.82 123.82 0 0 0 13.28 103v0.09a242.49 242.49 0 0 1 27.94 204.72c-40.11 128.13-176.52 199.49-304.67 159.39-120.64-37.75-190.94-160.82-165.3-282 14-99.39-65.51-203.37-145.33-228.34-79.82-25-124.65-9.41-174.58 21.85l-0.47 0.28a268.69 268.69 0 0 1-226.17 30.59 267.08 267.08 0 0 1-136.32-97.27 106.88 106.88 0 0 0-50.79-34.14 107 107 0 0 0-45.3-4.11c-13.69 1.71-28.9 7-42.38 10.51a418.45 418.45 0 0 1-230.69-5.88c-221.6-69.34-345-305.15-275.65-526.71 50.88-162.57 191.45-272.31 350.23-291.81a419 419 0 0 1 176.58 16.19"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-2" x1="619.71" x2="620.29" y1="635.09" y2="635.28" gradientTransform="matrix(-1930.5 237.04 -172.57 -1405.4 1307325 746331)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#272727" offset="0"/>
        <stop stop-color="#494a49" offset="1"/>
        </linearGradient>
        <mask id="mask-2" x="570.68" y="649.69" width="634.64" height="634.66" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <g data-name="mask-2">
          <path class="cls-1" d="m-89.44 868c0 175.24 142.08 317.31 317.35 317.31 175.24 0 317.3-142.07 317.3-317.31 0-175.29-142.06-317.35-317.3-317.35-175.27 0.02-317.35 142.08-317.35 317.35"/>
          </g>
        </g>
        </mask>
        <linearGradient id="linear-gradient-3" x1="617.12" x2="617.7" y1="635.7" y2="636.06" gradientTransform="matrix(577,0,0,-577,-356021,367772)" xlinkhref="#linear-gradient-2"/>
        <mask id="mask-3" x="0" y="271.66" width="848.67" height="931.85" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m-198.13 216c127.56 76.44 179.13 231.33 129.95 366l0.43 0.34a94.67 94.67 0 0 0 38.15 119 94.6 94.6 0 0 0 36.52 12.71l0.14 0.14a194.51 194.51 0 0 1 86.51 27.3c92.56 55.44 122.63 175.41 67.17 268-55.46 92.56-175.44 122.66-268 67.22a195.32 195.32 0 0 1-94.57-180.23l-0.84-0.94c0.71-2.88 1.27-5.78 1.69-8.68 0.15-1.3 0.32-2.59 0.5-3.89a94.64 94.64 0 0 0-45.52-91.14 94.32 94.32 0 0 0-60.62-12.7l-0.08-0.09a303.9 303.9 0 0 1-205.08-39.49c-144.55-86.55-191.51-273.96-104.89-418.55a304.56 304.56 0 0 1 69.67-80.35c96.75-78.34 235.9-92.28 348.88-24.61"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-4" x1="620.31" x2="620.31" y1="636.19" y2="635.47" gradientTransform="matrix(-431.32 349.27 -603.52 -745.28 651160 257975)" xlinkhref="#linear-gradient-2"/>
        <mask id="mask-4" x="1627.94" y="64.69" width="937.82" height="841.11" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m1859.5 432.2c-78.61 126.21-234.37 175-368.19 123.54l-0.35 0.43a94.65 94.65 0 0 0-119.63 36.07 94.46 94.46 0 0 0-13.31 36.29l-0.15 0.14a194.44 194.44 0 0 1-28.8 86c-57 91.58-177.53 119.55-269.1 62.49-91.58-57.07-119.58-177.56-62.54-269.14a195.29 195.29 0 0 1 181.85-91.41l1-0.83c2.87 0.76 5.75 1.37 8.64 1.85 1.3 0.16 2.59 0.36 3.88 0.56a94.66 94.66 0 0 0 91.92-43.93 94.3 94.3 0 0 0 13.75-60.39l0.09-0.09a303.9 303.9 0 0 1 43.06-204.36c89.09-143 277.25-186.7 420.28-97.58a304.71 304.71 0 0 1 79.12 71.09c76.65 98.1 88.16 237.49 18.52 349.27"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-5" x1="621.83" x2="621.83" y1="634.04" y2="633.32" gradientTransform="matrix(-341.69 -437.35 755.7 -590.42 -265082 646586)" xlinkhref="#linear-gradient-2"/>
        <mask id="mask-5-2" x="1318.74" y="217.59" width="609" height="599.46" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m1109.2 443.78a50.62 50.62 0 0 0 28 60.71 50.4 50.4 0 0 0 20.24 4.34l0.08 0.08a104 104 0 0 1 47.76 8.81 104.51 104.51 0 0 1 53.2 138 104.7 104.7 0 0 1-138 53.35 104.45 104.45 0 0 1-62-89.55l-0.51-0.44a46.79 46.79 0 0 0 0.33-4.71v-2.19a50.61 50.61 0 0 0-30.14-45.35 50.54 50.54 0 0 0-33-2.76 234 234 0 0 1-197.13 3.46c-118.36-52.38-171.76-190.86-119.24-309.28 52.51-118.43 191-172 309.39-119.59a233.48 233.48 0 0 1 70.78 48.46 234.44 234.44 0 0 1 50.21 256.66"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-6" x1="622.73" x2="623.55" y1="636.46" y2="636.46" gradientTransform="matrix(-332.34 -332.34 218.5 -218.5 69063 346653)" xlinkhref="#linear-gradient-2"/>
        <mask id="mask-6" x="2289.82" y="328.51" width="634.64" height="634.66" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m1629.7 546.84c0 175.24 142.07 317.31 317.34 317.31 175.24 0 317.3-142.07 317.3-317.31 0-175.29-142.06-317.35-317.3-317.35-175.27 0-317.34 142.06-317.34 317.35"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-7" x1="617.39" x2="617.97" y1="635.75" y2="636.11" gradientTransform="matrix(577,0,0,-577,-354458,367480)" xlinkhref="#linear-gradient-2"/>
        <mask id="mask-7" x="1971.31" y="494.45" width="767.99" height="778.78" maskUnits="userSpaceOnUse">
        <g transform="translate(660.11,99.02)">
          <path class="cls-1" d="m1334 802.47c52.23-117.78 180-178.46 301-150.41l0.26-0.4a82 82 0 0 0 98.3-45.38 81.68 81.68 0 0 0 7.06-32.75l0.11-0.14a168.62 168.62 0 0 1 14.34-77.3 169.25 169.25 0 0 1 223.4-86.09 169.35 169.35 0 0 1 86.14 223.43 169.19 169.19 0 0 1-145 100.37l-0.72 0.82c-2.55-0.31-5.1-0.48-7.64-0.54h-3.4a82 82 0 0 0-73.58 48.78 81.79 81.79 0 0 0-4.51 53.49l-0.06 0.08a263.36 263.36 0 0 1-12.3 180.57c-59.19 133.47-215.4 193.64-348.89 134.4a264 264 0 0 1-76.51-51.4c-77.61-75-104.31-193.18-58-297.5"/>
        </g>
        </mask>
        <linearGradient id="linear-gradient-8" x1="616.74" x2="616.74" y1="634.91" y2="634.19" gradientTransform="matrix(346 334.13 -577.26 597.77 154701 -584702)" xlinkhref="#linear-gradient-2"/>
        <linearGradient id="linearGradient1619" x1="619.71" x2="620.29" y1="635.09" y2="635.28" gradientTransform="matrix(-1930.5 237.04 -172.57 -1405.4 1307325 746331)" gradientUnits="userSpaceOnUse" xlinkhref="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-48" x2="1536" y1="484.5" y2="484.5" gradientTransform="matrix(-.65104 0 0 -.65015 7.6345e-5 -.00013206)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#333" offset="0"/>
        <stop stop-color="#494a49" offset="1"/>
        </linearGradient>
        <clipPath id="clipPath1682">
        <path class="cls-3" transform="scale(-1)" x="-2196.1101" y="-1068.02" width="1536" height="969" d="m-2196.1-1068h1536v969h-1536z" fill="url(#linearGradient1686)"/>
        </clipPath>
        <linearGradient id="linearGradient1686" x2="1536" y1="484.5" y2="484.5" gradientTransform="rotate(180 -330.06 -49.51)" gradientUnits="userSpaceOnUse" xlinkhref="#linear-gradient"/>
      </defs>
      <path class="cls-3" transform="scale(-1)" x="-1000" y="-630" width="1000" height="630" d="m-1e3 -630h1e3v630h-1e3z" fill="url(#linear-gradient-48)"/>
      <g id="Molecule-BG-Footer" transform="matrix(.65104 0 0 .65015 -429.76 -64.378)" clip-path="url(#clipPath1682)">
        <g class="cls-5" mask="url(#mask)">
        <path class="cls-6" transform="translate(660.11,99.02)" d="m166-79.72a418.42 418.42 0 0 1 212.46 151.2c25.38 34.33 36.58 59.12 85 74.27a130.49 130.49 0 0 0 135.54-36.69c11.79-13.76 17.31-19.3 32.27-31.73a269 269 0 0 1 252.53-49.91c30 9.38 51 25.55 78.42 34.12a130.21 130.21 0 0 0 121.31-23.36l2.21-1.83c6.48-5.51 9.85-8 16.47-12.87 104.33-77.5 243-105.71 376.57-63.93 221.61 69.34 345 305.15 275.65 526.71-42.87 136.93-149.35 236.34-276.74 276l-1.27 0.44a124 124 0 0 0-80.8 81.22 123.82 123.82 0 0 0 13.28 103v0.09a242.49 242.49 0 0 1 27.94 204.72c-40.11 128.13-176.52 199.49-304.67 159.39-120.64-37.75-190.94-160.82-165.3-282 14-99.39-65.51-203.37-145.33-228.34-79.82-25-124.65-9.41-174.58 21.85l-0.47 0.28a268.69 268.69 0 0 1-226.17 30.59 267.08 267.08 0 0 1-136.32-97.27 106.88 106.88 0 0 0-50.79-34.14 107 107 0 0 0-45.3-4.11c-13.69 1.71-28.9 7-42.38 10.51a418.45 418.45 0 0 1-230.69-5.88c-221.6-69.34-345-305.15-275.65-526.71 50.88-162.57 191.45-272.31 350.23-291.81a419 419 0 0 1 176.58 16.19" fill="url(#linearGradient1619)"/>
        </g>
        <g class="cls-7" mask="url(#mask-2)">
        <path class="cls-8" transform="translate(660.11,99.02)" d="m-89.44 868c0 175.24 142.08 317.31 317.35 317.31 175.24 0 317.3-142.07 317.3-317.31 0-175.29-142.06-317.35-317.3-317.35-175.27 0.02-317.35 142.08-317.35 317.35" fill="url(#linear-gradient-3)"/>
        </g>
        <g id="Group-9-Copy">
        <g class="cls-9" mask="url(#mask-3)">
          <path class="cls-10" transform="translate(660.11,99.02)" d="m-198.13 216c127.56 76.44 179.13 231.33 129.95 366l0.43 0.34a94.67 94.67 0 0 0 38.15 119 94.6 94.6 0 0 0 36.52 12.71l0.14 0.14a194.51 194.51 0 0 1 86.51 27.3c92.56 55.44 122.63 175.41 67.17 268-55.46 92.56-175.44 122.66-268 67.22a195.32 195.32 0 0 1-94.57-180.23l-0.84-0.94c0.71-2.88 1.27-5.78 1.69-8.68 0.15-1.3 0.32-2.59 0.5-3.89a94.64 94.64 0 0 0-45.52-91.14 94.32 94.32 0 0 0-60.62-12.7l-0.08-0.09a303.9 303.9 0 0 1-205.08-39.49c-144.55-86.55-191.51-273.96-104.89-418.55a304.56 304.56 0 0 1 69.67-80.35c96.75-78.34 235.9-92.28 348.88-24.61" fill="url(#linear-gradient-4)"/>
        </g>
        </g>
        <g class="cls-11" mask="url(#mask-4)">
        <path class="cls-12" transform="translate(660.11,99.02)" d="m1859.5 432.2c-78.61 126.21-234.37 175-368.19 123.54l-0.35 0.43a94.65 94.65 0 0 0-119.63 36.07 94.46 94.46 0 0 0-13.31 36.29l-0.15 0.14a194.44 194.44 0 0 1-28.8 86c-57 91.58-177.53 119.55-269.1 62.49-91.58-57.07-119.58-177.56-62.54-269.14a195.29 195.29 0 0 1 181.85-91.41l1-0.83c2.87 0.76 5.75 1.37 8.64 1.85 1.3 0.16 2.59 0.36 3.88 0.56a94.66 94.66 0 0 0 91.92-43.93 94.3 94.3 0 0 0 13.75-60.39l0.09-0.09a303.9 303.9 0 0 1 43.06-204.36c89.09-143 277.25-186.7 420.28-97.58a304.71 304.71 0 0 1 79.12 71.09c76.65 98.1 88.16 237.49 18.52 349.27" fill="url(#linear-gradient-5)" data-name="Fill-7"/>
        </g>
        <g class="cls-13" mask="url(#mask-5-2)">
        <path class="cls-14" transform="translate(660.11,99.02)" d="m1109.2 443.78a50.62 50.62 0 0 0 28 60.71 50.4 50.4 0 0 0 20.24 4.34l0.08 0.08a104 104 0 0 1 47.76 8.81 104.51 104.51 0 0 1 53.2 138 104.7 104.7 0 0 1-138 53.35 104.45 104.45 0 0 1-62-89.55l-0.51-0.44a46.79 46.79 0 0 0 0.33-4.71v-2.19a50.61 50.61 0 0 0-30.14-45.35 50.54 50.54 0 0 0-33-2.76 234 234 0 0 1-197.13 3.46c-118.36-52.38-171.76-190.86-119.24-309.28 52.51-118.43 191-172 309.39-119.59a233.48 233.48 0 0 1 70.78 48.46 234.44 234.44 0 0 1 50.21 256.66" fill="url(#linear-gradient-6)"/>
        </g>
        <g data-name="Group-12">
        <g class="cls-15" mask="url(#mask-6)">
          <path class="cls-16" transform="translate(660.11,99.02)" d="m1629.7 546.84c0 175.24 142.07 317.31 317.34 317.31 175.24 0 317.3-142.07 317.3-317.31 0-175.29-142.06-317.35-317.3-317.35-175.27 0-317.34 142.06-317.34 317.35" fill="url(#linear-gradient-7)" data-name="Fill-10"/>
        </g>
        </g>
        <g data-name="Group-9-Copy">
        <g class="cls-17" mask="url(#mask-7)">
          <path class="cls-18" transform="translate(660.11,99.02)" d="m1334 802.47c52.23-117.78 180-178.46 301-150.41l0.26-0.4a82 82 0 0 0 98.3-45.38 81.68 81.68 0 0 0 7.06-32.75l0.11-0.14a168.62 168.62 0 0 1 14.34-77.3 169.25 169.25 0 0 1 223.4-86.09 169.35 169.35 0 0 1 86.14 223.43 169.19 169.19 0 0 1-145 100.37l-0.72 0.82c-2.55-0.31-5.1-0.48-7.64-0.54h-3.4a82 82 0 0 0-73.58 48.78 81.79 81.79 0 0 0-4.51 53.49l-0.06 0.08a263.36 263.36 0 0 1-12.3 180.57c-59.19 133.47-215.4 193.64-348.89 134.4a264 264 0 0 1-76.51-51.4c-77.61-75-104.31-193.18-58-297.5" fill="url(#linear-gradient-8)" data-name="Fill-7"/>
        </g>
        </g>
      </g>
      <g stroke-width=".97468">
        <g id="LabelVirtualCard" class="cls-19" transform="scale(.66795 .63369)" aria-label="Virtual Card">
        <path d="m110.1 147.42-12.903-37.23h5.151l11.577 33.966-2.448-1.224h6.12l-2.601 1.224 10.659-33.966h4.998l-11.985 37.23z"/>
        <path d="m136.59 147.42v-27.234h4.896v27.234zm-4.182-23.358v-3.876h9.078v3.876zm5.559-8.262q-1.632 0-2.448-0.867-0.765-0.867-0.765-2.193t0.765-2.193q0.816-0.867 2.448-0.867t2.397 0.867q0.816 0.867 0.816 2.193t-0.816 2.193q-0.765 0.867-2.397 0.867z"/>
        <path d="m150.57 147.42v-27.234h3.876v11.22h-0.204q0-5.967 2.601-8.721 2.652-2.805 7.905-2.805h0.918v4.386h-1.734q-4.08 0-6.273 2.193-2.193 2.142-2.193 6.222v14.739z"/>
        <path d="m182.06 147.73q-3.06 0-5.304-0.867-2.193-0.867-3.417-2.958-1.173-2.142-1.173-5.763v-26.214h4.692v26.826q0 2.193 1.173 3.417 1.224 1.173 3.417 1.173h4.794v4.386zm-14.586-23.868v-3.672h18.768v3.672z"/>
        <path d="m202.61 148.24q-4.845 0-7.446-3.162t-2.601-9.129v-15.81h4.896v16.779q0 3.213 1.734 5.049 1.734 1.785 4.692 1.785 3.06 0 4.998-1.938 1.938-1.989 1.938-5.406v-16.269h4.896v27.285h-3.876v-11.679h0.612q0 4.029-1.071 6.834-1.071 2.754-3.213 4.233-2.091 1.428-5.355 1.428z"/>
        <path d="m241.68 147.42v-8.16h-0.816v-9.435q0-2.805-1.479-4.233-1.428-1.428-4.233-1.428-1.581 0-3.264 0.051t-3.213 0.102-2.652 0.153v-4.284q1.122-0.102 2.346-0.153 1.275-0.102 2.601-0.102 1.326-0.051 2.55-0.051 4.182 0 6.834 1.02 2.652 0.969 3.927 3.213t1.275 6.069v17.238zm-8.925 0.714q-3.06 0-5.304-1.02-2.193-1.071-3.417-3.06-1.173-1.989-1.173-4.794 0-2.907 1.275-4.845 1.326-1.938 3.825-2.907 2.499-1.02 6.018-1.02h7.395v3.672h-7.599q-2.958 0-4.539 1.428t-1.581 3.672 1.581 3.621 4.539 1.377q1.734 0 3.315-0.612 1.581-0.663 2.601-2.142 1.071-1.53 1.173-4.182l1.326 1.938q-0.255 2.907-1.428 4.896-1.122 1.938-3.162 2.958-1.989 1.02-4.845 1.02z"/>
        <path d="m254.83 147.42v-37.23h4.896v37.23zm-3.774-33.354v-3.876h8.67v3.876z"/>
        <path d="m297.62 148.34q-5.1 0-8.721-1.785-3.57-1.785-5.763-4.641t-3.213-6.171q-1.02-3.366-1.02-6.477v-1.122q0-3.366 1.02-6.732 1.071-3.366 3.264-6.12t5.712-4.437 8.415-1.683q4.998 0 8.568 1.734t5.661 4.845 2.55 7.344h-5.1q-0.408-3.162-2.04-5.202-1.632-2.091-4.131-3.111-2.448-1.02-5.508-1.02-3.315 0-5.814 1.173-2.499 1.122-4.182 3.213-1.632 2.04-2.499 4.743-0.816 2.703-0.816 5.865 0 3.009 0.816 5.712 0.867 2.703 2.55 4.794 1.734 2.091 4.284 3.315 2.55 1.173 5.967 1.173 4.794 0 8.058-2.397t3.978-6.936h5.1q-0.51 3.825-2.601 6.987-2.04 3.162-5.712 5.049-3.621 1.887-8.823 1.887z"/>
        <path d="m337.76 147.42v-8.16h-0.816v-9.435q0-2.805-1.479-4.233-1.428-1.428-4.233-1.428-1.581 0-3.264 0.051t-3.213 0.102-2.652 0.153v-4.284q1.122-0.102 2.346-0.153 1.275-0.102 2.601-0.102 1.326-0.051 2.55-0.051 4.182 0 6.834 1.02 2.652 0.969 3.927 3.213t1.275 6.069v17.238zm-8.925 0.714q-3.06 0-5.304-1.02-2.193-1.071-3.417-3.06-1.173-1.989-1.173-4.794 0-2.907 1.275-4.845 1.326-1.938 3.825-2.907 2.499-1.02 6.018-1.02h7.395v3.672h-7.599q-2.958 0-4.539 1.428t-1.581 3.672 1.581 3.621 4.539 1.377q1.734 0 3.315-0.612 1.581-0.663 2.601-2.142 1.071-1.53 1.173-4.182l1.326 1.938q-0.255 2.907-1.428 4.896-1.122 1.938-3.162 2.958-1.989 1.02-4.845 1.02z"/>
        <path d="m346.07 147.42v-27.234h3.876v11.22h-0.204q0-5.967 2.601-8.721 2.652-2.805 7.905-2.805h0.918v4.386h-1.734q-4.08 0-6.273 2.193-2.193 2.142-2.193 6.222v14.739z"/>
        <path d="m377.53 148.34q-2.907 0-5.355-1.071t-4.284-3.009q-1.785-1.938-2.805-4.488-0.969-2.55-0.969-5.508v-0.918q0-2.907 0.969-5.457t2.703-4.488q1.785-1.938 4.182-3.009 2.448-1.122 5.406-1.122 3.111 0 5.661 1.326 2.55 1.275 4.182 4.029t1.836 7.14l-2.04-3.264v-18.309h4.896v37.23h-3.876v-12.087h1.428q-0.204 4.641-1.938 7.548-1.734 2.856-4.386 4.182-2.601 1.275-5.61 1.275zm0.663-4.284q2.499 0 4.539-1.122t3.264-3.213q1.224-2.142 1.224-4.998v-2.346q0-2.805-1.275-4.743-1.224-1.989-3.315-3.009-2.04-1.071-4.488-1.071-2.703 0-4.794 1.275-2.04 1.275-3.213 3.57-1.122 2.295-1.122 5.406t1.173 5.457q1.173 2.295 3.264 3.57 2.091 1.224 4.743 1.224z"/>
        </g>
        <g class="cls-23" transform="scale(.66795 .63369)" aria-label="CVV2">
        <path d="m109.61 578.19q-3.2 0-5.472-1.12-2.24-1.12-3.616-2.912t-2.016-3.872q-0.64-2.112-0.64-4.064v-0.704q0-2.112 0.64-4.224 0.672-2.112 2.048-3.84t3.584-2.784 5.28-1.056q3.136 0 5.376 1.088t3.552 3.04 1.6 4.608h-3.2q-0.256-1.984-1.28-3.264-1.024-1.312-2.592-1.952-1.536-0.64-3.456-0.64-2.08 0-3.648 0.736-1.568 0.704-2.624 2.016-1.024 1.28-1.568 2.976-0.512 1.696-0.512 3.68 0 1.888 0.512 3.584 0.544 1.696 1.6 3.008 1.088 1.312 2.688 2.08 1.6 0.736 3.744 0.736 3.008 0 5.056-1.504t2.496-4.352h3.2q-0.32 2.4-1.632 4.384-1.28 1.984-3.584 3.168-2.272 1.184-5.536 1.184z"/>
        <path d="m129.61 577.62-8.096-23.36h3.232l7.264 21.312-1.536-0.768h3.84l-1.632 0.768 6.688-21.312h3.136l-7.52 23.36z"/>
        <path d="m152.27 577.62-8.096-23.36h3.232l7.264 21.312-1.536-0.768h3.84l-1.632 0.768 6.688-21.312h3.136l-7.52 23.36z"/>
        <path d="m168.56 577.62v-3.84q0-1.408 0.384-2.4 0.416-0.992 1.408-1.792 0.992-0.832 2.688-1.6l4.384-2.016q1.6-0.736 2.528-1.888 0.928-1.184 0.928-3.04 0-2.24-1.248-3.488t-3.52-1.248q-2.24 0-3.52 1.248t-1.28 3.488h-3.008q0-2.016 0.832-3.712 0.864-1.696 2.592-2.688 1.728-1.024 4.384-1.024t4.352 1.024q1.728 0.992 2.56 2.624 0.864 1.632 0.864 3.552v0.448q0 2.528-1.472 4.256-1.44 1.728-3.904 2.848l-4.384 2.016q-1.472 0.672-1.984 1.344t-0.512 1.792v2.944l-0.832-1.472h13.376v2.624z"/>
        </g>
        <g id="LabelValidThru" class="cls-23" transform="scale(.66795 .63369)" aria-label="VALIDTHRU">
        <path d="m508.74 546.13-8.096-23.36h3.232l7.264 21.312-1.536-0.768h3.84l-1.632 0.768 6.688-21.312h3.136l-7.52 23.36z"/>
        <path d="m521.61 546.13 8.416-23.36h5.312l8.672 23.36h-3.232l-7.84-21.312 1.536 0.768h-3.776l1.632-0.768-7.584 21.312zm5.024-6.976 1.056-2.816h10.08l1.056 2.816z"/>
        <path d="m548.3 546.13v-23.36h3.136v23.36zm2.496 0v-2.816h10.688v2.816z"/>
        <path d="m565.77 546.13v-23.36h3.136v23.36z"/>
        <path d="m578.25 546.26v-2.88h5.824q2.112 0 3.744-0.672 1.664-0.704 2.816-1.92 1.184-1.216 1.792-2.848t0.608-3.52q0-1.984-0.608-3.584-0.608-1.632-1.792-2.816-1.152-1.216-2.816-1.856-1.632-0.64-3.744-0.64h-5.824v-2.88h5.504q3.168 0 5.504 0.992 2.336 0.96 3.872 2.624 1.568 1.632 2.336 3.68 0.768 2.016 0.768 4.128v0.704q0 2.048-0.768 4.096t-2.336 3.712q-1.536 1.664-3.872 2.688-2.336 0.992-5.504 0.992zm-2.496 0v-23.616h3.136v23.616z"/>
        <path d="m507.88 585.52v-21.184h3.136v21.184zm-7.104-20.544v-2.816h17.344v2.816z"/>
        <path d="m538.15 585.52v-23.36h3.136v23.36zm-15.808 0v-23.36h3.136v23.36zm2.496-10.208v-2.816h13.952v2.816z"/>
        <path d="m546.94 585.52v-23.488h3.136v23.488zm14.432 0-7.744-10.4h3.68l7.872 10.4zm-12.48-8.448v-2.784h6.272q1.472 0 2.528-0.608t1.632-1.664q0.576-1.088 0.576-2.464t-0.576-2.432q-0.576-1.088-1.632-1.696-1.056-0.64-2.528-0.64h-6.272v-2.752h5.696q2.56 0 4.48 0.768t2.976 2.368 1.056 4.128v0.512q0 2.528-1.088 4.128-1.056 1.6-2.976 2.368-1.888 0.768-4.448 0.768z"/>
        <path d="m577.9 586.1q-3.168 0-5.344-1.216-2.144-1.248-3.264-3.456-1.088-2.24-1.088-5.184v-14.08h3.136v14.464q0 1.952 0.736 3.456 0.768 1.472 2.24 2.304t3.584 0.832 3.552-0.8q1.472-0.832 2.208-2.304 0.768-1.504 0.768-3.488v-14.464h3.136v14.08q0 2.944-1.088 5.184-1.088 2.208-3.232 3.456-2.144 1.216-5.344 1.216z"/>
        </g>
      </g>
      <g fill="#fff" stroke-width=".63412">
        <path d="m67.202 580.94v-19.429h2.7493v19.429z"/>
        <path d="m75.565 580.94v-14.212h2.1321v6.0948h-0.33665q0-2.1026 0.58913-3.5398 0.61719-1.4638 1.8516-2.209 1.2344-0.77183 3.114-0.77183h0.11222q2.8334 0 4.18 1.6767 1.3746 1.6768 1.3746 4.8439v8.1176h-2.6932v-8.6499q0-1.6501-0.98189-2.6349-0.98189-1.0114-2.6932-1.0114-1.7674 0-2.8615 1.038-1.0941 1.038-1.0941 2.768v8.4902z"/>
        <path d="m98.676 581.09q-1.6832 0-2.9176-0.45245-1.2063-0.45246-1.8796-1.5437-0.64524-1.1178-0.64524-3.0075v-13.68h2.581v13.999q0 1.1444 0.64524 1.7832 0.67329 0.61214 1.8796 0.61214h2.6371v2.2889zm-8.0234-12.456v-1.9163h10.324v1.9163z"/>
        <path d="m110.75 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89772-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44886-1.3041 1.3466-2.3421 0.89773-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61719 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53302 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5249q-0.3086 1.4638-1.2344 2.5284-0.92577 1.0646-2.3285 1.6235-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m121.55 580.94v-14.212h2.1321v5.8553h-0.11222q0-3.114 1.4308-4.5512 1.4588-1.4638 4.3484-1.4638h0.50497v2.2889h-0.95384q-2.2443 0-3.4506 1.1444-1.2063 1.1178-1.2063 3.247v7.6917z"/>
        <path d="m133.05 580.94v-14.212h2.1321v6.0948h-0.33665q0-2.1026 0.58913-3.5398 0.61719-1.4638 1.8516-2.209 1.2344-0.77183 3.114-0.77183h0.11222q2.8334 0 4.18 1.6767 1.3746 1.6768 1.3746 4.8439v8.1176h-2.6932v-8.6499q0-1.6501-0.98189-2.6349-0.98189-1.0114-2.6932-1.0114-1.7674 0-2.8615 1.038-1.0941 1.038-1.0941 2.768v8.4902z"/>
        <path d="m157.37 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89772-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44886-1.3041 1.3466-2.3421 0.89773-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61719 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53302 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5249q-0.3086 1.4638-1.2344 2.5284-0.92577 1.0646-2.3285 1.6235-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m174.01 581.09q-1.6832 0-2.9176-0.45245-1.2063-0.45246-1.8796-1.5437-0.64524-1.1178-0.64524-3.0075v-13.68h2.581v13.999q0 1.1444 0.64524 1.7832 0.67329 0.61214 1.8796 0.61214h2.6371v2.2889zm-8.0234-12.456v-1.9163h10.324v1.9163z"/>
        <path d="m193.58 580.94v-4.2584h-0.44886v-4.9238q0-1.4638-0.81357-2.209-0.78551-0.74522-2.3285-0.74522-0.86967 0-1.7954 0.0266t-1.7674 0.0532q-0.84161 0.0266-1.4588 0.0798v-2.2357q0.61719-0.0532 1.2905-0.0798 0.70135-0.0532 1.4308-0.0532 0.7294-0.0266 1.4027-0.0266 2.3004 0 3.7592 0.5323 1.4588 0.50568 2.1602 1.6767t0.70135 3.1672v8.9959zm-4.9094 0.37261q-1.6832 0-2.9176-0.5323-1.2063-0.55892-1.8796-1.5969-0.64524-1.038-0.64524-2.5018 0-1.517 0.70135-2.5284 0.7294-1.0114 2.104-1.5171 1.3746-0.5323 3.3104-0.5323h4.0678v1.9163h-4.18q-1.6271 0-2.4968 0.74522t-0.86967 1.9163 0.86967 1.8897q0.86967 0.71861 2.4968 0.71861 0.95383 0 1.8235-0.31938 0.86967-0.346 1.4308-1.1178 0.58913-0.79845 0.64524-2.1824l0.7294 1.0114q-0.14027 1.517-0.78551 2.555-0.61719 1.0114-1.7393 1.5437-1.0941 0.5323-2.6651 0.5323z"/>
        <path d="m200.65 580.94v-14.212h2.1321v6.0948h-0.33665q0-2.1026 0.58913-3.5398 0.61719-1.4638 1.8516-2.209 1.2344-0.77183 3.114-0.77183h0.11222q2.8334 0 4.18 1.6767 1.3746 1.6768 1.3746 4.8439v8.1176h-2.6932v-8.6499q0-1.6501-0.98189-2.6349-0.98189-1.0114-2.6932-1.0114-1.7674 0-2.8615 1.038-1.0941 1.038-1.0941 2.768v8.4902z"/>
        <path d="m224.86 581.42q-1.5991 0-2.9457-0.55891-1.3466-0.55892-2.3565-1.5703-0.98189-1.0114-1.543-2.3421-0.53303-1.3308-0.53303-2.8744v-0.47907q0-1.5171 0.53303-2.8478 0.53302-1.3307 1.4868-2.3421 0.98189-1.0114 2.3004-1.5703 1.3466-0.58553 2.9737-0.58553 1.7113 0 3.114 0.69199 1.4027 0.66538 2.3004 2.1026 0.89772 1.4372 1.0099 3.7261l-1.1222-1.7034v-9.5548h2.6932v19.429h-2.1321v-6.3078h0.78551q-0.11222 2.422-1.066 3.939-0.95383 1.4904-2.4126 2.1824-1.4308 0.66537-3.0859 0.66537zm0.3647-2.2357q1.3746 0 2.4968-0.58553 1.1222-0.58553 1.7954-1.6767 0.67329-1.1178 0.67329-2.6083v-1.2243q0-1.4638-0.70135-2.4752-0.67329-1.038-1.8235-1.5703-1.1222-0.55892-2.4688-0.55892-1.4868 0-2.6371 0.66538-1.1222 0.66537-1.7674 1.863-0.61718 1.1977-0.61718 2.8212 0 1.6235 0.64524 2.8478 0.64524 1.1977 1.7954 1.863 1.1502 0.63876 2.609 0.63876z"/>
        <path d="m248.57 581.09q-1.6832 0-2.9176-0.45245-1.2063-0.45246-1.8796-1.5437-0.64524-1.1178-0.64524-3.0075v-13.68h2.581v13.999q0 1.1444 0.64523 1.7832 0.6733 0.61214 1.8796 0.61214h2.6371v2.2889zm-8.0234-12.456v-1.9163h10.324v1.9163z"/>
        <path d="m260.64 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89772-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44886-1.3041 1.3466-2.3421 0.89773-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61719 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53302 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5249q-0.3086 1.4638-1.2344 2.5284-0.92577 1.0646-2.3285 1.6235-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m271.61 580.94v-19.429h2.6932v19.429zm-2.076-17.406v-2.0227h4.7692v2.0227z"/>
        <path d="m285.83 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89773-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44886-1.3041 1.3466-2.3421 0.89772-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61718 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53303 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5248q-0.30859 1.4638-1.2344 2.5284t-2.3285 1.6235q-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m296.63 586.26v-19.535h2.1321v6.1481l-0.50497-0.0532q0.14027-2.2889 1.0099-3.7261 0.89773-1.4638 2.3285-2.1558 1.4307-0.69199 3.114-0.69199 1.6271 0 2.9457 0.58553 1.3466 0.58553 2.3004 1.5969t1.4588 2.3421q0.50497 1.3307 0.50497 2.8212v0.47907q0 1.4904-0.53302 2.8212-0.53303 1.3308-1.5149 2.3421-0.95384 1.0114-2.3004 1.5969-1.3185 0.58553-2.9176 0.58553t-3.0579-0.66538q-1.4588-0.66537-2.4126-2.1558t-1.0941-3.9124l1.2344 2.1292v9.4483zm7.6026-7.0796q1.5149 0 2.6371-0.66538 1.1222-0.69199 1.7393-1.8897 0.61719-1.2243 0.61719-2.7946 0-1.5969-0.61719-2.7946-0.61719-1.1977-1.7393-1.863-1.1222-0.69199-2.6371-0.69199-1.3466 0-2.4968 0.58553-1.1502 0.55891-1.8516 1.6501-0.6733 1.0646-0.6733 2.5816v1.2243q0 1.4638 0.70135 2.5018 0.70135 1.038 1.8516 1.5969 1.1502 0.55892 2.4687 0.55892z"/>
        <path d="m315.96 580.94v-19.429h2.6932v11.604h-0.67329q0-2.1026 0.56108-3.6196 0.58913-1.5437 1.7954-2.3687 1.2063-0.82506 3.0859-0.82506h0.11221q2.6932 0 4.0678 1.7034 1.3746 1.6767 1.3746 4.8173v8.1176h-2.6932v-8.6499q0-1.73-1.038-2.6881-1.0099-0.95814-2.6371-0.95814-1.7954 0-2.8896 1.1444-1.066 1.1178-1.066 2.9543v8.1974z"/>
        <path d="m340.81 581.42q-1.9638 0-3.4506-0.63876t-2.5248-1.6767q-1.0099-1.0646-1.543-2.3687-0.50497-1.3041-0.50497-2.6615v-0.47907q0-1.3574 0.53303-2.6615 0.53302-1.3308 1.571-2.3687 1.038-1.0646 2.5248-1.6768 1.4868-0.63876 3.3945-0.63876 1.9077 0 3.3945 0.63876 1.4869 0.61215 2.5248 1.6768 1.038 1.038 1.571 2.3687 0.53302 1.3041 0.53302 2.6615v0.47907q0 1.3574-0.53302 2.6615-0.50497 1.3041-1.543 2.3687-1.0099 1.038-2.4968 1.6767-1.4869 0.63876-3.4506 0.63876zm0-2.2889q1.6832 0 2.8615-0.69199 1.2063-0.7186 1.8235-1.9163 0.64524-1.1977 0.64524-2.6881 0-1.517-0.64524-2.7147-0.64524-1.1977-1.8516-1.8897-1.1783-0.69199-2.8334-0.69199-1.6271 0-2.8334 0.69199-1.2063 0.69199-1.8516 1.8897-0.64524 1.1977-0.64524 2.7147 0 1.4904 0.61718 2.6881 0.64524 1.1977 1.8235 1.9163 1.2063 0.69199 2.8896 0.69199z"/>
        <path d="m352.88 580.94v-14.212h2.1321v6.0948h-0.33665q0-2.1026 0.58914-3.5398 0.61718-1.4638 1.8516-2.209 1.2344-0.77183 3.114-0.77183h0.11221q2.8334 0 4.18 1.6767 1.3746 1.6768 1.3746 4.8439v8.1176h-2.6932v-8.6499q0-1.6501-0.98189-2.6349-0.98188-1.0114-2.6932-1.0114-1.7674 0-2.8615 1.038-1.0941 1.038-1.0941 2.768v8.4902z"/>
        <path d="m377.2 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89773-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44886-1.3041 1.3466-2.3421 0.89772-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61718 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53303 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5248q-0.30859 1.4638-1.2344 2.5284t-2.3285 1.6235q-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m399.59 581.36q-2.6651 0-4.0959-1.6501t-1.4308-4.7641v-8.2506h2.6932v8.7563q0 1.6768 0.95384 2.6349 0.95383 0.93152 2.581 0.93152 1.6832 0 2.7493-1.0114 1.066-1.038 1.066-2.8212v-8.4902h2.6932v14.239h-2.1321v-6.0948h0.33665q0 2.1026-0.58913 3.5664-0.58913 1.4372-1.7674 2.209-1.1502 0.74522-2.9457 0.74522z"/>
        <path d="m416.93 581.36q-2.9457 0-4.5728-1.1977-1.5991-1.1977-1.6552-3.2736h2.5249q0.0561 0.82507 0.86967 1.5703 0.84161 0.7186 2.8334 0.7186 1.7954 0 2.7493-0.66537 0.95384-0.69199 0.95384-1.6768 0-0.87829-0.70135-1.3574-0.70135-0.50569-2.2724-0.66538l-1.6271-0.15969q-2.1321-0.21292-3.4787-1.1977-1.3466-1.0114-1.3466-2.8212 0-1.3574 0.72941-2.3155 0.7294-0.98475 2.0199-1.4904 1.2905-0.5323 2.9737-0.5323 2.5248 0 4.0959 1.0646t1.6271 3.1672h-2.5248q-0.0281-0.85168-0.84162-1.4372-0.78551-0.61214-2.3565-0.61214t-2.3846 0.61214q-0.81356 0.61215-0.81356 1.5437 0 0.79845 0.56107 1.2775 0.58914 0.47907 1.9077 0.61215l1.6271 0.15969q2.4126 0.23953 3.8714 1.2775 1.4588 1.0114 1.4588 2.8744 0 1.3308-0.81357 2.3687-0.78551 1.0114-2.1882 1.5969-1.4027 0.55891-3.2262 0.55891z"/>
        <path d="m433.43 581.42q-1.9638 0-3.3945-0.63876t-2.3285-1.6767q-0.89772-1.0646-1.3466-2.3687-0.42081-1.3041-0.42081-2.6615v-0.47907q0-1.384 0.42081-2.6881 0.44887-1.3041 1.3466-2.3421 0.89772-1.0646 2.2724-1.6768 1.4027-0.63876 3.2823-0.63876 2.4407 0 4.0117 1.0114 1.5991 0.98476 2.3565 2.555 0.78551 1.5703 0.78551 3.3535v1.1444h-13.298v-1.9163h11.334l-0.61718 1.038q0-1.5171-0.50497-2.6083-0.50497-1.1178-1.5149-1.73-1.0099-0.61215-2.5529-0.61215-1.5991 0-2.6651 0.69199-1.038 0.69199-1.571 1.8897-0.50497 1.1977-0.50497 2.768 0 1.5171 0.50497 2.7414 0.53303 1.1977 1.6271 1.9163 1.0941 0.69199 2.7773 0.69199 1.7674 0 2.8615-0.74522 1.1222-0.74522 1.3466-1.73h2.5248q-0.30859 1.4638-1.2344 2.5284t-2.3285 1.6235q-1.4027 0.55891-3.1701 0.55891z"/>
        <path d="m457.52 581.42q-1.9638 0-3.4506-0.63876-1.4868-0.63876-2.5248-1.6767-1.0099-1.0646-1.543-2.3687-0.50497-1.3041-0.50497-2.6615v-0.47907q0-1.3574 0.53302-2.6615 0.53302-1.3308 1.571-2.3687 1.038-1.0646 2.5248-1.6768 1.4869-0.63876 3.3945-0.63876 1.9077 0 3.3945 0.63876 1.4869 0.61215 2.5248 1.6768 1.038 1.038 1.571 2.3687 0.53303 1.3041 0.53303 2.6615v0.47907q0 1.3574-0.53303 2.6615-0.50497 1.3041-1.543 2.3687-1.0099 1.038-2.4968 1.6767-1.4868 0.63876-3.4506 0.63876zm0-2.2889q1.6832 0 2.8615-0.69199 1.2063-0.7186 1.8235-1.9163 0.64524-1.1977 0.64524-2.6881 0-1.517-0.64524-2.7147-0.64524-1.1977-1.8516-1.8897-1.1783-0.69199-2.8334-0.69199-1.6271 0-2.8334 0.69199t-1.8516 1.8897q-0.64524 1.1977-0.64524 2.7147 0 1.4904 0.61719 2.6881 0.64524 1.1977 1.8235 1.9163 1.2063 0.69199 2.8896 0.69199z"/>
        <path d="m469.58 580.94v-14.212h2.1321v6.0948h-0.33665q0-2.1026 0.58913-3.5398 0.61719-1.4638 1.8516-2.209 1.2344-0.77183 3.114-0.77183h0.11222q2.8334 0 4.18 1.6767 1.3746 1.6768 1.3746 4.8439v8.1176h-2.6932v-8.6499q0-1.6501-0.98188-2.6349-0.98189-1.0114-2.6932-1.0114-1.7674 0-2.8615 1.038-1.0941 1.038-1.0941 2.768v8.4902z"/>
        <path d="m487.71 580.94v-19.429h2.6932v19.429zm-2.076-17.406v-2.0227h4.7692v2.0227z"/>
        <path d="m494.55 586.47v-2.2889h2.1602q0.95383 0 1.5991-0.23954 0.6733-0.21291 1.0941-0.74521 0.44887-0.5323 0.72941-1.4106l4.713-15.064h2.6371l-4.9936 15.729q-0.44886 1.4106-1.2624 2.2889-0.81356 0.90491-1.9918 1.3041-1.1502 0.42584-2.6932 0.42584zm4.5728-5.8553v-2.1292h3.2542v2.1292zm-0.28054 0-5.3583-13.893h2.7493l5.1339 13.893z"/>
      </g>
      <path id="VisaLogo" class="cls-30" d="m826.86 483.75-28.828 68.676h-18.809l-14.18-54.808c-0.86589-3.3808-1.6146-4.6161-4.2383-6.0334-4.2708-2.3211-11.328-4.4926-17.546-5.8514l0.42318-1.9895h30.273c3.8607 0 7.3242 2.5551 8.2031 6.9957l7.4935 39.744 18.509-46.74zm73.685 46.252c0.0781-18.126-25.104-19.128-24.922-27.222 0.0456-2.4706 2.4024-5.0842 7.5391-5.7539a33.555 33.509 0 0 1 17.578 3.0752l3.1185-14.563a47.591 47.526 0 0 0-16.641-3.0492c-17.578 0-29.948 9.3362-30.065 22.71-0.11068 9.8824 8.8346 15.396 15.579 18.685 6.9336 3.3613 9.2513 5.5263 9.2318 8.5365-0.0521 4.6031-5.5339 6.6381-10.645 6.7161-8.9453 0.14304-14.141-2.4056-18.281-4.3365l-3.2552 15.051c4.1536 1.905 11.823 3.5628 19.792 3.6474 18.685 0 30.911-9.2257 30.97-23.497m46.432 22.424h16.458l-14.362-68.676h-15.189a8.0924 8.0814 0 0 0-7.5716 5.0322l-26.693 63.644h18.678l3.7109-10.253h22.826zm-19.844-24.335 9.362-25.792 5.3841 25.792zm-74.87-44.341-14.714 68.676h-17.786l14.707-68.676z" stroke-width=".6506"/>
      <g id="VisaCommercial" stroke-width=".6506">
        <path class="cls-30" d="m793.84 582.24a10.846 10.832 0 0 0 0.96354 4.7071 7.3568 7.3467 0 0 0 2.8125 3.1662 8.3138 8.3025 0 0 0 4.4336 1.1313 10.716 10.702 0 0 0 4.3359-0.76068 8.7305 8.7186 0 0 0 2.8971-2.0285h0.0846l-0.26041 2.9907a10.612 10.598 0 0 1-3.125 1.5409 13.724 13.705 0 0 1-4.1927 0.59164 11.152 11.137 0 0 1-4.4792-0.87771 10.104 10.09 0 0 1-3.4245-2.4316 10.775 10.76 0 0 1-2.194-3.6149 13.21 13.192 0 0 1 0-8.8291 11.12 11.105 0 0 1 2.1745-3.6149 9.9219 9.9084 0 0 1 3.405-2.4251 10.905 10.89 0 0 1 4.4596-0.87771 15.189 15.168 0 0 1 4.1211 0.50063 8.3659 8.3545 0 0 1 2.9688 1.4823l0.28646 3.0817h-0.0846a8.5156 8.504 0 0 0-2.8646-2.0025 10.469 10.454 0 0 0-4.1667-0.73468 8.1771 8.1659 0 0 0-4.4206 1.1378 7.2917 7.2817 0 0 0-2.78 3.1662 11.029 11.014 0 0 0-0.95052 4.7006z"/>
        <path class="cls-30" d="m820.31 576.52a8.0273 8.0164 0 0 1 4.2188 1.1053 7.8125 7.8019 0 0 1 2.8646 3.0362 9.9023 9.8889 0 0 1 0 8.8031 7.7279 7.7173 0 0 1-2.8646 3.0232 8.6719 8.6601 0 0 1-8.4635 0 7.7148 7.7043 0 0 1-2.8776-3.0232 9.9023 9.8889 0 0 1 0-8.8031 7.8125 7.8019 0 0 1 2.8776-3.0362 8.0534 8.0424 0 0 1 4.2448-1.1053zm0 14.954a4.9023 4.8957 0 0 0 4.0755-1.7099 8.7435 8.7316 0 0 0 0-9.3752 5.7161 5.7084 0 0 0-8.1706 0 8.6719 8.6601 0 0 0 0 9.3752 4.9414 4.9347 0 0 0 4.095 1.6839z"/>
        <path class="cls-30" d="m845.64 580.43h0.11068a5.2083 5.2012 0 0 1 1.3867-2.2625 6.0286 6.0204 0 0 1 2.0573-1.2548 6.7773 6.7681 0 0 1 2.2591-0.39659 5.4688 5.4613 0 0 1 4.0364 1.4043c0.9375 0.94272 1.4128 2.3601 1.4128 4.2585v10.994h-2.5651v-10.474a4.8568 4.8502 0 0 0-0.76172-3.0232c-0.50781-0.65017-1.5365-0.94273-3.0729-0.94273a4.9805 4.9737 0 0 0-3.3268 1.1313 4.0885 4.083 0 0 0-1.3021 3.2963v10.012h-2.5651v-10.474a4.7656 4.7591 0 0 0-0.78125-3.0232c-0.52083-0.65017-1.543-0.94273-3.0794-0.94273a5.2474 5.2402 0 0 0-2.3633 0.52662 4.1081 4.1025 0 0 0-1.6862 1.5409 4.3815 4.3755 0 0 0-0.65104 2.3601v10.012h-2.5651v-16.254h2.4479v3.5173h0.14323a5.4167 5.4093 0 0 1 1.3542-2.2625 5.8138 5.8059 0 0 1 2.0052-1.2548 6.5104 6.5015 0 0 1 2.2331-0.3966 5.8984 5.8904 0 0 1 3.5286 0.96223 4.6094 4.6031 0 0 1 1.7448 2.9452z"/>
        <path class="cls-30" d="m875.06 580.43h0.11067a5.2604 5.2533 0 0 1 1.3802-2.2625 6.0547 6.0464 0 0 1 2.0638-1.2548 6.7773 6.7681 0 0 1 2.2591-0.39659 5.4753 5.4678 0 0 1 4.0365 1.4043c0.9375 0.94272 1.4128 2.3601 1.4128 4.2585v10.994h-2.5651v-10.474a4.8112 4.8046 0 0 0-0.76823-3.0232c-0.5013-0.65017-1.5299-0.94273-3.0664-0.94273a4.9805 4.9737 0 0 0-3.3268 1.1313 4.0885 4.083 0 0 0-1.3021 3.2963v10.012h-2.5651v-10.474a4.7656 4.7591 0 0 0-0.78125-3.0232c-0.52084-0.65017-1.543-0.94273-3.0859-0.94273a5.2865 5.2793 0 0 0-2.3633 0.52662 4.1276 4.122 0 0 0-1.6797 1.5409 4.3815 4.3755 0 0 0-0.65104 2.3601v10.012h-2.5651v-16.254h2.4479v3.5173h0.14323a5.4167 5.4093 0 0 1 1.3542-2.2625 5.7878 5.7799 0 0 1 1.9987-1.2548 6.556 6.5471 0 0 1 2.2396-0.3966 5.8984 5.8904 0 0 1 3.5286 0.96223 4.6094 4.6031 0 0 1 1.7448 2.9452z"/>
        <path class="cls-30" d="m898.44 591.5a9.9154 9.9019 0 0 0 3.5612-0.53312 8.8867 8.8746 0 0 0 2.4609-1.4238h0.0586l-0.22786 2.3276a7.5 7.4898 0 0 1-2.2526 1.1573 11.374 11.358 0 0 1-3.8021 0.54613 8.3984 8.387 0 0 1-4.3359-1.0923 7.6693 7.6588 0 0 1-2.8971-3.0232 9.9544 9.9409 0 0 1 0-8.8031 7.8711 7.8604 0 0 1 2.793-3.0362 7.4154 7.4053 0 0 1 3.9974-1.1053 8.3854 8.374 0 0 1 4.1602 0.90371 5.4167 5.4093 0 0 1 2.3438 2.5161 9.069 9.0567 0 0 1 0.73567 3.7839v0.69566a6.4714 6.4625 0 0 1-0.0911 0.71517h-12.363a0.097656 0.097523 0 0 0 0 0.0585 0.052083 0.052012 0 0 1 0 0.0585 6.6602 6.6511 0 0 0 1.6146 4.5511 5.4948 5.4873 0 0 0 4.2448 1.7034zm-0.65104-13.003a4.7135 4.7071 0 0 0-3.4896 1.3003 6.1328 6.1245 0 0 0-1.6146 3.6799h9.8242v-0.29257a6.5104 6.5015 0 0 0-0.42968-2.4836 3.3333 3.3288 0 0 0-1.4518-1.6449 5.7422 5.7344 0 0 0-2.806-0.59164z"/>
        <path class="cls-30" d="m915.36 579.16a3.7826 3.7774 0 0 0-2.9753 1.1963q-1.0677 1.1898-1.0612 3.6084v9.2062h-2.5651v-16.254h2.4479v3.4003h0.14323a4.6615 4.6551 0 0 1 1.875-2.9647 5.2734 5.2663 0 0 1 2.8776-0.8322 7.8125 7.8019 0 0 1 0.79427 0.039 3.3138 3.3093 0 0 1 0.56641 0.10402l-0.31901 2.7892h-0.0911a4.5573 4.5511 0 0 0-0.6901-0.18855 4.8503 4.8437 0 0 0-1.0026-0.10402z"/>
        <path class="cls-30" d="m918.99 585.06a9.3815 9.3687 0 0 1 1.0091-4.4015 7.5456 7.5353 0 0 1 2.8516-3.0362 8.2617 8.2505 0 0 1 4.3229-1.1053 9.7656 9.7523 0 0 1 3.444 0.48761 6.1979 6.1895 0 0 1 1.888 1.0338l0.25391 2.7046h-0.0521a8.0729 8.0619 0 0 0-2.3503-1.5539 7.7083 7.6978 0 0 0-3.0404-0.54612 5.638 5.6303 0 0 0-2.9492 0.76068 5.3125 5.3053 0 0 0-2.0182 2.204 7.4479 7.4378 0 0 0-0.73567 3.4523c0 2.009 0.54036 3.5758 1.6276 4.6876a5.6185 5.6108 0 0 0 4.2188 1.6709 9.5573 9.5443 0 0 0 2.3372-0.24706 6.0221 6.0139 0 0 0 1.6797-0.68916 13.783 13.764 0 0 0 1.4583-1.0208h0.0846l-0.26042 2.3276a6.5951 6.5861 0 0 1-2.0833 1.2288 9.5117 9.4988 0 0 1-3.5026 0.55913 8.3138 8.3025 0 0 1-4.3229-1.0923 7.474 7.4638 0 0 1-2.8516-3.0232 9.401 9.3882 0 0 1-1.0091-4.4016z"/>
        <path class="cls-30" d="m936.33 572.95a1.3802 1.3783 0 0 1 0.44922-1.0532 1.5951 1.5929 0 0 1 1.1328-0.4161 1.6667 1.6644 0 0 1 1.1523 0.4161 1.3737 1.3718 0 0 1 0.46224 1.0532 1.3997 1.3978 0 0 1-0.46224 1.0793 1.6406 1.6384 0 0 1-1.1523 0.4161 1.569 1.5669 0 0 1-1.1328-0.4161 1.4128 1.4108 0 0 1-0.44922-1.0793zm2.8776 3.966v16.254h-2.5651v-16.254z"/>
        <path class="cls-30" d="m944.11 580.52 0.28645-2.5096a8.724 8.7121 0 0 1 2.3047-1.0598 10.189 10.175 0 0 1 3.0274-0.4291q3.138 0 4.6614 1.2353c1.0221 0.8192 1.53 2.1845 1.53 4.083v11.339h-2.4479v-3.1077h-0.11718a4.9805 4.9737 0 0 1-1.9531 2.6591 6.6471 6.6381 0 0 1-5.8073 0.34458 4.2188 4.213 0 0 1-1.8294-1.5214 4.5573 4.5511 0 0 1-0.69661-2.6006 4.3945 4.3885 0 0 1 0.65104-2.5421 4.2122 4.2065 0 0 1 1.862-1.4824 12.454 12.437 0 0 1 2.8646-0.80619c1.3672-0.22755 2.4479-0.44211 3.2552-0.65015a3.7891 3.7839 0 0 0 1.7122-0.77369v-0.74118a3.5286 3.5238 0 0 0-0.82031-2.6006q-0.82031-0.7997-3.0664-0.7997a8.5352 8.5235 0 0 0-2.8385 0.48762 7.6758 7.6653 0 0 0-2.5195 1.4694zm9.2773 7.2232v-3.3678a4.1797 4.174 0 0 1-1.6276 0.70867c-0.78125 0.19504-1.7448 0.4096-2.8971 0.61764a5.6445 5.6368 0 0 0-2.3763 0.85821 2.5651 2.5616 0 0 0-0.90495 2.2495 2.6758 2.6721 0 0 0 0.84635 2.1715 3.5742 3.5693 0 0 0 2.3503 0.70216 4.8698 4.8632 0 0 0 2.474-0.59813 4.4596 4.4536 0 0 0 1.5885-1.5149 3.5221 3.5173 0 0 0 0.54688-1.8269z"/>
        <path class="cls-30" d="m963.18 570.45v22.755h-2.5651v-22.755z"/>
      </g>
      <g id="NiumLogo">
        <polygon class="cls-30" transform="matrix(.65104 0 0 .65015 -429.76 -64.378)" points="2097.6 243.57 2097.6 158.69 2080.2 158.69 2056.6 201.33 2056.2 201.33 2031.4 158.69 2013.2 158.69 2013.2 243.57 2028.5 243.57 2028.5 185.19 2028.9 185.19 2054.2 227.83 2055.8 227.83 2081.9 185.19 2082.3 185.19 2082.3 243.57"/>
        <polygon class="cls-30" transform="matrix(.65104 0 0 .65015 -429.76 -64.378)" points="1815.6 215.41 1776.7 158.69 1759.3 158.69 1759.3 243.57 1775.5 243.57 1775.5 186.01 1775.9 186.01 1815.2 243.57 1832.2 243.57 1832.2 158.69 1816.1 158.69 1816.1 215.41"/>
        <path class="cls-30" d="m814.85 72.98c0 14.264 8.6263 22.346 23.184 22.346 13.483 0 23.438-8.0814 23.438-22.346v-34.192h-11.068v34.192c0 6.7291-3.2552 12.385-12.37 12.385-9.1146 0-12.129-6.4625-12.129-13.192v-33.385h-11.068v34.192z" stroke-width=".6506"/>
        <polygon class="cls-30" transform="matrix(.65104 0 0 .65015 -429.76 -64.378)" points="1881.9 243.57 1881.9 158.69 1864.9 158.69 1864.9 243.57"/>
      </g>
      <metadata>
        <rdfRDF>
        <ccWork rdfabout="">
          <dctitle>Nium Credit Card</dctitle>
        </ccWork>
        </rdfRDF>
      </metadata>
      <g fill="#ffffff">
        <g font-family="Faktum" font-weight="500">
        <text id="TextCVV" x="138.08292" y="365.22" font-size="37.333px" style={{fontFamily:'Faktum',fontVariantCaps:'normal',fontVariantEastAsian:'normal',fontVariantLigatures:'normal',fontVariantNumeric:'normal',lineHeight:1.25}} xmlSpace="preserve"><tspan x="138.08292" y="365.22">{props.cvv}</tspan></text>
        <text id="TextExpiry" x="417.57898" y="365.22363" font-size="37.333px" style={{fontFamily:'Faktum',fontVariantCaps:'normal',fontVariantEastAsian:'normal',fontVariantLigatures:'normal',fontVariantNumeric:'normal',lineHeight:1.25}} xmlSpace="preserve"><tspan x="417.57898" y="365.22363">{props.expiry}</tspan></text>
        <text id="TextCardholderName" x="64.950668" y="473.7543" font-size="25.333px" style={{fontFamily:'Faktum',fontVariantCaps:'normal',fontVariantEastAsian:'normal',fontVariantLigatures:'normal',fontVariantNumeric:'normal',lineHeight:1.25}} xmlSpace="preserve"><tspan x="64.950668" y="473.7543">{props.name}</tspan></text>
        </g>
        <text id="TextAccountNumber" x="62.970001" y="295.98465" font-family="Forza" font-size="51.333px" font-weight="300" style={{fontFamily:'Forza',fontVariantCaps:'normal',fontVariantEastAsian:'normal',fontVariantLigatures:'normal',fontVariantNumeric:'normal',lineHeight:1.25}} xmlSpace="preserve"><tspan x="62.970001" y="295.98465">{FormatCardNumber(props.number)}</tspan></text>
      </g>
      </svg>
  </div>
);


export default CreditCardMint;
