import {
	TextField, Labeled, useTranslate, useRecordContext, ArrayField, Datagrid, BooleanField, DateField
} from 'react-admin';
import { DateTimeLocale } from '../shared';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import TextArrayField from './TextArrayField';

const ActiveIcon = () => <CheckIcon style={{ color: '#14cb14' }} />;
const InactiveIcon = () => <ClearIcon style={{ color: '#fd3c39' }} />;

const CardCreateRequestEnettView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.activation_date')}>
					<DateField source={source + '.eNett.activationDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.expiry_date')}>
					<DateField source={source + '.eNett.expiryDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.minimum_authorisation_amount')}>
					<TextField source={source + '.eNett.minimumAuthorisationAmount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.maximum_authorisation_amount')}>
					<TextField source={source + '.eNett.maximumAuthorisationAmount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.currency_code')}>
					<TextField source={source + '.eNett.currencyCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.funding_currency_code')}>
					<TextField source={source + '.eNett.fundingCurrencyCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.integrator_reference')}>
					<TextField source={source + '.eNett.integratorReference'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.is_multi_use')}>
					<BooleanField source={source + '.eNett.isMultiUse'} TrueIcon={ActiveIcon} FalseIcon={InactiveIcon} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.merchant_category_name')}>
					<TextField source={source + '.eNett.merchantCategoryName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.notes')}>
					<TextField source={source + '.eNett.notes'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.eNett_create_request.user_references')}>
					<ArrayField source={source + '.eNett.userReferences'} >
						<Datagrid classes={props.classes} bulkActionButtons={false}>
							<TextField source="label" label={translate('kuggar.shared.label')} />
							<TextField source="value" label={translate('kuggar.shared.value')} />
						</Datagrid>
					</ArrayField>
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusFlightView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.passenger_name')}>
					<TextField source={source + '.airPlusFlight.passengerName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.flight_date')}>
					<DateField source={source + '.airPlusFlight.flightDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.departure_airport_code')}>
					<TextField source={source + '.airPlusFlight.departureAirportCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.destination_airport_code')}>
					<TextField source={source + '.airPlusFlight.destinationAirportCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.airline_code')}>
					<TextField source={source + '.airPlusFlight.airlineCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_flight_create_request.flight_class')}>
					<TextField source={source + '.airPlusFlight.flightClass'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_from_date')}>
					<DateField source={source + '.airPlusFlight.validFromDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_to_date')}>
					<DateField source={source + '.airPlusFlight.validToDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit')}>
					<TextField source={source + '.airPlusFlight.limit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')}>
					<TextField source={source + '.airPlusFlight.limitCurrencyNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')}>
					<TextArrayField source={source + '.airPlusFlight.transactionCurrencyNumericCodes'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_name')}>
					<TextField source={source + '.airPlusFlight.merchantName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')}>
					<TextField source={source + '.airPlusFlight.merchantCountryNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_name')}>
					<TextField source={source + '.airPlusFlight.generatingUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_mail')}>
					<TextField source={source + '.airPlusFlight.generatingUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_id')}>
					<TextField source={source + '.airPlusFlight.generatingUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_phone')}>
					<TextField source={source + '.airPlusFlight.generatingUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_name')}>
					<TextField source={source + '.airPlusFlight.cardUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_mail')}>
					<TextField source={source + '.airPlusFlight.cardUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_id')}>
					<TextField source={source + '.airPlusFlight.cardUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_phone')}>
					<TextField source={source + '.airPlusFlight.cardUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')}>
					<TextField source={source + '.airPlusFlight.dbiAccountingUnit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_code')}>
					<TextField source={source + '.airPlusFlight.dbiAccountingCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_order_number')}>
					<TextField source={source + '.airPlusFlight.dbiOrderNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_departure_date')}>
					<DateField source={source + '.airPlusFlight.dbiDepartureDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_department_code')}>
					<TextField source={source + '.airPlusFlight.dbiDepartmentCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_internal_account')}>
					<TextField source={source + '.airPlusFlight.dbiInternalAccount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_cost_centre')}>
					<TextField source={source + '.airPlusFlight.dbiCostCentre'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_employee_number')}>
					<TextField source={source + '.airPlusFlight.dbiEmployeeNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_project_number')}>
					<TextField source={source + '.airPlusFlight.dbiProjectNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_destination')}>
					<TextField source={source + '.airPlusFlight.dbiDestination'} />
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusHotelView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.air_plus_hotel_create_request.guest_name')}>
					<TextField source={source + '.airPlusHotel.guestName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_hotel_create_request.check_in_date')}>
					<DateField source={source + '.airPlusHotel.checkInDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_hotel_create_request.check_out_date')}>
					<DateField source={source + '.airPlusHotel.checkOutDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_hotel_create_request.room_nights')}>
					<TextField source={source + '.airPlusHotel.roomNights'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_from_date')}>
					<DateField source={source + '.airPlusHotel.validFromDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_to_date')}>
					<DateField source={source + '.airPlusHotel.validToDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit')}>
					<TextField source={source + '.airPlusHotel.limit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')}>
					<TextField source={source + '.airPlusHotel.limitCurrencyNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')}>
					<TextArrayField source={source + '.airPlusHotel.transactionCurrencyNumericCodes'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_name')}>
					<TextField source={source + '.airPlusHotel.merchantName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')}>
					<TextField source={source + '.airPlusHotel.merchantCountryNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_name')}>
					<TextField source={source + '.airPlusHotel.generatingUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_mail')}>
					<TextField source={source + '.airPlusHotel.generatingUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_id')}>
					<TextField source={source + '.airPlusHotel.generatingUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_phone')}>
					<TextField source={source + '.airPlusHotel.generatingUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_name')}>
					<TextField source={source + '.airPlusHotel.cardUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_mail')}>
					<TextField source={source + '.airPlusHotel.cardUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_id')}>
					<TextField source={source + '.airPlusHotel.cardUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_phone')}>
					<TextField source={source + '.airPlusHotel.cardUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')}>
					<TextField source={source + '.airPlusHotel.dbiAccountingUnit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_code')}>
					<TextField source={source + '.airPlusHotel.dbiAccountingCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_order_number')}>
					<TextField source={source + '.airPlusHotel.dbiOrderNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_departure_date')}>
					<DateField source={source + '.airPlusHotel.dbiDepartureDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_department_code')}>
					<TextField source={source + '.airPlusHotel.dbiDepartmentCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_internal_account')}>
					<TextField source={source + '.airPlusHotel.dbiInternalAccount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_cost_centre')}>
					<TextField source={source + '.airPlusHotel.dbiCostCentre'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_employee_number')}>
					<TextField source={source + '.airPlusHotel.dbiEmployeeNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_project_number')}>
					<TextField source={source + '.airPlusHotel.dbiProjectNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_destination')}>
					<TextField source={source + '.airPlusHotel.dbiDestination'} />
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestAirPlusProcurementView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.air_plus_procurement_create_request.procurement_type')}>
					<TextField source={source + '.airPlusProcurement.procurementType'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_procurement_create_request.name')}>
					<TextField source={source + '.airPlusProcurement.name'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_procurement_create_request.free_text')}>
					<TextField source={source + '.airPlusProcurement.freeText'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_from_date')}>
					<DateField source={source + '.airPlusProcurement.validFromDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.valid_to_date')}>
					<DateField source={source + '.airPlusProcurement.validToDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit')}>
					<TextField source={source + '.airPlusProcurement.limit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.limit_currency_numeric_code')}>
					<TextField source={source + '.airPlusProcurement.limitCurrencyNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.transaction_currency_numeric_codes')}>
					<TextArrayField source={source + '.airPlusProcurement.transactionCurrencyNumericCodes'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_name')}>
					<TextField source={source + '.airPlusProcurement.merchantName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.merchant_country_numeric_code')}>
					<TextField source={source + '.airPlusProcurement.merchantCountryNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_name')}>
					<TextField source={source + '.airPlusProcurement.generatingUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_mail')}>
					<TextField source={source + '.airPlusProcurement.generatingUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_id')}>
					<TextField source={source + '.airPlusProcurement.generatingUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.generating_user_phone')}>
					<TextField source={source + '.airPlusProcurement.generatingUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_name')}>
					<TextField source={source + '.airPlusProcurement.cardUserName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_mail')}>
					<TextField source={source + '.airPlusProcurement.cardUserMail'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_id')}>
					<TextField source={source + '.airPlusProcurement.cardUserID'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.card_user_phone')}>
					<TextField source={source + '.airPlusProcurement.cardUserPhone'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_unit')}>
					<TextField source={source + '.airPlusProcurement.dbiAccountingUnit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_accounting_code')}>
					<TextField source={source + '.airPlusProcurement.dbiAccountingCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_order_number')}>
					<TextField source={source + '.airPlusProcurement.dbiOrderNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_departure_date')}>
					<DateField source={source + '.airPlusProcurement.dbiDepartureDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_department_code')}>
					<TextField source={source + '.airPlusProcurement.dbiDepartmentCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_internal_account')}>
					<TextField source={source + '.airPlusProcurement.dbiInternalAccount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_cost_centre')}>
					<TextField source={source + '.airPlusProcurement.dbiCostCentre'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_employee_number')}>
					<TextField source={source + '.airPlusProcurement.dbiEmployeeNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_project_number')}>
					<TextField source={source + '.airPlusProcurement.dbiProjectNumber'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.air_plus_create_request.dbi_destination')}>
					<TextField source={source + '.airPlusProcurement.dbiDestination'} />
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestWexView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.amount')}>
					<TextField source={source + '.wex.amount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.active_from_date')}>
					<DateField source={source + '.wex.activeFromDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.active_to_date')}>
					<DateField source={source + '.wex.activeToDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>			
				<Labeled label={translate('kuggar.wex_create_request.billing_currency')}>
					<TextField source={source + '.wex.billingCurrency'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.local_currency')}>
					<TextField source={source + '.wex.localCurrency'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.min_amount')}>
					<TextField source={source + '.wex.minAmount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.auth_hold_days')}>
					<TextField source={source + '.wex.authHoldDays'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.wex_create_request.user_defined_fields')}>
					<ArrayField source={source + '.wex.userDefinedFields'} >
						<Datagrid classes={props.classes} bulkActionButtons={false}>
							<TextField source="label" label={translate('kuggar.shared.name')} />
							<TextField source="value" label={translate('kuggar.shared.value')} />
						</Datagrid>
					</ArrayField>
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestMastercardView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.valid_from_date')}>
					<DateField source={source + '.mastercard.validFromDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.valid_to_date')}>
					<DateField source={source + '.mastercard.validToDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.minimum_amount')}>
					<TextField source={source + '.mastercard.minimumAmount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.maximum_amount')}>
					<TextField source={source + '.mastercard.maximumAmount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.maximum_transactions')}>
					<TextField source={source + '.mastercard.maximumTransactions'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.currency_numeric_code')}>
					<TextField source={source + '.mastercard.currencyNumericCode'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.is_merchant_currency')}>
					<BooleanField source={source + '.mastercard.isMerchantCurrency'} TrueIcon={ActiveIcon} FalseIcon={InactiveIcon} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.notify_supplier')}>
					<BooleanField source={source + '.mastercard.notifySupplier'} TrueIcon={ActiveIcon} FalseIcon={InactiveIcon} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.supplier_emails')}>
					<TextArrayField source={source + '.mastercard.supplierEmails'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.country_codes')}>
					<TextArrayField source={source + '.mastercard.countryCodes'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.strict_pre_auth_check')}>
					<BooleanField source={source + '.mastercard.strictPreAuthCheck'} TrueIcon={ActiveIcon} FalseIcon={InactiveIcon} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.authorisation_hold_days')}>
					<TextField source={source + '.mastercard.authorisationHoldDays'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.velocity_period')}>
					<TextField source={source + '.mastercard.velocityPeriod'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.curfew_days')}>
					<TextArrayField source={source + '.mastercard.curfew.daysOfWeek'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.curfew_from_time')}>
					<TextField source={source + '.mastercard.curfew.fromTimeUtc'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.curfew_to_time')}>
					<TextField source={source + '.mastercard.curfew.toTimeUtc'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.times_of_day')}>
					<TextArrayField source={source + '.mastercard.timesOfDay'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mastercard_create_request.custom_fields')}>
					<ArrayField source={source + '.mastercard.customFields'} >
						<Datagrid classes={props.classes} bulkActionButtons={false}>
							<TextField source="label" label={translate('kuggar.shared.name')} />
							<TextField source="value" label={translate('kuggar.shared.value')} />
						</Datagrid>
					</ArrayField>
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestMintView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.virtual_card_factory_name')}>
					<TextField source={source + '.mint.virtualCardFactoryName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.currency')}>
					<TextField source={source + '.mint.currency'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.funding_account_reference')}>
					<TextField source={source + '.mint.fundingAccountReference'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.external_call_reference')}>
					<TextField source={source + '.mint.externalCallReference'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.card_name')}>
					<TextField source={source + '.mint.cardName'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.amount')}>
					<TextField source={source + '.mint.amount'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.external_identifier')}>
					<TextField source={source + '.mint.externalIdentifier'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.card_user')}>
					<TextField source={source + '.mint.cardUser'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.authentication_data')}>
					<TextField source={source + '.mint.authenticationData'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.clearance_date')}>
					<DateField source={source + '.mint.clearanceDate'} showTime locales={DateTimeLocale} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.mint_create_request.card_info')}>
					<ArrayField source={source + '.mint.cardInfo'} >
						<Datagrid classes={props.classes} bulkActionButtons={false}>
							<TextField source="label" label={translate('kuggar.shared.label')} />
							<TextField source="value" label={translate('kuggar.shared.value')} />
						</Datagrid>
					</ArrayField>
				</Labeled>
			</div>
		</span>
	);
};

const CardCreateRequestKuggarView = props => {
	const translate = useTranslate();
	const { source } = props;

	return (
		<span>
			<div>
				<Labeled label={translate('kuggar.kuggar_create_request.limit')}>
					<TextField source={source + '.kuggar.limit'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.kuggar_create_request.active_from_date')}>
					<TextField source={source + '.kuggar.activeFromDate'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.kuggar_create_request.active_to_date')}>
					<TextField source={source + '.kuggar.activeToDate'} />
				</Labeled>
			</div>
			<div>
				<Labeled label={translate('kuggar.kuggar_create_request.currency')}>
					<TextField source={source + '.kuggar.currency'} />
				</Labeled>
			</div>			
			<div>
				<Labeled label={translate('kuggar.kuggar_create_request.metadata')}>
					<ArrayField source={source + '.kuggar.metadata'} >
						<Datagrid classes={props.classes} bulkActionButtons={false}>
							<TextField source="label" label={translate('kuggar.shared.name')} />
							<TextField source="value" label={translate('kuggar.shared.value')} />
						</Datagrid>
					</ArrayField>
				</Labeled>
			</div>
		</span>

	);
};

const CardCreateRequestView = props => {
	const record = useRecordContext(props);
	const { source } = props;

	if (record.provider === 'eNett') {
		return (
			<span>
				<CardCreateRequestEnettView source={source} />
			</span>
		);
	}
	else if (record.provider === 'AirPlusFlight') {
		return (
			<span>
				<CardCreateRequestAirPlusFlightView source={source} />
			</span>
		);
	}
	else if (record.provider === 'AirPlusHotel') {
		return (
			<span>
				<CardCreateRequestAirPlusHotelView source={source} />
			</span>
		);
	}
	else if (record.provider === 'AirPlusProcurement') {
		return (
			<span>
				<CardCreateRequestAirPlusProcurementView source={source} />
			</span>
		);
	}
	else if (record.provider === 'WEX') {
		return (
			<CardCreateRequestWexView source={source} />
		);
	}
	else if (record.provider === 'Mastercard') {
		return (
			<CardCreateRequestMastercardView source={source} />
		);
	}
	else if (record.provider === 'Mint') {
		return (
			<CardCreateRequestMintView source={source} />
		);
	}
	else if (record.provider === "Kuggar") {
		return (
			<CardCreateRequestKuggarView source={source} />
		);
	}
	else {
		return (
			null
		);
    }
};

export default CardCreateRequestView;