import {
    List, Datagrid, TextField, TopToolbar, EditButton,
    Show, TabbedShowLayout, Tab, useTranslate, useRecordContext,
    Edit, SimpleForm, TextInput, required, useNotify,
    useRedirect, Create, FileInput, FileField
} from "react-admin";

import {
    CreateToolbar,
    HasCreatePermission,
    TableFilter,
    TablePagination,
    HasUpdatePermission,
    UpdateToolbar, kuggarExporter
} from "../shared";

export const PgpPublicKeyList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            filters={<TableFilter />}
            pagination={<TablePagination />}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/pgp_public_keys')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="description" label={translate('kuggar.shared.description')} />
            </Datagrid>
        </List>
    );
};

export const PgpPublicKeyShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/pgp_public_keys') ?
                    <EditButton
                        label={translate('kuggar.pgp_public_keys.update_pgp_public_key')}
                        record={data} />
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<Actions />}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')} />
                    <TextField source="description" label={translate('kuggar.shared.description')} />
                    <TextField source="key" label={translate('kuggar.pgp_public_keys.key')} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const PgpPublicKeyEdit = () => {
    const translate = useTranslate();

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar />}>
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true} />
                <FileInput source="attachments">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
};

export const PgpPublicKeyCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`${translate('kuggar.pgp_public_keys.on_success_notify')}`);
        redirect(`/pgp_public_key/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <TextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true} />
                <FileInput source="attachments">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};