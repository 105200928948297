import * as React from "react";
import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField, FunctionField,
	PasswordInput, SelectInput, 
	TopToolbar, EditButton,
	required, Labeled, useGetList, useTranslate, useNotify, useRedirect, useRecordContext
} from 'react-admin';
import {
	TableFilter,
	TablePagination,
	HasCreatePermission,
	HasUpdatePermission,
	UpdateToolbar,
	CreateToolbar,
	TextFieldWithCopyButton, ProviderFilter, KuggarTextInput, kuggarExporter
} from '../shared';
import { useState } from 'react';
import ImageField from '../components/ImageField';
import { Environment } from "../configProvider";

export const IssuerCredentialsList = () => {
	const translate = useTranslate();

	const filter = [
		<KuggarTextInput label={translate('ra.action.search')} source="q" alwaysOn/>,
		<ProviderFilter alwaysOn isFilter/>
	];

	return (
		<List
			exporter={kuggarExporter}
			filters={filter}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('VirtualCards', '/issuer_credential')}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<ImageField source="provider" path="cards" height="50" label={translate('kuggar.virtual_cards.provider')} />
				<TextField source="description" label={translate('kuggar.shared.name')} />
			</Datagrid>
		</List>
	);
};

export const IssuerCredentialsShow = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>Issuer Credentials - ID {record ? record.id : ''}</span>;
	};

	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/issuer_credential/') ?
					<EditButton
						label={translate('kuggar.issuer_credentials.update_issuer_credentials')}
						record={data} />
					: null}
			</TopToolbar>
		);
	};

	const IssuerCredentialsProviderData = () => {
		const record = useRecordContext();
		if (record.provider === 'eNett') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.eNett && record.issuerCredentialData.eNett.clientAccessKey &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.eNett_client_access_key')}>
								<TextField source="issuerCredentialData.eNett.clientAccessKey" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.eNett && record.issuerCredentialData.eNett.integratorCode &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.eNett_integrator_code')}>
								<TextField source="issuerCredentialData.eNett.integratorCode" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.eNett && record.issuerCredentialData.eNett.integratorAccessKey &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.eNett_integrator_access_key')}>
								<FunctionField render={record => record.issuerCredentialData.eNett.integratorAccessKey.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else if (record.provider === 'AirPlusHotel' || record.provider === 'AirPlusFlight' || record.provider === 'AirPlusProcurement') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.airPlus && record.issuerCredentialData.airPlus.username &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.airPlus_username')}>
								<TextField source="issuerCredentialData.airPlus.username" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.airPlus && record.issuerCredentialData.airPlus.password &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.airPlus_password')}>
								<FunctionField render={record => record.issuerCredentialData.airPlus.password.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else if (record.provider === 'Mastercard') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.mastercard && record.issuerCredentialData.mastercard.consumerKey &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.masterCard_consumer_key')}>
								<TextField source="issuerCredentialData.mastercard.consumerKey" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.mastercard && record.issuerCredentialData.mastercard.pkcs12Base64 &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.masterCard_pkcs12base64')}>
								<TextField source="issuerCredentialData.mastercard.pkcs12Base64" style={{overflowWrap:'anywhere'}} />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.mastercard && record.issuerCredentialData.mastercard.signingKeyPassword &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.masterCard_signing_key_password')}>
								<FunctionField render={record => record.issuerCredentialData.mastercard.signingKeyPassword.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else if (record.provider === 'Mint') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.mint && record.issuerCredentialData.mint.apiKey &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.mint_api_key')}>
								<TextField source="issuerCredentialData.mint.apiKey" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.mint && record.issuerCredentialData.mint.password &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.mint_password')}>
								<FunctionField render={record => record.issuerCredentialData.mint.password.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else if (record.provider === 'WEX') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.wex && record.issuerCredentialData.wex.orgGroupLoginId &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.wex_org_group_login_id')}>
								<TextField source="issuerCredentialData.wex.orgGroupLoginId" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.wex && record.issuerCredentialData.wex.username &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.wex_username')}>
								<TextField source="issuerCredentialData.wex.username" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.wex && record.issuerCredentialData.wex.password &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.wex_password')}>
								<FunctionField render={record => record.issuerCredentialData.wex.password.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else if (record.provider === 'Kuggar') {
			return (
				<div>
					{
						record.issuerCredentialData && record.issuerCredentialData.kuggar && record.issuerCredentialData.kuggar.authUrl &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.kuggar_auth_url')}>
								<TextField source="issuerCredentialData.kuggar.authUrl" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.kuggar && record.issuerCredentialData.kuggar.clientId &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.kuggar_client_id')}>
								<TextField source="issuerCredentialData.kuggar.clientId" />
							</Labeled>
						</div>
					}
					{
						record.issuerCredentialData && record.issuerCredentialData.kuggar && record.issuerCredentialData.kuggar.clientSecret &&
						<div>
							<Labeled label={translate('kuggar.issuer_credentials.kuggar_client_secret')}>
								<FunctionField render={record => record.issuerCredentialData.kuggar.clientSecret.replace(/./g, '*')} />
							</Labeled>
						</div>
					}
				</div>
			);
		}
		else {
			return (
				null
			);
		}
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
			<TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
					<TextField source="issuerEnvironmentId" label={translate('kuggar.issuer_credentials.issuer_environment_id')} />
					<TextField source="description" label={translate('kuggar.shared.name')} />
					<IssuerCredentialsProviderData />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const IssuerCredentialsEdit = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.issuer_credentials.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
	};

	const IssuerCredentialsProviderEdit = () => {
		const record = useRecordContext();
		if (record.provider === 'eNett') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.eNett.clientAccessKey"
						label={translate('kuggar.issuer_credentials.eNett_client_access_key')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.eNett.integratorCode"
						label={translate('kuggar.issuer_credentials.eNett_integrator_code')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.eNett.integratorAccessKey"
						label={translate('kuggar.issuer_credentials.eNett_integrator_access_key')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (record.provider === 'AirPlusHotel' || record.provider === 'AirPlusFlight' || record.provider === 'AirPlusProcurement') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.airPlus.username"
						label={translate('kuggar.issuer_credentials.airPlus_username')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.airPlus.password"
						label={translate('kuggar.issuer_credentials.airPlus_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (record.provider === 'Mastercard') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.mastercard.consumerKey"
						label={translate('kuggar.issuer_credentials.masterCard_consumer_key')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.mastercard.pkcs12Base64"
						label={translate('kuggar.issuer_credentials.masterCard_pkcs12base64')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.mastercard.signingKeyPassword"
						label={translate('kuggar.issuer_credentials.masterCard_signing_key_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (record.provider === 'Mint') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.mint.apiKey"
						label={translate('kuggar.issuer_credentials.mint_api_key')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.mint.password"
						label={translate('kuggar.issuer_credentials.mint_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (record.provider === 'WEX') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.wex.orgGroupLoginId"
						label={translate('kuggar.issuer_credentials.wex_org_group_login_id')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.wex.username"
						label={translate('kuggar.issuer_credentials.wex_username')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.wex.password"
						label={translate('kuggar.issuer_credentials.wex_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (record.provider === 'Kuggar') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.kuggar.authUrl"
						label={translate('kuggar.issuer_credentials.kuggar_auth_url')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.kuggar.clientId"
						label={translate('kuggar.issuer_credentials.kuggar_client_id')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.kuggar.clientSecret"
						label={translate('kuggar.issuer_credentials.kuggar_client_secret')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else {
			return (
				null
			);
		}
	};

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />}>
				<KuggarTextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<IssuerCredentialsProviderEdit />

			</SimpleForm>
		</Edit>
	);
};

export const IssuerCredentialsCreate = () => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();	
	const [provider, setProvider] = useState(null);

	const { data: issuerEnvData, isLoading: issuerEnvLoading, error: issuerEnvError } = useGetList(
		'issuer_environment',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {

			}
		}
	);

	if (issuerEnvLoading) return <Loading />;
	if (issuerEnvError) return <Error />;
	if (!issuerEnvData) return null;

	const onIssuerEnvironmentChange = (value) => {
		const provider = getProvider(value.target.value);
		setProvider(provider);
	};

	const getProvider = (issuerEnvironmentId) => {
		const issuerEnv = issuerEnvData.find(element => element.id === issuerEnvironmentId);
		return issuerEnv.provider;
	};

	const validateIssuerCredentialsCreate = (values) => {
		const errors = {};

		return errors;
	};

	const inputStyle = { width: '512px' };

	const onSuccess = (data) => {
		notify(`${translate('kuggar.issuer_credentials.on_success_notify')}`);
		redirect(`/issuer_credentials/${data.id}/show`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	const IssuerCredentialsProviderCreate = (props) => {
		if (props != null && props.provider === 'eNett') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.eNett.clientAccessKey"
						label={translate('kuggar.issuer_credentials.eNett_client_access_key')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.eNett.integratorCode"
						label={translate('kuggar.issuer_credentials.eNett_integrator_code')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.eNett.integratorAccessKey"
						label={translate('kuggar.issuer_credentials.eNett_integrator_access_key')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (props != null && (props.provider === 'AirPlusHotel' || props.provider === 'AirPlusFlight' || props.provider === 'AirPlusProcurement')) {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.airPlus.username"
						label={translate('kuggar.issuer_credentials.airPlus_username')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.airPlus.password"
						label={translate('kuggar.issuer_credentials.airPlus_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (props != null && props.provider === 'Mastercard') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.mastercard.consumerKey"
						label={translate('kuggar.issuer_credentials.masterCard_consumer_key')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.mastercard.pkcs12Base64"
						label={translate('kuggar.issuer_credentials.masterCard_pkcs12base64')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.mastercard.signingKeyPassword"
						label={translate('kuggar.issuer_credentials.masterCard_signing_key_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (props != null && props.provider === 'Mint') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.mint.apiKey"
						label={translate('kuggar.issuer_credentials.mint_api_key')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.mint.password"
						label={translate('kuggar.issuer_credentials.mint_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (props != null && props.provider === 'WEX') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.wex.orgGroupLoginId"
						label={translate('kuggar.issuer_credentials.wex_org_group_login_id')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.wex.username"
						label={translate('kuggar.issuer_credentials.wex_username')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.wex.password"
						label={translate('kuggar.issuer_credentials.wex_password')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else if (props != null && props.provider === 'Kuggar') {
			return (
				<div>
					<KuggarTextInput
						source="issuerCredentialData.kuggar.authUrl"
						label={translate('kuggar.issuer_credentials.kuggar_auth_url')}
						fullWidth={true}
						validate={[required()]} />
					<KuggarTextInput
						source="issuerCredentialData.kuggar.clientId"
						label={translate('kuggar.issuer_credentials.kuggar_client_id')}
						fullWidth={true}
						validate={[required()]} />
					<PasswordInput
						source="issuerCredentialData.kuggar.clientSecret"
						label={translate('kuggar.issuer_credentials.kuggar_client_secret')}
						fullWidth={true}
						validate={[required()]} />
				</div>
			);
		}
		else {
			return null;
		}
	};

	return (
		<Create title={translate('kuggar.issuer_credentials.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateIssuerCredentialsCreate}>
				<SelectInput
					source="issuerEnvironmentId"
					label={translate('kuggar.shared.issuer_environment')}
					validate={[required()]}
					choices={issuerEnvData}
					optionText="description"
					optionValue="id"
					onChange={onIssuerEnvironmentChange}
					style={inputStyle} />
				<KuggarTextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<IssuerCredentialsProviderCreate provider={provider} />

			</SimpleForm>
		</Create>
	);
};