import englishMessages from './englishMessages?hostName=mint';

let messages = englishMessages;
messages.kuggar.menu.prepaid_accounts = 'Balance Instruments';
messages.kuggar.menu.funding_account_activity = 'Balance Instrument Activity';
messages.kuggar.prepaid_accounts.prepaid_account = 'Balance Instrument';
messages.kuggar.prepaid_accounts.available_balance = 'Limit';
messages.kuggar.prepaid_accounts.scheduled_loads = 'Schedule Loads / Limit Adjustments';
messages.kuggar.prepaid_accounts.scheduled_load_amount = 'Limit';
messages.kuggar.vc_transactions.credited = 'Credits';
messages.kuggar.vc_transactions.debited = 'Debits';
messages.kuggar.vc_transactions.balance = 'Limit';
messages.kuggar.virtual_cards.card_limit = 'Card Limit';
messages.kuggar.virtual_cards.card_limit_create = 'Initial Card Limit';
messages.kuggar.virtual_cards.card_limit_update = 'New Card Limit';
messages.kuggar.shared.funding_account_name = 'Funding Account Name';

export default messages;