import {
    BooleanField, BooleanInput,
    Create,
    Datagrid, Edit, EditButton,
    List,
    Show, SimpleForm, Tab, TabbedShowLayout,
    TextField, TextInput, TopToolbar, FunctionField,
    useNotify,
    useRecordContext, useRedirect, useTranslate
} from "react-admin";
import * as React from "react";
import {CreateToolbar, UpdateToolbar} from "../shared";

export const DataTransformQueryList = () => {
    const translate = useTranslate();

    return (
        <List
            pagination={false}>
            <Datagrid rowClick='show'
                      bulkActionButtons={false}
                      sx={{ '& .RaDatagrid-rowCell': { verticalAlign: 'top' }}}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <FunctionField render={record => <code style={{whiteSpace:'pre-wrap',color:'#002b36',fontSize:'0.8rem'}}>{record.queryText}</code>} label={translate('kuggar.rules.query_text')} />
            </Datagrid>
        </List>
    );
}

export const DataTransformQueryShow = () => {
    const translate = useTranslate();
    
    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                <EditButton
                    label={translate('kuggar.rules.update_rule_query')}
                    record={data}/>
            </TopToolbar>
        );
    };
    
    return (
        <Show actions={<Actions />}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <FunctionField render={record => <code style={{whiteSpace:'pre-wrap',color:'#002b36',fontSize:'0.8rem'}}>{record.queryText}</code>} label={translate('kuggar.rules.query_text')} />
                    <BooleanField source="isActive"  label={translate('kuggar.shared.active')} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export const DataTransformQueryEdit = () => {
    const translate = useTranslate();

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <TextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true}/>
                <TextInput source="queryText" label={translate('kuggar.rules.query_text')} fullWidth={true} multiline/>
                <BooleanInput
                    source="isActive"
                    label={translate('kuggar.shared.active')} disabled/>
            </SimpleForm>
        </Edit>
    )
}

export const DataTransformQueryCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data) => {
        notify(`${translate('kuggar.rules.query_on_success_notify')}`);
        redirect(`/data_transform_queries/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };
    
    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <TextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true}/>
                <TextInput source="queryText" label={translate('kuggar.rules.query_text')} fullWidth={true} multiline/>
            </SimpleForm>
        </Create>
    )
}