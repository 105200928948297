import * as React from "react";
import {
	List,
	Show,
	Datagrid,
	SimpleShowLayout,
	TextField,
	FunctionField,
	ArrayField,
	ReferenceField,
	SingleFieldList,
	useTranslate,
	useRecordContext,
	TextInput
} from 'react-admin';
import ImageField from '../components/ImageField';
import CurrencyChipField from '../components/CurrencyChipField';
import {TextFieldWithCopyButton, TablePagination, FormatCurrencyValue, kuggarExporter} from '../shared';
import { Environment } from "../configProvider";
import CurrencySelect from "../components/CurrencySelect";
import OrganisationSelect from "../components/OrganisationSelect";

export const PrepaidAccountList = () => {
    const translate = useTranslate();
	
	const prepaidAccountFilter = [
		<TextInput label="Search" source="q" alwaysOn />,
		<CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />,
		<OrganisationSelect style={{ width: '300px' }} alwaysOn isFilter fullWidth/>,
	];
	
    return (
        <List exporter={kuggarExporter} filters={prepaidAccountFilter} pagination={<TablePagination />} perPage={10} sort={{ field: 'description', order: 'ASC' }}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source='issuerAccountId' label={translate('kuggar.shared.id')} />
				<ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
                    <TextField source="description" />
                </ReferenceField>
				<ReferenceField source="issuerCredentialId" label={translate('kuggar.shared.issuer_credential')} reference="issuer_credentials" link="show">
                    <TextField source="description" />
                </ReferenceField>
				<ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
				<ArrayField source="currencyCodes" label={translate('kuggar.shared.currency')}>
					<SingleFieldList linkType={false}>
						<CurrencyChipField />
					</SingleFieldList>
				</ArrayField>
                <FunctionField label={translate('kuggar.prepaid_accounts.available_balance')} render={record => record.currencyCodes
				    ? FormatCurrencyValue(record.currencyCodes[0], record.availableBalance)
				    : record.availableBalance.replace(/^[a-zA-Z]+\s?/, '$')}
			    />
                {/*<FunctionField label={translate('kuggar.prepaid_accounts.actual_balance')} render={record => record.currencyCodes
				    ? FormatCurrencyValue(record.currencyCodes[0], record.actualBalance)
				    : record.actualBalance}
				/>*/}
            </Datagrid>
        </List>
    );
};

export const PrepaidAccountShow = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.prepaid_accounts.prepaid_account')} - ID {record ? record.id : ''}</span>;	
	};

	return (
		<Show title={<Title />}>
			<SimpleShowLayout>
                <TextFieldWithCopyButton source="issuerAccountId" label={translate('kuggar.shared.kuggar_id', Environment)} />
                <ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField source="issuerCredentialId" label={translate('kuggar.shared.issuer_credential')} reference="issuer_credentials" link="show">
                    <TextField source="description" />
                </ReferenceField>
                <ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
                <ArrayField source="currencyCodes" label={translate('kuggar.shared.currency')}>
                    <SingleFieldList linkType={false}>
                        <CurrencyChipField />
                    </SingleFieldList>
                </ArrayField>
				<FunctionField label={translate('kuggar.prepaid_accounts.available_balance')} render={record => record.currencyCodes
				    ? FormatCurrencyValue(record.currencyCodes[0], record.availableBalance)
				    : record.availableBalance}
			    />
                {/*<FunctionField label={translate('kuggar.prepaid_accounts.actual_balance')} render={record => record.currencyCodes
				    ? FormatCurrencyValue(record.currencyCodes[0], record.actualBalance) 
				    : record.actualBalance}
			    />*/}
			</SimpleShowLayout>
		</Show>
	);
};