import * as React from "react";
import { Environment } from "../configProvider";
import { useState } from "react";
import TerminalResult from '../components/TerminalResult.jsx';
import TerminalLoading from '../components/TerminalLoading.jsx';

export const Terminal = () => {
    const [amount, setAmount] = useState(0);
    const [accountNumber, setAccountNumber] = useState(null);
    const [expiryDateMonth, setExpiryDateMonth] = useState(null);
    const [expiryDateYear, setExpiryDateYear] = useState(null);

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const handleSubmit = async (e) => {
        setLoading(true);
        setResponse(null);

        e.preventDefault();
        await fetch(Environment.API.VirtualTerminal, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    UserName: Environment.VirtualTerminalUsername,
                    Password: Environment.VirtualTerminalPassword,
                    AccountNumber: accountNumber,
                    AmountTransaction: amount,
                    CardExpirationDate: `${expiryDateMonth}${expiryDateYear}`,
                    CardAcceptorIdentificationCode: 'A28',
                    CurrencyCodeTransaction: 'USD',
                    ApprovalCodeLength: 4,
                    InstitutionIdentificationCode: '036136001',
                    SystemTraceAuditNumber: '123456'
                }
            )
        })
        .then(res => res.json())
        .then(data => {
            setResponse(data);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
        });
    };

    return (
        <div id="terminal-main">

            <h1>Virtual Terminal</h1>

            <div id="terminal-form">
                <form onSubmit={handleSubmit}>

                    <div class="terminal-form-section">
                        <div class="terminal-form-section-label">
                            Amount
                        </div>
                        <div class="terminal-form-section-input">
                            <input
                                value={amount}
                                onChange={ e => setAmount(e.target.value)}
                                type="number"
                            />
                        </div>
                    </div>

                    <div class="terminal-form-section">
                        <div class="terminal-form-section-label">
                            Card Number
                        </div>
                        <div class="terminal-form-section-input">
                            <input
                                value={accountNumber}
                                onChange={ e => setAccountNumber(e.target.value)}
                                type="text"
                            />
                        </div>
                    </div>

                    <div class="terminal-form-section">
                        <div class="terminal-form-section-label">
                            Expiry Date
                        </div>
                        <div class="terminal-form-section-input terminal-form-expiry-date">

                            <input
                                value={expiryDateMonth}
                                placeholder="MM"
                                maxLength="2"
                                onChange={e => setExpiryDateMonth(e.target.value)}
                                type="text"
                            />

                            <input
                                value={expiryDateYear}
                                placeholder="YY"
                                maxLength="2"
                                onChange={e => setExpiryDateYear(e.target.value)}
                                type="text"
                            />
                        </div>
                    </div>

                    <div class="terminal-form-section terminal-form-section-button">
                        <button class='terminal-button' type="submit">Pay Now</button>
                    </div>
                    
                </form>
            </div>

            <TerminalLoading show={loading} />
            <TerminalResult result={response} />

        </div>
    )
}