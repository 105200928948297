import {
	useRecordContext, TextField
} from 'react-admin';
import get from 'lodash/get';

const TextArrayField = (props) => {
	const { source } = props;
	const record = useRecordContext();
	const array = get(record, source);

	if (typeof array === 'undefined' || array === null || array.length === 0) {
		return <div />;
	}
	else if (props.type === 'list') {
		return array.map(item => <div><TextField record={{label:item}} source="label" /></div>);
	}
    else {
		return (
			<div>
				{array.map(item => <span>{item} </span>)}
			</div>
		);
	}
};

export default TextArrayField;