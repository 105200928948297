import * as React from "react";
import { 
    List, Datagrid, Show, SimpleShowLayout, TextField, EmailField, 
    Create, Edit, SimpleForm, 
    TopToolbar, EditButton, DeleteButton,
    required, email, useNotify, useRedirect, useTranslate, useRecordContext
} from 'react-admin';
import {
    TableFilter,
    TablePagination,
    UpdateToolbar,
    HasCreatePermission,
    HasUpdatePermission,
    HasDeletePermission,
    TextFieldWithCopyButton, KuggarTextInput, kuggarExporter
} from '../shared';

export const OrganisationList = () => {
    const translate = useTranslate();
    return (
        <List exporter={kuggarExporter} filters={<TableFilter />} pagination={<TablePagination />} perPage={20} 
              sort={{ field: 'name', order: 'ASC' }} hasCreate={HasCreatePermission('Platform', '/organisation')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="name" label={translate('kuggar.shared.name')} />
                <TextField source="referenceCode" label={translate('kuggar.shared.reference_code')} />
                <EmailField source="emailAddress" label={translate('kuggar.shared.email_address')} />
            </Datagrid>
        </List>
    );
};

export const OrganisationShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const Title = () => {
        const record = useRecordContext();
        return <span>Organisation - ID {record ? record.id : ''}</span>;
    };

    const onSuccess = () => {
		notify(`${translate('kuggar.organisation.on_delete_success_notify')}`);
		redirect(`/organisation`);
    };
    
    const onError = (data) => {
        notify(`${translate('kuggar.organisation.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
    };

    const Actions = () => {
        const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('Platform', '/organisation/') ?
					<EditButton
						label={translate('kuggar.organisation.update_organisation')}
						record={data} />
				: null}
				{HasDeletePermission('Platform', '/organisation/') ?
					<DeleteButton
						label={translate('kuggar.organisation.delete_organisation')}
						record={data}
                        mutationMode="optimistic"
                        mutationOptions={{ onSuccess, onError }}
						confirmTitle="Delete Organisation"
						confirmContent={`Are you sure you want to delete Organisation with Kuggar ID ${data ? data.id : ''}? This action cannot be undone.`} />
				: null}
			</TopToolbar>
		);
	};

    return (
        <Show title={<Title />} actions={<Actions />}>
            <SimpleShowLayout>
                <TextFieldWithCopyButton source='id' label={translate('kuggar.shared.id')} />
                <TextField source="name" label={translate('kuggar.shared.name')} />
                <TextField source="referenceCode" label={translate('kuggar.shared.reference_code')} />
                <EmailField source="emailAddress" label={translate('kuggar.shared.email_address')} />
            </SimpleShowLayout>
        </Show>
    );
};

export const OrganisationEdit = () => {
    const translate = useTranslate();
    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar />}>
                <KuggarTextInput source="id" label={translate('kuggar.shared.id')} fullWidth={true} disabled />
                <KuggarTextInput source="name" label={translate('kuggar.shared.name')} fullWidth={true} validate={[required()]} />
                <KuggarTextInput source="referenceCode" label={translate('kuggar.shared.reference_code')} fullWidth={true} validate={[required()]} helperText={translate('kuggar.organisation.reference_code_validation')} format={v => v == null ? null : v.toUpperCase()} />
                <KuggarTextInput source="emailAddress" label={translate('kuggar.shared.email_address')} fullWidth={true} validate={[required(), email('kuggar.shared.email_address_validation')]} />
            </SimpleForm>
        </Edit>
    );
};

export const OrganisationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const onSuccess = () => {
		notify(`${translate('kuggar.organisation.on_success_notify')}`);
		redirect(`/organisation`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }}>
            <SimpleForm>
                <KuggarTextInput source="organisationName" label={translate('kuggar.shared.name')} fullWidth={true} validate={[required()]} />
                <KuggarTextInput source="organisationRef" label={translate('kuggar.shared.reference_code')} fullWidth={true} validate={[required()]} helperText={translate('kuggar.organisation.reference_code_validation')} format={v => v == null ? null : v.toUpperCase()} />
                <KuggarTextInput source="emailAddress" label={translate('kuggar.shared.email_address')} fullWidth={true} validate={[required(), email('kuggar.shared.email_address_validation')]} />
            </SimpleForm>
        </Create>
    );
};