import {Error, Loading, required, SelectInput, useGetList, useRecordContext, useTranslate} from "react-admin";
import * as React from "react";

const OrganisationSelect = props => {
    const translate = useTranslate();
    const { source, style, isFilter, alwaysOn, fullWidth} = props;

    const {data, isLoading, error} = useGetList(
        'organisation',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.name} ({record.referenceCode})</span>
    };

    return <SelectInput
        source={source ?? 'organisationId'}
        label={translate('kuggar.organisation.organisation')}
        choices={data}
        optionValue='id'
        style={style}
        alwaysOn={alwaysOn}
        fullWidth={fullWidth}
        validate={isFilter === true ? null : [required()]}
        optionText={<OptionRenderer/>}
    />;
};

export default OrganisationSelect;