import {
    ArrayField, ArrayInput, BooleanField, BooleanInput, ChipField, Create,
    Datagrid, Edit,
    EditButton, email, Error, FunctionField,
    List, Loading, NumberInput, PasswordInput, ReferenceField, required, SelectArrayInput, SelectInput,
    Show, SimpleForm, SimpleFormIterator, SingleFieldList, Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    HasCreatePermission,
    HasUpdatePermission, kuggarExporter, KuggarTextInput,
    TableFilter,
    TablePagination,
    UpdateToolbar
} from "../shared";
import {Environment} from "../configProvider";
import * as React from "react";
import PermissionsCreateUpdate from "../components/PermissionsCreateUpdate";
import CurrencyChipField from "../components/CurrencyChipField";
import Chip from "@mui/material/Chip";
import SimpleChipField from "../components/SimpleChipField";

export const CardIssuerTemplateList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            filters={<TableFilter/>}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/card_issuer_templates')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <ReferenceField source="binRangeId" reference="ip_bin_range" label={translate('kuggar.bin_ranges.bin_range')} >
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField source="accountId" reference="ip_account" label={translate('kuggar.accounts.account')} >
                    <TextField source="description" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const CardIssuerTemplateShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/card_issuer_templates') ?
                    <EditButton
                        label={translate('kuggar.card_issuer_template.update_card_issuer_template')}
                        record={data}/>
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <ReferenceField source="accountId" reference="ip_account" label={translate('kuggar.accounts.account')} >
                        <TextField source="description" />
                    </ReferenceField>
                    <ReferenceField source="binRangeId" reference="ip_bin_range" label={translate('kuggar.bin_ranges.bin_range')} >
                        <TextField source="description" />
                    </ReferenceField>
                    <ReferenceField source="scheme.id" reference="ip_scheme" label={translate('kuggar.ip_schemes.scheme')} >
                        <TextField source="description" />
                    </ReferenceField>
                    <TextField source="maxAuthorisations" label={translate('kuggar.card_issuer_template.max_authorisations')}/>
                    <TextField source="maxLimit" label={translate('kuggar.card_issuer_template.max_limit')}/>
                    <TextField source="maxActiveToDate.daysFromNow" label={translate('kuggar.card_issuer_template.expiry_days')}/>
                    <TextField source="maxActiveToDate.monthsFromNow" label={translate('kuggar.card_issuer_template.expiry_months')}/>
                    <TextField source="tolerance.percent" label={translate('kuggar.card_issuer_template.tolerance_percent')}/>
                    <TextField source="tolerance.fixedAmount" label={translate('kuggar.card_issuer_template.tolerance_fixed_amount')}/>
                    <TextField source="expiryDateProjectionMonths" label={translate('kuggar.card_issuer_template.expiry_date_projection_months')}/>
                    <ArrayField source="currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                        <SingleFieldList linkType={false}>
                            <SimpleChipField />
                        </SingleFieldList>
                    </ArrayField>
                    <ArrayField source="currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                        <SingleFieldList linkType={false}>
                            <SimpleChipField />
                        </SingleFieldList>
                    </ArrayField>
                    <ArrayField source="metadataConfig" label={translate('kuggar.metadata_configs.metadata_config')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="label" label={translate('kuggar.metadata_configs.label')} />
                            <TextField source="metadataType" label={translate('kuggar.metadata_configs.metadata_type')} />
                            <TextField source="defaultValue" label={translate('kuggar.metadata_configs.default_value')} />
                        </Datagrid>
                    </ArrayField>
                    <TextField source="webhookConfig.webhookUrl" label={translate('kuggar.card_issuer_template.webhook_config_url')}/>
                    <TextField source="webhookConfig.webhookUsername" label={translate('kuggar.card_issuer_template.webhook_config_username')}/>
                    <BooleanField source="hasCvv"  label={translate('kuggar.card_issuer_template.has_cvv')} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const CardIssuerTemplateEdit = () => {
    const translate = useTranslate();

    const {data: binRangeData, isLoading: binRangeLoading, error: binRangeError} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: accountData, isLoading: accountLoading, error: accountError} = useGetList(
        'ip_account',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (binRangeLoading || accountLoading) return <Loading/>;
    if (binRangeError || accountError) return <Error/>;
    if (!binRangeData || !accountData) return null;

    const inputStyle = {width: '512px'};

    const validateTemplateParameters = (value) => {
        if (value !== undefined && value !== null && value <= 0) {
            return 'Value must be greater than 0';
        }
        return undefined;
    };

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <KuggarTextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <SelectInput
                    source="binRangeId"
                    label={translate('kuggar.bin_ranges.bin_range')}
                    validate={[required()]}
                    choices={binRangeData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <SelectInput
                    source="accountId"
                    label={translate('kuggar.accounts.account')}
                    validate={[required()]}
                    choices={accountData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <NumberInput
                    source="maxAuthorisations"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.max_authorisations')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxLimit"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.max_limit')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxActiveToDate.daysFromNow"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_days')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxActiveToDate.monthsFromNow"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_months')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="tolerance.percent"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.tolerance_percent')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="tolerance.fixedAmount"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.tolerance_fixed_amount')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="expiryDateProjectionMonths"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_date_projection_months')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <ArrayInput source="currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="metadataConfig" label={translate('kuggar.metadata_configs.metadata_config')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput
                            source="label"
                            label={translate('kuggar.metadata_configs.label')}
                            fullWidth={true}
                            validate={[required()]}/>
                        <SelectInput source="metadataType" bel={translate('kuggar.metadata_configs.metadata_type')} validate={[required()]} choices={[
                            { id: 'String', name: 'String' },
                            { id: 'Number', name: 'Number' },
                            { id: 'Boolean', name: 'Boolean' }]}
                        />
                        <KuggarTextInput
                            source="defaultValue"
                            label={translate('kuggar.metadata_configs.default_value')}
                            fullWidth={true}
                            validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <KuggarTextInput
                    source="webhookConfig.url"
                    label={translate('kuggar.card_issuer_template.webhook_config_url')}
                    fullWidth={true}/>
                <KuggarTextInput
                    source="webhookConfig.username"
                    label={translate('kuggar.card_issuer_template.webhook_config_username')}
                    fullWidth={true}/>
                <PasswordInput
                    source="webhookConfig.password"
                    label={translate('kuggar.card_issuer_template.webhook_config_password')}
                    fullWidth={true}/>
                <BooleanInput
                    source="hasCvv"
                    label={translate('kuggar.card_issuer_template.has_cvv')}/>
            </SimpleForm>
        </Edit>
    );
};

export const CardIssuerTemplateCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const {data: binRangeData, isLoading: binRangeLoading, error: binRangeError} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: accountData, isLoading: accountLoading, error: accountError} = useGetList(
        'ip_account',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (binRangeLoading || accountLoading) return <Loading/>;
    if (binRangeError || accountError) return <Error/>;
    if (!binRangeData || !accountData) return null;

    const inputStyle = {width: '512px'};

    const onSuccess = (data) => {
        notify(`${translate('kuggar.card_issuer_template.on_success_notify')}`);
        redirect(`/ip_card_issuer_template/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    const validateTemplateParameters = (value) => {
        if (value !== undefined && value !== null && value <= 0) {
            return 'Value must be greater than 0';
        }
        return undefined;
    };

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>} >
                <KuggarTextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <SelectInput
                    source="binRangeId"
                    label={translate('kuggar.bin_ranges.bin_range')}
                    validate={[required()]}
                    choices={binRangeData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <SelectInput
                    source="accountId"
                    label={translate('kuggar.accounts.account')}
                    validate={[required()]}
                    choices={accountData}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <NumberInput
                    source="maxAuthorisations"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.max_authorisations')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxLimit"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.max_limit')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxActiveToDate.daysFromNow"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_days')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="maxActiveToDate.monthsFromNow"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_months')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="tolerance.percent"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.tolerance_percent')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="tolerance.fixedAmount"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.tolerance_fixed_amount')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <NumberInput
                    source="expiryDateProjectionMonths"
                    defaultValue={0}
                    label={translate('kuggar.card_issuer_template.expiry_date_projection_months')}
                    style={inputStyle}
                    validate={[validateTemplateParameters]} />
                <ArrayInput source="currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="metadataConfig" label={translate('kuggar.metadata_configs.metadata_config')}>
                    <SimpleFormIterator inline>
                        <KuggarTextInput
                            source="label"
                            label={translate('kuggar.metadata_configs.label')}
                            fullWidth={true}
                            validate={[required()]}/>
                        <SelectInput source="metadataType" bel={translate('kuggar.metadata_configs.metadata_type')} validate={[required()]} choices={[
                            { id: 'String', name: 'String' },
                            { id: 'Number', name: 'Number' },
                            { id: 'Boolean', name: 'Boolean' }]}
                        />
                        <KuggarTextInput
                            source="defaultValue"
                            label={translate('kuggar.metadata_configs.default_value')}
                            fullWidth={true}
                            validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <KuggarTextInput
                    source="webhookConfig.url"
                    label={translate('kuggar.card_issuer_template.webhook_config_url')}
                    fullWidth={true}/>
                <KuggarTextInput
                    source="webhookConfig.username"
                    label={translate('kuggar.card_issuer_template.webhook_config_username')}
                    fullWidth={true}/>
                <PasswordInput
                    source="webhookConfig.password"
                    label={translate('kuggar.card_issuer_template.webhook_config_password')}
                    fullWidth={true}/>
                <BooleanInput
                    source="hasCvv"
                    label={translate('kuggar.card_issuer_template.has_cvv')}/>
            </SimpleForm>
        </Create>
    );
};