import {
    Loading, Error,
    useGetList, SelectInput, required, useTranslate, ArrayInput, SimpleFormIterator
} from 'react-admin';
import * as React from "react";

const PermissionsCreateUpdate = props => {
    const translate = useTranslate();

    const {source, style} = props;

    const {data, isLoading, error} = useGetList(
        'ip_access_profile',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;
    
    const inputStyle = {width: '512px'};


    if (data) {
        return (
            <ArrayInput source="permissions" label='Permissions'>
                <SimpleFormIterator inline>
                    <SelectInput
                        source="accessProfileId"
                        label={translate('kuggar.shared.credential')}
                        validate={[required()]}
                        choices={data}
                        optionText="description"
                        optionValue="id"
                        style={inputStyle}/>
                    <SelectInput source="scope"
                                 label={translate('kuggar.shared.type')}
                                 validate={[required()]}
                                 style={inputStyle}
                                 choices={[
                                     {id: 'Admin', name: 'Admin'},
                                     {id: 'Writer', name: 'Writer'},
                                     {id: 'Reader', name: 'Reader'},
                                 ]}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    }

    return null;
};

export default PermissionsCreateUpdate;