import { Environment } from "./configProvider";

const refreshAuth = () => {    
    const accessTokenExpiry = Date.parse(localStorage.getItem('auth_expiry'));
    const refreshToken = localStorage.getItem('refresh');
    const refreshTokenExpiry = Date.parse(localStorage.getItem('refresh_expiry'));
    const clientId = localStorage.getItem('clientId');

    let now = new Date();

    if(refreshTokenExpiry < now)
        return Promise.reject("Refresh token expired.");
    
    if (accessTokenExpiry < now) {
        return fetch(`${Environment.API.Auth}/auth/refresh-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'                
            },
            body: JSON.stringify({ clientId: clientId,  refreshToken: refreshToken })
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            localStorage.setItem('auth', data.accessToken);
            localStorage.setItem('auth_expiry', data.accessTokenExpiryUtc);
            localStorage.setItem('refresh', data.refreshToken);
            localStorage.setItem('refresh_expiry', data.refreshTokenExpiryUtc);
            
            return Promise.resolve();
        })
        .catch(error => {
            console.error(error)
            return Promise.reject(error);
        })
    }
    
    return Promise.resolve();
}

export default refreshAuth