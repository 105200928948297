import * as React from "react";
import CreditCard from '../components/CreditCard';
import CreditCardMint from '../components/CreditCardMint';
import CreditCardUATP from '../components/CreditCardUATP';

class CardView extends React.Component {			
	render() {
		const record = this.props.record !== 'undefined' ? this.props.record : '';
		
		if (record !== '' && typeof record.createResponse !== 'undefined' && record.createResponse !== null) {
			const createResponse = JSON.parse(record.createResponse);

			if (record.provider === 'eNett') {
				return <CreditCard
					number={createResponse.VirtualAccountNumber}
					name={createResponse.CardHolderName.toUpperCase()}
					expiry={createResponse.ExpiryDate}
					cvv={createResponse.CardSecurityCode}
					type="mastercard"
				/>;
			}
			else if (record.provider.startsWith('AirPlus')) {
				return <CreditCard
					number={createResponse.CardCPN.PAN}
					name={createResponse.AVSData.CardholderName.toUpperCase()}
					expiry={`${createResponse.CardCPN.Expiry.substr(2, 2)}/${createResponse.CardCPN.Expiry.substr(0, 2)}`}
					cvv={createResponse.CardCPN.AVV}
					type="mastercard"
				/>;
			}
			else if (record.provider === 'Mastercard') {
				return <CreditCard
					number={createResponse.GetPurchaseRequestDetail.VcnInformation.Pan}
					name=""
					expiry={`${createResponse.GetPurchaseRequestDetail.VcnInformation.Expiry.substr(2, 2)}/${createResponse.GetPurchaseRequestDetail.VcnInformation.Expiry.substr(0, 2)}`}
					cvv={createResponse.GetPurchaseRequestDetail.VcnInformation.Avv}
					type="mastercard"
				/>;
			}
			else if (record.provider === 'Mint') {
				return <CreditCardMint
					number={createResponse.CardDetails.CardNumber}
					name={createResponse.CardDetails.NameOnCard.toUpperCase()}
					expiry={`${createResponse.CardDetails.ExpiryDateMonth}/${createResponse.CardDetails.ExpiryDateYear.substr(2, 2)}`}
					cvv={createResponse.CardDetails.Cvv}
					type={createResponse.CardDetails.CardBrand.toLowerCase()}
				/>;
			}
			else if (record.provider === 'WEX') {
				return <CreditCard
					number={createResponse.Card.CardNumber}
					name=""
					expiry={`${createResponse.Card.ExpirationDate.substr(5, 2)}/${createResponse.Card.ExpirationDate.substr(2, 2)}`}
					cvv={createResponse.Card.CVC2}
					type="mastercard"
				/>;
			}
			else if (record.provider === 'Kuggar') {
				return <CreditCardUATP
					number={createResponse.CardNumber}
					name={record.organisation && record.organisation.name ? record.organisation.name.toUpperCase() : ""}
					expiry={`${createResponse.ExpiryDate.substr(5, 2)}/${createResponse.ExpiryDate.substr(2, 2)}`}
					type="uatp" /* TODO get from create response */
				/>;
			}
			else {
				return '';
			}
		}
		else if (record.currentCardProperties && record.currentCardProperties.cardNumber) {
			if (record.provider === 'Mint') {
				return <CreditCardMint
					number={record.currentCardProperties.cardNumber}
					name=""
					expiry=""
					cvv={record.currentCardProperties.cvv}
					type={record.currentCardProperties.cardNumber.startsWith('4') ? 'visa' : 'mastercard'}
				/>;
			}
			else if (record.provider === 'Kuggar') {
				return <CreditCardUATP
					number={record.currentCardProperties.cardNumber}
					name={record.organisation && record.organisation.name ? record.organisation.name.toUpperCase() : ""}
					expiry=""
					type="uatp" /* TODO get from current card properties */
				/>;
			}
			else {
				return <CreditCard
					number={record.currentCardProperties.cardNumber}
					name=""
					expiry=""
					cvv={record.currentCardProperties.cvv}
					type={record.currentCardProperties.cardNumber.startsWith('4') ? 'visa' : 'mastercard'}
				/>;
			}
		}
		else {
			return '';
        }
	}			
}

export default CardView;