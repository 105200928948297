import {
    Create,
    Datagrid, Edit, EditButton, Error,
    FunctionField,
    List, Loading,
    ReferenceField, required, SelectInput, Show, SimpleForm, Tab, TabbedShowLayout,
    TextField, 
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    HasCreatePermission,
    HasUpdatePermission, kuggarExporter, KuggarTextInput,
    TableFilter,
    TablePagination,
    TextFieldWithCopyButton, UpdateToolbar
} from "../shared";
import {Environment} from "../configProvider";
import * as React from "react";

export const IpAccessProfilesList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            filters={<TableFilter/>}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/access_profiles')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <TextField source="role" label={translate('kuggar.shared.type')}/>
            </Datagrid>
        </List>
    );
};

export const IpAccessProfilesShow = () => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();
        return <span>Issuer Access Profile - ID {record ? record.id : ''}</span>;
    };

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/access_profiles') ?
                    <EditButton
                        label={translate('kuggar.ip_access_profiles.update_access_profile')}
                        record={data}/>
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show title={<Title/>} actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.id')}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <TextField source="role" label={translate('kuggar.shared.type')}/>
                    <ReferenceField source="credentialId"
                                    label={translate('kuggar.user.authentication_method')} reference="credential"
                                    link={false}>
                        <TextField source="authenticationMethod"/>
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const IpAccessProfilesEdit = () => {
    const translate = useTranslate();

    const {data: credData, isLoading: credLoading, error: credGetError} = useGetList(
        'credential',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {}
        }
    );

    if (credLoading) return <Loading/>;
    if (credGetError) return <Error/>;
    if (!credData) return null;

    const inputStyle = {width: '512px'};

    const Title = () => {
        const record = useRecordContext();
        return <span>{translate('kuggar.ip_access_profiles.update_access_profile')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
    };

    return (
        <Edit title={<Title/>} mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <SelectInput
                    source="credentialId"
                    label={translate('kuggar.shared.credential')}
                    validate={[required()]}
                    choices={credData}
                    optionText="clientId"
                    optionValue="id"
                    style={inputStyle}/>
                <KuggarTextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <SelectInput source="role"
                             label={translate('kuggar.shared.type')}
                             validate={[required()]}
                             style={inputStyle}
                             choices={[
                                 {id: 'GlobalAdmin', name: 'Global Admin'},
                                 {id: 'Admin', name: 'Admin'},
                                 {id: 'Writer', name: 'Writer'},
                                 {id: 'Reader', name: 'Reader'},
                             ]}
                />
            </SimpleForm>
        </Edit>
    );
};

export const IpAccessProfilesCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const {data: credData, isLoading: credLoading, error: credGetError} = useGetList(
        'credential',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {}
        }
    );

    if (credLoading) return <Loading/>;
    if (credGetError) return <Error/>;
    if (!credData) return null;

    const inputStyle = {width: '512px'};

    const onSuccess = (data) => {
        notify(`${translate('kuggar.ip_access_profiles.on_success_notify')}`);
        redirect(`/ip_access_profile/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    return (
        <Create title={translate('kuggar.ip_access_profiles.title_create')} mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <SelectInput
                    source="credentialId"
                    label={translate('kuggar.shared.credential')}
                    validate={[required()]}
                    choices={credData}
                    optionText="clientId"
                    optionValue="id"
                    style={inputStyle}/>
                <KuggarTextInput
                    source="description"
                    label={translate('kuggar.shared.description')}
                    fullWidth={true}
                    validate={[required()]}/>
                <SelectInput source="role"
                             label={translate('kuggar.shared.type')}
                             validate={[required()]}
                             style={inputStyle}
                             choices={[
                                 {id: 'GlobalAdmin', name: 'Global Admin'},
                                 {id: 'Admin', name: 'Admin'},
                                 {id: 'Writer', name: 'Writer'},
                                 {id: 'Reader', name: 'Reader'},
                             ]}
                />
            </SimpleForm>
        </Create>
    );
};