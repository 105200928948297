import {
    useRecordContext
} from 'react-admin';
import UserDefinedField from './UserDefinedField';

const UserDefinedFieldsUpdate = props => {
    const { source, style } = props;
    const record = useRecordContext();

    let result = [];
    //let udfs = record[source]; // TODO: Access the record dynamically.
    let udfs = record.currentCardProperties.userDefinedFields;
    for (var i = 0; i < udfs.length; i++) {
        let udf = <div key={i}><UserDefinedField
            source={`${source}[${i}].value`}
            label={udfs[i].label}
            style={style}
            udfType="Text"
        /></div>;
        result.push(udf);
    }

    return result.map((udfResult) => udfResult);
};

export default UserDefinedFieldsUpdate;