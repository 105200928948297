import * as React from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { useSetLocale, useRefresh } from 'react-admin';

const useStyles = makeStyles({
    button: { color: 'var(--color) !important' }
});

const LocaleSwitcher = () => {
    const classes = useStyles();
    const setLocale = useSetLocale();
    const refresh = useRefresh();
    const [localeName, setLocaleName] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState('');
    const open = Boolean(anchorEl);

    const storedLocaleName = localStorage.getItem('locale-name');

    const handleChange = event => {
        const { locale, localeName } = event.currentTarget.dataset;
        localStorage.setItem('locale', locale);
        localStorage.setItem('locale-name', localeName);
        setLocale(locale);
        setLocaleName(localeName);
        setAnchorEl(null);
        setTimeout(() => refresh(), 10);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button className={classes.button} onClick={handleMenu}><TranslateIcon />&nbsp;{localeName === '' ? (storedLocaleName ? storedLocaleName : 'English') : localeName}<ExpandMoreIcon /></Button>
            <Menu
                id="locale"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem data-locale="en" data-locale-name="English" onClick={handleChange}>English</MenuItem>
                {/*<MenuItem data-locale="ru" data-locale-name="Russian" onClick={handleChange}>Russian</MenuItem>*/}
            </Menu>
        </div>
    );
};

export default LocaleSwitcher;