import React from 'react';

function TerminalResult(props) {
    if (props.result != null) {
        return (
            <div id="terminal-response">
                <p>{props.result.ResultDescription}</p>
            </div>
        );
    }
    else {
        return null;
    }
};

export default TerminalResult;