import {
    ArrayField, BooleanField, BooleanInput, Create,
    Datagrid, Edit,
    EditButton, Error,
    List, Loading, PasswordInput, ReferenceField, required, SelectInput,
    Show, SimpleForm, Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar, useGetList, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    HasCreatePermission,
    HasUpdatePermission, kuggarExporter, KuggarTextInput,
    TableFilter,
    TablePagination,
    UpdateToolbar
} from "../shared";
import {Environment} from "../configProvider";
import * as React from "react";
import PermissionsCreateUpdate from "../components/PermissionsCreateUpdate";

export const BinRangeList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            filters={<TableFilter/>}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/bin_ranges')}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)}/>
                <TextField source="description" label={translate('kuggar.shared.description')}/>
                <TextField source="start" label={translate('kuggar.bin_ranges.start')}/>
                <TextField source="end" label={translate('kuggar.bin_ranges.end')}/>
                <TextField source="binLength" label={translate('kuggar.bin_ranges.bin_length')}/>
                <ReferenceField source="schemeId" reference="ip_scheme" label={translate('kuggar.ip_schemes.scheme')} >
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField source="issuerId" reference="ip_issuer_management" label={translate('kuggar.ip_issuer_management.issuer')} >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const BinRangeShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/bin_ranges') ?
                    <EditButton
                        label={translate('kuggar.bin_ranges.update_bin_range')}
                        record={data}/>
                    : null}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)}/>
                    <TextField source="description" label={translate('kuggar.shared.description')}/>
                    <TextField source="start" label={translate('kuggar.bin_ranges.start')}/>
                    <TextField source="end" label={translate('kuggar.bin_ranges.end')}/>
                    <TextField source="binLength" label={translate('kuggar.bin_ranges.bin_length')}/>
                    <TextField source="schemeId" label={translate('kuggar.ip_schemes.scheme_id')}/>
                    <ReferenceField source="schemeId" reference="ip_scheme" label={translate('kuggar.ip_schemes.scheme')} >
                        <TextField source="description" />
                    </ReferenceField>
                    <TextField source="issuerId" label={translate('kuggar.ip_issuer_management.issuer_id')}/>
                    <ReferenceField source="issuerId" reference="ip_issuer_management" label={translate('kuggar.ip_issuer_management.issuer')} >
                        <TextField source="name" />
                    </ReferenceField>
                    <ArrayField source="permissions" label={translate('kuggar.shared.permissions')}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="accessProfileId" label={translate('kuggar.shared.access_profile_id')} />
                            <TextField source="scope" label={translate('kuggar.role.scope')} />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const BinRangeEdit = () => {
    const translate = useTranslate();

    const {data: schemeData, isLoading: isSchemeLoading, error: schemeError} = useGetList(
        'ip_scheme',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: issuerData, isLoading: isIssuerLoading, error: issuerError} = useGetList(
        'ip_issuer_management',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: binRangeData, isLoading: isBinRangeLoading, error: binRangeError} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isSchemeLoading || isIssuerLoading || isBinRangeLoading) return <Loading/>;
    if (schemeError || issuerError || binRangeError) return <Error/>;
    if (!schemeData || !issuerData || !binRangeData) return null;

    const inputStyle = {width: '512px'};

    const filterSchemesWithBinRanges = (schemes, binRanges) => {
        // Extract all scheme IDs that are used in the bin ranges
        const usedSchemeIds = new Set(binRanges.map(br => br.schemeId));
        // Filter schemes that are not used in any bin range
        const filteredSchemes = schemes.filter(scheme => !usedSchemeIds.has(scheme.id));
        return filteredSchemes;
    };

    const validateEndGreaterThanStart = (value, allValues) => {
        if (parseInt(value, 10) <= parseInt(allValues.start, 10)) {
            return translate('kuggar.bin_ranges.end_greater_than_start_validation');
        }
        return undefined;
    };

    const validateBinLength = (value) => {
        if (parseInt(value, 10) <= 0) {
            return translate('kuggar.bin_ranges.bin_length_greater_than_zero_validation');
        }
        return undefined;
    };
    
    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <KuggarTextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true} />
                <KuggarTextInput source="start" validate={[required()]} label={translate('kuggar.bin_ranges.start')}/>
                <KuggarTextInput source="end" validate={[required(), validateEndGreaterThanStart]} label={translate('kuggar.bin_ranges.end')}/>
                <KuggarTextInput source="binLength" validate={[required(), validateBinLength]} label={translate('kuggar.bin_ranges.bin_length')}/>
                <SelectInput
                    source="schemeId"
                    label={translate('kuggar.ip_schemes.scheme')}
                    validate={[required()]}
                    choices={filterSchemesWithBinRanges(schemeData, binRangeData)}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <SelectInput
                    source="issuerId"
                    label={translate('kuggar.ip_issuer_management.issuer')}
                    validate={[required()]}
                    choices={issuerData}
                    optionText="name"
                    optionValue="id"
                    style={inputStyle}/>
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Edit>
    );
};

export const BinRangeCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`${translate('kuggar.bin_ranges.on_success_notify')}`);
        redirect(`/ip_bin_range/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    const {data: schemeData, isLoading: isSchemeLoading, error: schemeError} = useGetList(
        'ip_scheme',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: issuerData, isLoading: isIssuerLoading, error: issuerError} = useGetList(
        'ip_issuer_management',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: binRangeData, isLoading: isBinRangeLoading, error: binRangeError} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isSchemeLoading || isIssuerLoading || isBinRangeLoading) return <Loading/>;
    if (schemeError || issuerError || binRangeError) return <Error/>;
    if (!schemeData || !issuerData || !binRangeData) return null;

    const inputStyle = {width: '512px'};

    const filterSchemesWithBinRanges = (schemes, binRanges) => {
        // Extract all scheme IDs that are used in the bin ranges
        const usedSchemeIds = new Set(binRanges.map(br => br.schemeId));
        // Filter schemes that are not used in any bin range
        const filteredSchemes = schemes.filter(scheme => !usedSchemeIds.has(scheme.id));
        return filteredSchemes;
    };

    const validateEndGreaterThanStart = (value, allValues) => {
        if (parseInt(value, 10) <= parseInt(allValues.start, 10)) {
            return translate('kuggar.bin_ranges.end_greater_than_start_validation');
        }
        return undefined;
    };

    const validateBinLength = (value) => {
        if (parseInt(value, 10) <= 0) {
            return translate('kuggar.bin_ranges.bin_length_greater_than_zero_validation');
        }
        return undefined;
    };

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                <KuggarTextInput source="description" label={translate('kuggar.shared.description')} fullWidth={true} />
                <KuggarTextInput source="start" validate={[required()]} label={translate('kuggar.bin_ranges.start')}/>
                <KuggarTextInput source="end" validate={[required(), validateEndGreaterThanStart]} label={translate('kuggar.bin_ranges.end')}/>
                <KuggarTextInput source="binLength" validate={[required(), validateBinLength]} label={translate('kuggar.bin_ranges.bin_length')}/>
                <SelectInput
                    source="schemeId"
                    label={translate('kuggar.ip_schemes.scheme')}
                    validate={[required()]}
                    choices={filterSchemesWithBinRanges(schemeData, binRangeData)}
                    optionText="description"
                    optionValue="id"
                    style={inputStyle}/>
                <SelectInput
                    source="issuerId"
                    label={translate('kuggar.ip_issuer_management.issuer')}
                    validate={[required()]}
                    choices={issuerData}
                    optionText="name"
                    optionValue="id"
                    style={inputStyle}/>
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Create>
    );
};