import configData from "./config.json";

const devDomains = [
    'agreeable-ocean-023c91300.3.azurestaticapps.net',
    'kuggar-dev-au-web-cp.azurewebsites.net',
    'kuggar-dev-fr-web-cp.azurewebsites.net',
    'portal.dev.au.kuggar.io',
    'portal.dev.fr.kuggar.io'
];

const devMintDomains = [
    'portal.dev.mint.kuggar.io'
];

const devUatpDomains = [
    'portal.dev.vc.uatp.com'
];

const testDomains = [
    'kuggar-test-web-cp.azurefd.net',
    'portal.test.kuggar.io'
];

const stagingDomains = [
    'black-forest-0ea77aa03.1.azurestaticapps.net',
    'portal.staging.kuggar.io'
];

const testAUAzureDomains = [
    'kuggar-test-au-web-cp.azurewebsites.net',
    'portal.test.au.kuggar.io'
];

const testAUAWSDomains = [
    'dg08jklimtv7d.cloudfront.net',
    'portal.aws.test.au.kuggar.io'
];

const testAEAzureDomains = [
    'kuggar-test-ae-web-cp.azurewebsites.net',
    'portal.test.ae.kuggar.io'
];

const testMintDomains = [
    'virtualcard-staging.mintpayments.com'
];

const testUatpDomains = [
    'portal.staging.vc.uatp.com'
];

const prodDomains = [
    'ambitious-bush-0f1487400.1.azurestaticapps.net',
    'portal.kuggar.io'
];

const prodMintDomains = [
    'virtualcard.mintpayments.com'
];

const prodUatpDomains = [
    'portal.vc.uatp.com'
];

const domain = window.location.host;

let env = '';
if (prodDomains.includes(domain)) {
    env = 'Production';
} else if (prodMintDomains.includes(domain)) {
    env = 'Production-Mint';
} else if (prodUatpDomains.includes(domain)) {
    env = 'Production-UATP';
} else if (testAUAzureDomains.includes(domain)) {
    env = 'Test-AU-Azure';
} else if (testAUAWSDomains.includes(domain)) {
    env = 'Test-AU-AWS';
} else if (testAEAzureDomains.includes(domain)) {
    env = 'Test-AE-Azure';
} else if (stagingDomains.includes(domain)) {
    env = 'Staging';
} else if (testDomains.includes(domain)) {
    env = 'Test';
} else if (testMintDomains.includes(domain)) {
    env = 'Test-Mint';
} else if (testUatpDomains.includes(domain)) {
    env = 'Test-UATP';
} else if (devDomains.includes(domain)) {
    env = 'Dev';
} else if (devMintDomains.includes(domain)) {
    env = 'Dev-Mint';
} else if (devUatpDomains.includes(domain)) {
    env = 'Dev-UATP';
} else {
    env = 'Local';
}

export const Version = configData['AppVersion'];
export const Environment = configData["Environments"].find(e => e.Name === env);