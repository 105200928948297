import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/englishMessages';
import mintMessages from './i18n/mintMessages';
import russianMessages from './i18n/russianMessages';
import get from 'lodash/get';
import { Environment } from "./configProvider";

const messages = {
    en: englishMessages,
    ru: russianMessages,
    mint: mintMessages
};

let defaultLocale = 'en';
const storedLocale = localStorage.getItem('locale');
if (storedLocale)
    defaultLocale = storedLocale;

const i18nProvider = polyglotI18nProvider(locale => messages[locale === 'en' && Environment.HostName === 'Mint' ? 'mint' : locale], defaultLocale, { 
    onMissingKey: key => {
        // Default to English if key is missing
        const englishMessage = get(englishMessages, key);
        if (englishMessage !== null && englishMessage !== undefined)
            return englishMessage;
            
        return key;
    }
});

export default i18nProvider;