import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CardActions,
    CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { Form, Login, TextInput, PasswordInput, required, BooleanInput  } from 'react-admin';
import { Environment } from './configProvider';
import LocaleSwitcher from './components/LocaleSwitcher';

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 0 1em',
        },
        input: {
            marginTop: '0'
        },
        button: {
            width: '100%',
        },
        icon: {
            textAlign: 'center'
        },
        or: {
            marginTop: '.5em',
            marginBottom: '.5em',
            textAlign: 'center'
        },
        signIn: {
            padding: '0 !important'
        },
        locale: {
            paddingTop: '.5em'
        }
    }),
    { name: 'RaLoginForm' }
);

const LoginForm = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const [authMethod, setAuthMethod] = useSafeSetState("Unassigned");
    const [preUsername, setPreUsername] = useSafeSetState(localStorage.getItem("remember_username"));
    const [clientId, setClientId] = useSafeSetState("");
    const [domain, setDomain] = useSafeSetState("");
    const [preState, setPreState] = useSafeSetState("");
    const [reUsername, setReUsername] = useSafeSetState(false);

    useEffect(() => {
        // If code is present, we came back from the provider
        const loginState = localStorage.getItem('login_state');
        const username = localStorage.getItem('login_username');
        if (code && loginState) {
            if (state === loginState) {
                login({ 
                    username: username,
                    authCode: code,
                    source: state.split('_')[0]
                })
                .catch(error => {
                    // Display error message
                    const message = typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message;
                    
                    document.getElementById('openIDConnectReturn').innerHTML = message + `<br><br><a href="/#/login">${translate('kuggar.auth.go_back_to_login')}</a>`;
                });
            }
            else {
                document.getElementById('openIDConnectReturn').innerHTML = `${translate('kuggar.auth.invalid_state_token')}<br><br><a href="/#/login">${translate('kuggar.auth.go_back_to_login')}</a>`;
            }
            localStorage.removeItem('login_state');
            localStorage.removeItem('login_username');
        }
    });

    const submit = values => {
        setLoading(true);

        if(reUsername)
            localStorage.setItem("remember_username", preUsername);

        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : (error.message.includes('Invalid credentials') ? 'kuggar.auth.invalid_credentials' : error.message),
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? (error.message.includes('Invalid credentials') ? 'kuggar.auth.invalid_credentials' : error.message)
                                : undefined,
                    }
                );
            });
    };

    const createRandomString = () => [...Array(32)].map(i=>(~~(Math.random()*36)).toString(36)).join('');

    const createLoginState = () => {
        const loginState = preState + '_' + createRandomString();
        localStorage.setItem('login_state', loginState);
        return loginState;
    };

    const redirectToMicrosoftLogin = () => {
        localStorage.setItem('login_username', preUsername);

        if(reUsername)
            localStorage.setItem("remember_username", preUsername);

        let redirectUrl = "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?" +
            "client_id=" + clientId +
            "&response_type=code" +
            "&redirect_uri=" + encodeURIComponent(Environment.Auth.RedirectUri) +
            "&response_mode=query" +
            "&scope=openid%20email%20profile%20offline_access%20user.read%20user.readbasic.all" +
            "&state=" + createLoginState() +
            "&nonce=" + createRandomString() +
            "&prompt=select_account";

        window.location.href = redirectUrl;
    };

    const redirectToGoogleLogin = () => {
        localStorage.setItem('login_username', preUsername);

        if(reUsername)
            localStorage.setItem("remember_username", preUsername);

        let redirectUrl = "https://accounts.google.com/o/oauth2/v2/auth?" +
            "client_id=" + clientId +
            "&response_type=code" +
            "&redirect_uri=" + encodeURIComponent(Environment.Auth.RedirectUri) +
            "&scope=openid%20email%20https://www.googleapis.com/auth/admin.directory.user.readonly" +
            "&access_type=offline" + 
            "&state=" + createLoginState() +
            "&nonce=" + createRandomString() +
            "&prompt=select_account";

        window.location.href = redirectUrl;
    };    

    const redirectToOktaLogin = () => {
        localStorage.setItem('login_username', preUsername);

        if(reUsername)
            localStorage.setItem("remember_username", preUsername);

        let redirectUrl = "https://" + domain + "/oauth2/v1/authorize?" +
            "client_id=" + clientId +
            "&response_type=code" +
            "&redirect_uri=" + encodeURIComponent(Environment.Auth.RedirectUri) +
            "&scope=openid%20email%20profile%20offline_access%20okta.users.read" +
            "&state=" + createLoginState() +
            "&nonce=" + createRandomString() +
            "&prompt=login";

        window.location.href = redirectUrl;
    };

    const getAuthMethod = () => {
      setLoading(true);

      var request = new Request(
        `${Environment.API.Auth}/identity/authentication-method/${preUsername}`,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );

      fetch(request)
        .then((response) => response.json())
        .then(async (body) => {
          setLoading(false);
          setAuthMethod(body.authenticationMethod);
          setClientId(body.clientId);
          setDomain(body.domain);
          setPreState(body.state);          
        });
    };

    const setFromStep1 = () => ({ username: preUsername, reUsername2: reUsername.length > 0 });
    const setRememberedUsername = () => ({ preUsername: localStorage.getItem("remember_username"), reUsername: localStorage.getItem("remember_username") !== null && localStorage.getItem("remember_username").length > 0 });

    if (code)
        return (
            <Login id="login" notification={null}>
                <div id="openIDConnectReturn" className={classes.form}>{translate(state.split('_')[0] === preState 
                    ? 'kuggar.auth.validating_okta_account'
                    : (state.split('_')[0] === preState
                        ? 'kuggar.auth.validating_google_account'
                        : 'kuggar.auth.validating_microsoft_account'))}
                </div>
                <br />
            </Login>
        );
    else
        return (
            <Login id="login">       
                {authMethod === "Unassigned" && (
                    <React.Fragment>
                        <Form onSubmit={getAuthMethod} defaultValues={setRememberedUsername}>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <TextInput
                                        source="preUsername"
                                        label={translate('ra.auth.username')}
                                        variant="standard"
                                        fullWidth={true}                                        
                                        disabled={loading}  
                                        validate={[required()]}                                      
                                        autoFocus
                                        onChange={e => setPreUsername(e.target.value)} />
                                </div>                                
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <BooleanInput 
                                        source="reUsername"
                                        label={translate('kuggar.auth.remember_username')} 
                                        variant="standard"
                                        fullWidth={true}                                                                                                   
                                        onChange={e => setReUsername(e.target.value)}
                                         />
                                </div>
                            </div>
                            {loading && (
                                <div className={classes.icon}>
                                    <CircularProgress size={18} thickness={2} />
                                </div>
                            )}                            
                            <CardActions className={classes.signIn}>
                                    <Button 
                                        variant="text"
                                        type="submit" 
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                        id="preSignInKuggar"                                         
                                        >Continue</Button>
                                </CardActions>
                        </Form>
                    </React.Fragment>
                )}           
                {(authMethod === "Kuggar") && (
                    <React.Fragment>
                        <Form onSubmit={submit} defaultValues={setFromStep1}>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <TextInput
                                        source="username"
                                        label={translate('ra.auth.username')}
                                        variant="standard"
                                        fullWidth={true}                                        
                                        disabled={loading}  
                                        validate={[required()]}                                      
                                        autoFocus                                        
                                    />
                                </div>
                                {Environment.Auth.Kuggar.IsAllowed === true && authMethod === "Kuggar" && (
                                    <div className={classes.input}>
                                        <PasswordInput
                                            source="password"
                                            label={translate('ra.auth.password')}
                                            variant="standard"
                                            fullWidth={true}
                                            validate={[required()]}
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <BooleanInput 
                                        source="reUsername2"
                                        label={translate('kuggar.auth.remember_username')} 
                                        variant="standard"
                                        fullWidth={true}                                                                                                                      
                                        onChange={e => setReUsername(e.target.value)}
                                         />
                                </div>
                            </div>
                            {loading && (
                                <div className={classes.icon}>
                                    <CircularProgress size={18} thickness={2} />
                                </div>
                            )}
                            {Environment.Auth.Kuggar.IsAllowed === true && authMethod === "Kuggar" && (
                                <CardActions className={classes.signIn}>
                                    <Button
                                        variant="text"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                        id="signInKuggar"
                                    >
                                        <img src="images/signin-with/kuggar.svg" alt={translate('kuggar.auth.sign_in_with_kuggar', Environment)} />
                                    </Button>
                                </CardActions>
                            )}                            
                        </Form>                    
                    </React.Fragment>
                )}
                {authMethod === "Microsoft" && (
                    <Form onSubmit={redirectToMicrosoftLogin}>
                        <CardActions className={classes.signIn}>
                            <Button
                                variant="text"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                id="signInMicrosoft"
                            >
                                <img src="images/signin-with/microsoft.svg" alt={translate('kuggar.auth.sign_in_with_microsoft')} />
                            </Button>
                        </CardActions>
                    </Form>
                )}
                {authMethod === "Google" && (
                    <Form onSubmit={redirectToGoogleLogin}>
                        <CardActions className={classes.signIn}>
                            <Button
                                variant="text"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                id="signInGoogle"
                            >
                                <img src="images/signin-with/google.svg" alt={translate('kuggar.auth.sign_in_with_google')} />
                            </Button>
                        </CardActions>
                    </Form>
                )}
                {authMethod === "Okta" && (
                    <Form onSubmit={redirectToOktaLogin}>
                        <CardActions className={classes.signIn}>
                            <Button
                                variant="text"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                                id="signInOkta"
                            >
                                <img src="images/signin-with/okta.svg" alt={translate('kuggar.auth.sign_in_with_okta')} />
                            </Button>
                        </CardActions>
                    </Form>
                )}
                <div className={classes.locale}>
                    <LocaleSwitcher />
                </div>
            </Login>
        );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;