import {
    Button, Create, Datagrid, Edit, EditButton, Error, FormDataConsumer,
    List, Loading, ReferenceField, required,
    SelectInput, Show, SimpleForm, Tab, TabbedShowLayout, Labeled, FunctionField,
    TextField, TextInput, TopToolbar, useGetList, useListContext, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import {
    CreateToolbar,
    UpdateToolbar
} from "../shared";
import * as React from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import AddIcon from '@mui/icons-material/Add';


export const RuleList = () => {
    const translate = useTranslate();
    const navigate = useNavigate();

    const [selectedRuleCollectionId, setSelectedRuleCollectionId] = useState(null)

    const {data: ruleCollections, isLoading, error} = useGetList(
        'rule_collections',
        {
            filter: {},
        }
    );

    useEffect(() => {
        if (ruleCollections && selectedRuleCollectionId === null) {
            setSelectedRuleCollectionId(ruleCollections[0]?.id);
        }
    }, [ruleCollections, selectedRuleCollectionId]);

    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!ruleCollections || ruleCollections.length === 0) return null;
    const firstRuleCollectionId = ruleCollections[0].id;

    const RuleCollectionsSelect = props => {
        const {data, source, label, style, value, isFilter} = props;

        let optionValue = value;
        if (value == null) {
            optionValue = "id";
        }

        const OptionRenderer = () => {
            const record = useRecordContext();
            return <span>{record.description}</span>;
        }

        return (
            <SelectInput
                source={source}
                label={label}
                validate={isFilter === true ? null : [required()]}
                choices={data}
                optionText={<OptionRenderer/>}
                optionValue={optionValue}
                style={style}/>
        );
    };

    const FilterQuery = () => {
        const {filterValues} = useListContext();

        if (selectedRuleCollectionId === null)
            setSelectedRuleCollectionId(filterValues?.id)

        return (
            <ReferenceField source="filterQueryId" reference="rule_queries" link="show"
                            label={translate('kuggar.rules.filter_queries')}
                            queryOptions={{
                                meta: {ruleCollectionId: selectedRuleCollectionId}
                            }}>
                <TextField source="description" />
            </ReferenceField>
        )
    }

    const DataTransformQuery = () => {
        const {filterValues} = useListContext();

        if (selectedRuleCollectionId === null)
            setSelectedRuleCollectionId(filterValues?.id)

        return (
            <ReferenceField source="dataTransformQueryId" reference="rule_queries" link="show"
                            label={translate('kuggar.rules.data_transform_queries')}
                            queryOptions={{
                                meta: {ruleCollectionId: selectedRuleCollectionId}
                            }}>
                <TextField source="description" />
            </ReferenceField>
        )
    }

    const handleCreateClick = () => {
        navigate(`/rules/create?ruleCollectionId=${selectedRuleCollectionId}`);
    }

    const ListActions = () => {
        return (
            <TopToolbar>
                <Button label="Create" onClick={handleCreateClick} startIcon={<AddIcon/>}/>
            </TopToolbar>
        )
    }

    return (
        <List
            actions={<ListActions/>}
            filters={[<RuleCollectionsSelect label={translate('kuggar.rules.rule_collections')} source="id" alwaysOn
                                             data={ruleCollections}/>]}
            filterDefaultValues={{id: firstRuleCollectionId}}
            pagination={false}>
            <Datagrid rowClick='show'
                      bulkActionButtons={false}>
                <TextField source="ruleNumber" label={translate('kuggar.rules.rule_number')}/>
                <FilterQuery label={translate('kuggar.rules.filter_query')} />
                <DataTransformQuery label={translate('kuggar.rules.data_transform_query')} />
                <ReferenceField source="resultId" reference="cache" link="show"
                                label={translate('kuggar.rules.cache')}>
                    <TextField source="description"/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const RuleShow = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    console.log(record)

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                <EditButton
                    label={translate('kuggar.rules.update_rule')}
                    record={data}
                />
            </TopToolbar>
        );
    };

    const QueryField = ({source, label, referenceSource, fixedWidth }) => {
        const data = useRecordContext();

        return (
            <ReferenceField source={source} reference="rule_queries" link="show"
                            label={label}
                            queryOptions={{
                                meta: {ruleCollectionId: data.ruleCollectionId}
                            }}>
                <FunctionField render={record => 
                    fixedWidth === true 
                    ? <code style={{whiteSpace:'pre-wrap',color:'#002b36',fontSize:'0.8rem'}}>{record[referenceSource]}</code>
                    : record[referenceSource]
                } />
            </ReferenceField>
        )
    }

    return (
        <Show actions={<Actions/>}>
            <TabbedShowLayout>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextField source="ruleNumber" label={translate('kuggar.rules.rule_number')}/>
                    <TextField source="ruleCollectionId" label={translate('kuggar.rules.rule_collection_id')}/>
                    <QueryField source='filterQueryId' referenceSource="description" label={translate('kuggar.rules.filter_query')}/>
                    <QueryField source='filterQueryId' referenceSource="queryText" fixedWidth={true} label={translate('kuggar.rules.filter_query_text')}/>
                    <QueryField source='dataTransformQueryId' referenceSource="description" label={translate('kuggar.rules.data_transform_query')}/>
                    <QueryField source='dataTransformQueryId' referenceSource="queryText" fixedWidth={true} label={translate('kuggar.rules.data_transform_query_text')}/>
                    <ReferenceField source="resultId" reference="cache" link="show"
                                    label={translate('kuggar.rules.cache')}>
                        <TextField source="description"/>
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export const RuleEdit = () => {
    const translate = useTranslate();

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <TextInput source="ruleNumber" label={translate('kuggar.rules.rule_number')} disabled={true} fullWidth={true}/>
                <FilterQuerySelectInput />
                <DataTransformQuerySelectInput />
                <CacheSelectInput/>
            </SimpleForm>
        </Edit>
    )
}

export const RuleCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();
    const [ruleCollectionId, setRuleCollectionId] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('ruleCollectionId');
        setRuleCollectionId(id);
    }, [location]);

    const onSuccess = (data) => {
        notify(`${translate('kuggar.rules.rule_on_success_notify')}`);
        redirect(`/rules`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };


    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>
                {ruleCollectionId && (
                    <TextInput
                        source="ruleCollectionId"
                        label={translate('kuggar.rules.rule_collection_id')}
                        defaultValue={ruleCollectionId}
                        disabled
                    />
                )}
                <FilterQuerySelectInput />
                <DataTransformQuerySelectInput />
                <CacheSelectInput/>
            </SimpleForm>
        </Create>
    )
}

const FilterQuerySelectInput = () => {
    const translate = useTranslate();

    const {data, isLoading, error} = useGetList(
        'filter_queries',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'description',
                order: 'ASC'
            },
            meta: {
                listByOwner: true
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.description}</span>
    };

    return (
        <SelectInput source="filterQueryId" label={translate('kuggar.rules.filter_queries')}
                     validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer/>}
                     optionValue="id"/>
    );
};

const DataTransformQuerySelectInput = () => {
    const translate = useTranslate();

    const {data, isLoading, error} = useGetList(
        'data_transform_queries',
        {
            filter: {
            },
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'description',
                order: 'ASC'
            },
            meta: {
                listByOwner: true
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span>{record.description}</span>
    };

    return (
        <SelectInput source="dataTransformQueryId" label={translate('kuggar.rules.data_transform_queries')}
                     validate={[required()]} fullWidth={true} choices={data} optionText={<OptionRenderer/>}
                     optionValue="id"/>
    );
};

const CacheSelectInput = () => {
    const translate = useTranslate();
    const {data, isLoading, error} = useGetList(
        'cache',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'currency',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    return (
        <SelectInput source="resultId" label={translate('kuggar.rules.cache')} validate={[required()]}
                     choices={data} optionText="description" optionValue="id"/>
    );
};