import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, useTranslate } from 'react-admin';

const ImageField = (props) => {
    const { source, path, height, width, label } = props;
    const record = useRecordContext();
    const translate = useTranslate();

    let style = {
        height: 'auto',
        width: 'auto',
        verticalAlign: 'middle'
    };
    if (height !== undefined) style.height = `${height}px`;
    if (width !== undefined) style.width = `${height}px`;

    if (record[source]) {
        let fileName = record[source].toString().toLowerCase();
    
        // TODO: Delete this if statement once Virtual Cards API returns the provider as 'Nium' instead of 'Mint'
        if ((source === 'provider' || label === translate('kuggar.shared.provider')) && path === 'cards' && fileName === 'mint')
            fileName = 'nium';

        return <span><img src={`images/${path}/${fileName}.svg`} alt={record[source]} style={style} /></span>;
    }

    return null;
};

ImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

ImageField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default ImageField;