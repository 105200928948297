import * as React from "react";
import { 
    List, Datagrid, TextField, CreateButton,
    Create, SelectInput, TextInput, NumberInput, ArrayInput, SimpleFormIterator,
    Loading, Error,
    required, useGetList, useNotify, useRedirect, useTranslate, 
} from 'react-admin';
import { WizardForm, WizardFormStep } from "@react-admin/ra-form-layout";
import { Box, Typography } from '@mui/material';
import PermissionsCreateUpdate from "../../components/PermissionsCreateUpdate";

export const IssuerOnboardingList = () => {
    const translate = useTranslate();

    const Empty = () => (
        <Box sx={{ width:'100%', marginTop:'20px', textAlign:'center' }}>
            <Typography variant="h6" color="inherit">{translate('kuggar.ip_onboarding.list_title')}</Typography>
            <Typography variant="body2">{translate('kuggar.ip_onboarding.list_subtitle')}</Typography>
            <br/>
            <CreateButton label={translate('kuggar.ip_onboarding.create_title')} />
        </Box>
    );

    return (
        <List empty={<Empty />}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
            </Datagrid>
        </List>
    );
};

export const IssuerOnboardingCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const onSuccess = () => {
		notify(`${translate('kuggar.organisation.on_success_notify')}`);
		redirect(`/ip_card_issuer_template`);
    };

    const {data: schemeData, isLoading: isSchemeLoading, error: schemeError} = useGetList(
        'ip_scheme',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: issuerData, isLoading: isIssuerLoading, error: issuerError} = useGetList(
        'ip_issuer_management',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    const {data: binRangeData, isLoading: isBinRangeLoading, error: binRangeError} = useGetList(
        'ip_bin_range',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isSchemeLoading || isIssuerLoading || isBinRangeLoading) return <Loading/>;
    if (schemeError || issuerError || binRangeError) return <Error/>;
    if (!schemeData || !issuerData || !binRangeData) return null;

    const validateEndGreaterThanStart = (value, allValues) => {
        if (parseInt(value, 10) <= parseInt(allValues.start, 10)) {
            return translate('kuggar.bin_ranges.end_greater_than_start_validation');
        }
        return undefined;
    };

    const validateBinLength = (value) => {
        if (parseInt(value, 10) <= 0) {
            return translate('kuggar.bin_ranges.bin_length_greater_than_zero_validation');
        }
        return undefined;
    };

    const validateTemplateParameters = (value) => {
        if (value !== undefined && value !== null && value <= 0) {
            return 'Value must be greater than 0';
        }
        return undefined;
    };

    const inputStyle = {width: '100%'};

    return (
        <Create title={translate('kuggar.ip_onboarding.create_title')} mutationOptions={{ onSuccess }}>
            <WizardForm>
                <WizardFormStep label="Account">
                    <TextInput source="accountDescription" validate={[required()]} label={translate('kuggar.shared.description')} fullWidth={true} />
                    <TextInput source="accountNumber" validate={[required()]} label={translate('kuggar.accounts.account_number')} fullWidth={true} />
                    <PermissionsCreateUpdate/>
                </WizardFormStep>
                <WizardFormStep label="BIN Range">
                    <TextInput source="binRangeDescription" label={translate('kuggar.shared.description')} fullWidth={true} />
                    <TextInput source="start" validate={[required()]} label={translate('kuggar.bin_ranges.start')} fullWidth={true} />
                    <TextInput source="end" validate={[required(), validateEndGreaterThanStart]} label={translate('kuggar.bin_ranges.end')} fullWidth={true} />
                    <TextInput source="binLength" validate={[required(), validateBinLength]} label={translate('kuggar.bin_ranges.bin_length')} fullWidth={true} />
                    <SelectInput
                        source="schemeId"
                        label={translate('kuggar.ip_schemes.scheme')}
                        validate={[required()]}
                        choices={schemeData}
                        optionText="description"
                        optionValue="id"
                        style={inputStyle}/>
                    <SelectInput
                        source="issuerId"
                        label={translate('kuggar.ip_issuer_management.issuer')}
                        validate={[required()]}
                        choices={issuerData}
                        optionText="name"
                        optionValue="id"
                        style={inputStyle}/>
                    <PermissionsCreateUpdate />
                </WizardFormStep>
                <WizardFormStep label="Card Processor Policy">
                    <TextInput
                        source="cardProcessorPolicyDescription"
                        label={translate('kuggar.shared.description')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <ArrayInput source="currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="countryControls.allowedCountryCodes" label={translate('kuggar.card_processor_policy.allowed_country_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.country_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="countryControls.blockedCountryCodes" label={translate('kuggar.card_processor_policy.blocked_country_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.country_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="merchantCategoryControls.allowedMerchantCategories" label={translate('kuggar.card_processor_policy.allowed_merchant_categories')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.merchant_category')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="merchantCategoryControls.blockedMerchantCategories" label={translate('kuggar.card_processor_policy.blocked_merchant_categories')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.merchant_category')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="merchantIdentifierControls.allowedMerchantIdentifiers" label={translate('kuggar.card_processor_policy.allowed_merchant_identifiers')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.merchant_identifier')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="merchantIdentifierControls.blockedMerchantIdentifiers" label={translate('kuggar.card_processor_policy.blocked_merchant_identifiers')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.merchant_identifier')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <PermissionsCreateUpdate />
                </WizardFormStep>
                <WizardFormStep label="Card Issuer Template">
                    <TextInput
                        source="cardIssuerTemplateDescription"
                        label={translate('kuggar.shared.description')}
                        fullWidth={true}
                        validate={[required()]}/>
                    <NumberInput
                        source="maxAuthorisations"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.max_authorisations')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <NumberInput
                        source="maxLimit"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.max_limit')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <NumberInput
                        source="maxExpiryDate.daysFromNow"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.expiry_days')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <NumberInput
                        source="maxExpiryDate.monthsFromNow"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.expiry_months')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <NumberInput
                        source="tolerance.percent"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.tolerance_percent')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <NumberInput
                        source="tolerance.fixedAmount"
                        defaultValue={0}
                        label={translate('kuggar.card_issuer_template.tolerance_fixed_amount')}
                        style={inputStyle}
                        validate={[validateTemplateParameters]} />
                    <ArrayInput source="currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                        <SimpleFormIterator inline>
                            <TextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="metadataConfig" label={translate('kuggar.metadata_configs.metadata_config')}>
                        <SimpleFormIterator inline>
                            <TextInput
                                source="label"
                                label={translate('kuggar.metadata_configs.label')}
                                fullWidth={true}
                                validate={[required()]}/>
                            <SelectInput source="metadataType" bel={translate('kuggar.metadata_configs.metadata_type')} validate={[required()]} choices={[
                                { id: 'String', name: 'String' },
                                { id: 'Number', name: 'Number' },
                                { id: 'Boolean', name: 'Boolean' }]}
                            />
                            <TextInput
                                source="defaultValue"
                                label={translate('kuggar.metadata_configs.default_value')}
                                fullWidth={true}
                                validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </WizardFormStep>
            </WizardForm>
        </Create>
    );
};