import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, Button } from 'react-admin';
import apiClient from '../apiClient';

const EditIssuerAccountDefinedData = props => {
    const id = props.match.params.id;
    const [issuerAcct, setIssuerAcct] = useState(null);
    const [label, setLabel] = useState('');
    const [selectedType, setSelectedType] = useState('1');
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        if (id) {
            apiClient.getIssuerAccount(id, onGetSuccess, onGetError, onGetFinally);
        }
    }, [id]);

    const onGetSuccess = (data) => {
        setIssuerAcct(data);
    };

    const onGetError = () => {

    };

    const onGetFinally = () => {
        
    };

    const AccountDefinedDataItem = (props) => {
        return (
            <div className='issuer-account-defined-data-item'>
                <div>
                    Label: { props.label }
                </div>
                <div>
                    Type: { props.type }
                </div>
            </div>
        );
    };

    const backPath = `#/issuer_account/${id}/show`;

    const goBack = () => {
        console.log('Go back');
        window.location.href = backPath;
    };

    const addNew = () => {
        setShowAdd(!showAdd);
    };

    const saveNew = () => {
        let data = {
            issuerAccountId: id,
            label: label,
            type: selectedType
        };
        apiClient.addAccountDefinedData(data, onAddSuccess, onAddError, onAddFinally);
    };


    const onAddSuccess = (data) => {
        setShowAdd(false);
        apiClient.getIssuerAccount(id, onGetSuccess, onGetError, onGetFinally);
    };

    const onAddError = () => {

    };

    const onAddFinally = () => {

    };

    const onLabelChange = (evt) => {
        const labelValue = evt.target.value;
        setLabel(labelValue);
    };

    const onSelectType = (evt) => {
        const selectedType = evt.target.value;
        setSelectedType(selectedType);
    };

    const AddAccountDefinedDataItem = () => {
        if (showAdd) {
            return (
                <div className='issuer-account-defined-data-item'>
                    <div>
                        Label: <input
                            value={label}
                            autoFocus
                            onChange={onLabelChange}
                            type="text" />
                    </div>
                    <div>
                        Type: <select
                            onChange={onSelectType}
                            value={selectedType} >
                            <option value='1' key='1'>Text</option>
                            <option value='2' key='2'>Date</option>
                            <option value='3' key='3'>Number</option>
                        </select>
                    </div>
                    <div>
                        <Button
                            className='app-button'
                            label={'Save'}
                            onClick={saveNew} />
                    </div>
                </div>
            );
        }
        else {
            return (
                null
            );
        }
    }

    if (issuerAcct && issuerAcct.issuerAccountDefinedData) {
        return (
            <Card>
                <Title title="Edit Account Defined Data" />
                <CardContent>
                    {
                        issuerAcct.issuerAccountDefinedData.map((item) =>
                            <AccountDefinedDataItem
                                key={item.id}
                                label={item.label}
                                type={item.type}
                            />
                        )
                    }
                    <AddAccountDefinedDataItem />
                    <div class='app-button-area'>
                        <Button
                            className='app-button'
                            label={'Go Back'}
                            onClick={goBack} />
                        <Button
                            className='app-button'
                            label={'Add New'}
                            onClick={addNew} />
                    </div>
                </CardContent>
            </Card>
        );
    }
    else {
        return (
            <Card>
                <Title title="Edit Account Defined Data" />
                <CardContent>

                </CardContent>
            </Card>
        );
    }
};

export default EditIssuerAccountDefinedData;