import {
	Loading, Error,
	useGetList
} from 'react-admin';
import UserDefinedField from './UserDefinedField';

const UserDefinedFieldsCreate = props => {
    const { source, style } = props;

	const { data, isLoading, error } = useGetList(
		'user_defined_data_label',
		{
			filter: {

			},
			pagination: {
				page: 1,
				perPage: 1000
			},
			sort: {
				field: 'userLabel',
				order: 'ASC'
			}
		}
	);
	if (isLoading) return <Loading />;
	if (error) return <Error />;
	if (!data) return null;

	if (data) {
		return (
			<span>
				{
					data.map(udf =>
						<div key={udf.id}>
							<UserDefinedField
								source={`${source}.${udf.label}`}
								label={udf.label}
								style={style}
								udfType="Text"							
							/>
						</div>
					)
				}
			</span>
		);
    }

	return null;
};

export default UserDefinedFieldsCreate;