import * as React from "react";
import {
    Datagrid,
    Error,
    ExportButton,
    List,
    Loading,
    required,
    SelectInput,
    TextField,
    useGetList,
    useRecordContext,
    useTranslate
} from 'react-admin';

import CurrencyFlag from "react-currency-flags";
import {kuggarExporter} from "../shared";

export const ExchangeRatesList = () => {
    const translate = useTranslate();

    const {data: currencies, isLoading, error} = useGetList(
        'currency',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'alphaCode',
                order: 'ASC'
            }
        }
    );

    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!currencies) return null;

    const emptyUUID = "00000000-0000-0000-0000-000000000000";
    const currenciesWithExchangeRates = currencies.filter(c => c.currencyExchangeId !== emptyUUID);

    const CurrencyNameField = () => {
        const record = useRecordContext();
        const currency = currencies.find(c => c.id === record.currencyId)

        return <span><CurrencyFlag currency={currency.alphaCode} size="md"/>&nbsp;{currency.name}</span>;
    };

    const AlphaCodeField = () => {
        const record = useRecordContext();
        const currency = currencies.find(c => c.id === record.currencyId)

        return <span>{currency.alphaCode}</span>;
    };

    return (
        <List exporter={kuggarExporter} pagination={false}
              filters={[<CurrencyExchangeSelect label={translate('kuggar.shared.currency')} source="currencyCode"
                                                alwaysOn data={currenciesWithExchangeRates}/>]}
              filterDefaultValues={{ currencyCode: '08eeae4b-93f8-49f0-8ec8-5282985899d8' }}
              actions={<ExportButton/>}>
            <Datagrid bulkActionButtons={false}>
                <CurrencyNameField label={translate('kuggar.shared.name')}/>
                <AlphaCodeField label={translate('kuggar.currency.alpha_code')}/>
                <TextField source="rate" label={translate('kuggar.currency.rate')}/>
            </Datagrid>
        </List>
    );
};

const CurrencyExchangeSelect = props => {
    const {data, source, label, style, value, isFilter} = props;

    // Create the currency data with duplicated top currencies
    let currencyData = [];
    let index = 1;

    // Now insert all currencies
    for (let i = 0; i < data.length; i++) {
        currencyData.push({
            index: index,
            id: data[i].id,
            alphaCode: data[i].alphaCode,
            name: data[i].name
        });
        index++;
    }

    // Default select list value to 'id'.
    let optionValue = value;
    if (value == null) {
        optionValue = "id";
    }

    const OptionRenderer = () => {
        const record = useRecordContext();
        return <span><CurrencyFlag currency={record.alphaCode}
                                   size="md"/>&nbsp;{record.alphaCode} - {record.name}</span>;
    }
    
    return (
        <SelectInput
            source={source}
            label={label}
            validate={isFilter === true ? null : [required()]}
            choices={currencyData}
            optionText={<OptionRenderer/>}
            optionValue={optionValue}
            style={style}/>
    );
};