import * as React from "react";
import {
    Datagrid,
    DateField,
    List,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    Title,
    useTranslate
} from "react-admin";
import {
    DateTimeLocale, kuggarExporter, TablePagination
} from "../shared";

export const UatpServiceLogList = () => {
    const translate = useTranslate();

    return (
        <List
            exporter={kuggarExporter}
            pagination={<TablePagination/>}
            perPage={10}
            hasCreate={false}>
            <Datagrid
                bulkActionButtons={false}
                rowClick='show'>
                <DateField source="timestampUtc" label={translate('kuggar.logs.timestamp')} showTime
                           locales={DateTimeLocale} sortable={false}/>
                <TextField source="exceptionMessage"/>
            </Datagrid>
        </List>
    );
};

export const UatpServiceLogShow = () => {
    const translate = useTranslate();

    return (<Show title={<Title/>}>
        <TabbedShowLayout>
            <Tab label={translate('kuggar.shared.tab_summary')}>
                <DateField source="timestampUtc" label={translate('kuggar.logs.timestamp')} showTime
                           locales={DateTimeLocale} sortable={false}/>
                <TextField source="exceptionMessage" label={translate('kuggar.uatp_logs.exception_message')}/>
                <TextField source="requestXml" label={translate('kuggar.uatp_logs.request_xml')}/>
                <TextField source="responseXml" label={translate('kuggar.uatp_logs.response_xml')}/>
            </Tab>
        </TabbedShowLayout>
    </Show>);
}
