import * as React from "react";
import {
	List, Show, Edit, Create, 
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField,
	SelectInput,
	TopToolbar, EditButton,
	required, useTranslate, useNotify, useRedirect, useRecordContext
} from 'react-admin';
import {
	TableFilter,
	TablePagination,
	HasCreatePermission,
	HasUpdatePermission,
	UpdateToolbar,
	CreateToolbar,
	TextFieldWithCopyButton, ProviderFilter, KuggarTextInput, kuggarExporter
} from '../shared';
import ImageField from '../components/ImageField';
import { Environment } from "../configProvider";

export const IssuerEnvironmentsList = () => {
	const translate = useTranslate();

	const filter = [
		<KuggarTextInput label={translate('ra.action.search')} source="q" alwaysOn/>,
		<ProviderFilter alwaysOn isFilter/>
	];

	return (
		<List
			exporter={kuggarExporter}
			filters={filter}
			pagination={<TablePagination />}
			perPage={10}
			sort={{ field: 'lastModified', order: 'DESC' }}
			hasCreate={HasCreatePermission('VirtualCards', '/issuer_environment')}>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
				<ImageField source="provider" path="cards" height="50" label={translate('kuggar.virtual_cards.provider')} />
				<TextField source="description" label={translate('kuggar.shared.name')} />
				<TextField source="serviceUrl" label={translate('kuggar.issuer_environment.service_url')} />
			</Datagrid>
		</List>
	);
};

export const IssuerEnvironmentShow = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>Issuer Credentials - ID {record ? record.id : ''}</span>;
	};

	const Actions = () => {
		const data = useRecordContext();
		return (
			<TopToolbar>
				{HasUpdatePermission('VirtualCards', '/issuer_environment/') ?
					<EditButton
						label={translate('kuggar.issuer_environment.update_issuer_environment')}
						record={data} />
					: null}
			</TopToolbar>
		);
	};

	return (
		<Show title={<Title />} actions={<Actions />}>
			<TabbedShowLayout>
				<Tab label={translate('kuggar.shared.tab_summary')}>
					<TextFieldWithCopyButton source='id' label={translate('kuggar.shared.kuggar_id', Environment)} />
					<ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
					<TextField source="description" label={translate('kuggar.shared.name')} />
					<TextField source="serviceUrl" label={translate('kuggar.issuer_environment.service_url')} />
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export const IssuerEnvironmentEdit = () => {
	const translate = useTranslate();

	const Title = () => {
		const record = useRecordContext();
		return <span>{translate('kuggar.issuer_environment.title_update')} - {translate('kuggar.shared.id')} {record ? record.id : ''}</span>;
	};

	return (
		<Edit title={<Title />} mutationMode="optimistic">
			<SimpleForm toolbar={<UpdateToolbar />}>
				<KuggarTextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<KuggarTextInput
					source="serviceUrl"
					label={translate('kuggar.issuer_environment.service_url')}
					fullWidth={true}
					validate={[required()]} />
			</SimpleForm>
		</Edit>
	);
};

export const IssuerEnvironmentCreate = () => {
	const translate = useTranslate();
	const inputStyle = { width: '512px' };
	const notify = useNotify();
	const redirect = useRedirect();

	const validateIssuerEnvironmentCreate = (values) => {
		const errors = {};

		return errors;
	};

	const onSuccess = (data) => {
		notify(`${translate('kuggar.issuer_environment.on_success_notify')}`);
		redirect(`/issuer_environment/${data.id}/show`);
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

	const OptionRenderer = () => {
		const record = useRecordContext(); 
		return <span><ImageField source="id" path="cards" height="30" label={translate('kuggar.shared.provider')} />&nbsp;&nbsp;{record.name}</span>;
	};

	return (
		<Create title={translate('kuggar.issuer_credentials.title_create')} mutationOptions={{ onSuccess, onError }}>
			<SimpleForm toolbar={<CreateToolbar />} validate={validateIssuerEnvironmentCreate}>
				<SelectInput source="provider"
					label={translate('kuggar.shared.provider')}
					validate={[required()]}
					style={inputStyle}
					choices={[
						{ id: 'AirPlusFlight', name: 'AirPlus - Flight' },
						{ id: 'AirPlusHotel', name: 'AirPlus - Hotel' },
						{ id: 'AirPlusProcurement', name: 'AirPlus - Procurement' },
						{ id: 'eNett', name: 'eNett' },
						{ id: 'Kuggar', name: 'Kuggar' },
						{ id: 'Mastercard', name: 'Mastercard' },
						{ id: 'Mint', name: 'Nium' },
						{ id: 'WEX', name: 'WEX' },
					]}
					optionText={<OptionRenderer />}
				/>
				<KuggarTextInput
					source="description"
					label={translate('kuggar.shared.description')}
					fullWidth={true}
					validate={[required()]} />
				<KuggarTextInput
					source="serviceUrl"
					label={translate('kuggar.issuer_environment.service_url')}
					fullWidth={true}
					validate={[required()]} />

			</SimpleForm>
		</Create>
	);
};