import * as React from "react";
import {useEffect, useState} from "react";
import {
    List, Edit, Loading, Error, Labeled,
    Datagrid, SimpleForm, FunctionField, TextField, SelectInput, SelectArrayInput, EditButton, SaveButton, Button, Toolbar,
    required, useGetList, useRedirect, useTranslate, useRecordContext, useListContext, useUpdate, useRefresh, useNotify
} from 'react-admin';
import CurrencyFlag from 'react-currency-flags';
import ImageField from '../components/ImageField';
import {HasUpdatePermission, kuggarExporter, TableFilter, TablePagination, UpdateToolbar} from '../shared';
import SwitchIcon from '@mui/icons-material/SwapHoriz';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import RouteIcon from '@mui/icons-material/Route';
import {Link} from "react-router-dom";

export const RoutingList = () => {
    const translate = useTranslate();

    const SetDefaultCacheButton = () => {
        const record = useRecordContext();
        const {data, isLoadingData} = useListContext();
        const refresh = useRefresh();

        const cacheId = (data.find(d => d.defaultFlag) || {}).cacheId || null;

        const [update, {isLoadingUpdate, error}] = useUpdate(
            'routing',
            {data: {cacheId: cacheId, newCacheId: record.cacheId, defaultFlag: true}, previousData: record},
            {
                onSuccess: (data) => {
                    refresh();
                },
                onError: (error) => {
                    console.error(error)
                },
            }
        )

        const handleClick = () => {
            update()
        }

        if (isLoadingData) return null;

        if (!record.defaultFlag && HasUpdatePermission('VirtualCards', '/cache_route'))
            return <Button startIcon={<VerticalAlignBottomIcon/>} label={translate('kuggar.caches.set_default')}
                           onClick={handleClick} disabled={isLoadingUpdate}/>
    }

    const RouteCurrencyButton = () => {
        const record = useRecordContext();
        if (HasUpdatePermission('VirtualCards', '/cache_route'))
            return (
                <Link component={RouteCurrencyButton} to={{pathname: `/routing/${record.cacheId}/currency`}}>
                    <Button startIcon={<RouteIcon/>} label={translate('kuggar.caches.route_currencies')}/>
                </Link>
            )
        return null;
    }

    const SwitchCacheButton = () => {
        const record = useRecordContext();
        if (HasUpdatePermission('VirtualCards', '/cache_route'))
            return <EditButton icon={<SwitchIcon/>} label={translate('kuggar.caches.switch')}
                               record={{id: record.cacheId}}/>
        return null;
    };

    const CacheCurrencyField = () => {
        const record = useRecordContext();
        const primaryCurrency = !record.fundingCurrency ? record.currencies[0] : record.fundingCurrency;

        return (
            <span>
                <CurrencyFlag currency={primaryCurrency.substr(0, 3)} size="md"/>
                {record.defaultFlag === true
                    ? <b>&nbsp;{primaryCurrency} (Default)</b>
                    : <span>&nbsp;{primaryCurrency}</span>}
            </span>
        );
    };

    return (
        <List exporter={kuggarExporter} filters={<TableFilter/>} pagination={<TablePagination/>} perPage={10}
              sort={{field: 'currency', order: 'ASC'}}>
            <Datagrid bulkActionButtons={false}>
                <CacheCurrencyField label={translate('kuggar.shared.currency')}/>
                <ImageField source="provider" path="cards" height="50"
                            label={translate('kuggar.virtual_cards.provider')}/>
                <FunctionField source="description" label={translate('kuggar.caches.cache')}
                               render={record => record.defaultFlag === true ?
                                   <b>{record.description}</b> : record.description}/>
                <SwitchCacheButton/>
                <RouteCurrencyButton/>
                <SetDefaultCacheButton/>
            </Datagrid>
        </List>
    );
};

export const RoutingEdit = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const notify = useNotify();

    // Setup the cache dropdown list
    const CacheSelectInput = () => {
        const record = useRecordContext();
        const {data, isLoading, error} = useGetList(
            'cache',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'currency',
                    order: 'ASC'
                },
                meta: {
                    currency: record.fundingCurrency ?? record.currencies[0]
                }
            }
        );
        if (isLoading) return <Loading/>;
        if (error) return <Error/>;
        if (!data) return null;

        return (
            <SelectInput source="newCacheId" label={translate('kuggar.caches.new_cache')} validate={[required()]}
                         choices={data} optionText="description" optionValue="id"/>
        );
    };

    const CacheCurrencyField = () => {
        const record = useRecordContext();
        const primaryCurrency = !record.fundingCurrency ? record.currencies[0] : record.fundingCurrency;

        return (
            <Labeled label={translate('kuggar.shared.currency')}>
                {record.defaultFlag === true
                    ? <b>{primaryCurrency}</b>
                    : <span><CurrencyFlag currency={primaryCurrency.substr(0, 3)}
                                          size="md"/>&nbsp;{primaryCurrency}</span>}
            </Labeled>
        );
    };

    const onSuccess = () => {
		redirect('/routing');
    };
    
    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };
    
    return (
        <Edit mutationOptions={{ onSuccess, onError }} mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <CacheCurrencyField source="currencyId"/>
                <TextField source="description" label={translate('kuggar.caches.current_cache')}/>
                <CacheSelectInput/>
            </SimpleForm>
        </Edit>
    );
};

export const CurrencyRoutingEdit = () => {
    const [selectedCurrencies, setSelectedCurrencies] = useState();
    const CurrencyArraySelectInput = ({handleSelectedCurrencies}) => {
        const record = useRecordContext();
        const primaryCurrency = !record.fundingCurrency ? record.currencies[0] : record.fundingCurrency;
        const {data: currencies, isLoading: currencyLoading, error: currencyError} = useGetList(
            'currency',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'alphaCode',
                    order: 'ASC'
                }
            }
        );

        const {data: caches, isLoading: cacheLoading, error: cacheError} = useGetList(
            'routing',
            {
                filter: {},
                pagination: {
                    page: 1,
                    perPage: 1000
                },
                sort: {
                    field: 'currency',
                    order: 'ASC'
                }
            }
        );

        if (currencyLoading || cacheLoading) return <Loading/>;
        if (currencyError || cacheError) return <Error/>;
        if (!currencies || !caches) return null;

        // Get all the other caches, not the currently selected one
        const notInCaches = caches.filter(cache => cache.fundingCurrency ?
            cache.fundingCurrency !== primaryCurrency : cache.currencies[0] !== primaryCurrency)

        // Get all their currencies
        const distinctCurrencies = [...new Set(
            notInCaches.flatMap(cache =>
                [cache.fundingCurrency, ...cache.currencies]
            )
        )];

        // Remove them as selectable options in the ddl
        const availableCurrencies = currencies?.filter(currency => !distinctCurrencies.includes(currency.alphaCode))

        let currencyData = []
        for (let i = 0; i < availableCurrencies.length; i++) {
            let disableValue = primaryCurrency === availableCurrencies[i].alphaCode;

            currencyData.push({
                index: i,
                id: availableCurrencies[i].id,
                alphaCode: availableCurrencies[i].alphaCode,
                name: availableCurrencies[i].name,
                disabled: disableValue,
            });
        }

        const OptionRenderer = () => {
            const record = useRecordContext();
            return <span><CurrencyFlag currency={record.alphaCode}
                                       size="md"/>&nbsp;{record.alphaCode} - {record.name}</span>;
        }

        return (
            <SelectArrayInput
                source='currencies'
                choices={currencyData}
                onChange={e => handleSelectedCurrencies(e.target.value)}
                optionText={<OptionRenderer/>}
                optionValue='alphaCode'
                style={{width: '512px'}}
            />
        );
    };

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateCurrencyRoutesToolbar currencies={selectedCurrencies}/>}>
                <TextField source="fundingCurrency" hidden={true}/>
                <CurrencyArraySelectInput source='currencies' handleSelectedCurrencies={setSelectedCurrencies}/>
            </SimpleForm>
        </Edit>
    );
};

const UpdateCurrencyRoutesToolbar = ({currencies}) => {
    const record = useRecordContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const [update, {isLoading}] = useUpdate();
    const translate = useTranslate();
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        if (currencies) {
            setIsDisabled(
                currencies.every(item => record.currencies.includes(item)) &&
                record.currencies.every(item => currencies.includes(item)))
        }
    }, [currencies])

    if (isLoading) return null;

    const handleClick = () => {
        update(
            'routing/currency',
            {
                id: record.id,
                data: {id: record.id, currencies, fundingCurrency: record.fundingCurrency},
                previousData: record
            },
            {
                onSuccess: () => {
                    redirect('/routing')
                },
                onFailure: error => notify(`Error: ${error.message}`, 'warning'),
            }
        );
    };
    return (
        <Toolbar>
            <SaveButton onClick={handleClick} disabled={isLoading || isDisabled}
                        label={translate('kuggar.shared.update')}/>
        </Toolbar>
    );
};